import React, { useState, useContext, useEffect } from "react";
import { ContextFormulario } from "../../contexts/formulario/ContextFormulario";
import {
  Form,
  Input,
  Button,
  Radio,
  Row,
  Col,
  Card,
  Select,
  InputNumber,
  Checkbox,
  Space,
  Divider,
  DatePicker,
} from "antd";
import "./styles.css";
import { ContextAutenticacion } from "../../contexts/autenticacion/ContextAutenticacion";
import { Container } from "reactstrap";
import moment from "moment";
import { useHistory } from "react-router-dom";

export const VerJuridico = () => {
  const history = useHistory();
  const [flag, setFlag] = useState(false);
  const [datosBasicos, setDatosBasicos] = useState({
    fecha_atencion_juridico: "",
    remitido_juridico: "",
    entidad_juridico: "",
    tiempo_espera_juridico: "",
    resumen_juridico: "",
    tuvo_solucion_juridico: "",
    observaciones_juridico: "",
    estado_juridico: "",
  });

  const contextformulario = useContext(ContextFormulario);
  const {
    agregarSeguimientoJuridico,
    caraterizaciones,
    seguimiento_editar,
    editarSeguimiento,
  } = contextformulario;
  const contextAutenticacion = useContext(ContextAutenticacion);
  const { usuario } = contextAutenticacion;

  const {
    fecha_atencion_juridico,
    remitido_juridico,
    entidad_juridico,
    tiempo_espera_juridico,
    resumen_juridico,
    tuvo_solucion_juridico,
    observaciones_juridico,
    estado_juridico,
  } = datosBasicos;

  const array = [];

  useEffect(() => {
    if (seguimiento_editar !== null) {
      setDatosBasicos({
        fecha_atencion_juridico: seguimiento_editar.fecha,
        remitido_juridico:
          seguimiento_editar.remitido_juridico === "Si" ? 1 : 2,
        entidad_juridico: seguimiento_editar.entidad,
        tiempo_espera_juridico: seguimiento_editar.tiempo,
        resumen_juridico: seguimiento_editar.resumen,
        tuvo_solucion_juridico: seguimiento_editar.solucion === "Si" ? 1 : 2,
        observaciones_juridico: seguimiento_editar.observaciones,
      });
    }
  }, [seguimiento_editar]);

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };

  const checkStyle = {
    display: "block",
    marginRight: "0",
  };

  const checkStyle1 = {
    display: "block",
    marginRight: "0",
    marginBottom: "1rem",
    paddingTop: "0.5rem",
  };

  const dateFormat = "YYYY/MM/DD";

  const onFinish = (values) => {
    if (seguimiento_editar === null) {
      const fieldsValue = {
        ...values,
        fecha_atencion_juridico:
          values["fecha_atencion_juridico"].format("YYYY-MM-DD"),
        caracterizacioneId: caraterizaciones[0].id,
        userId: usuario.id,
      };

      agregarSeguimientoJuridico(fieldsValue);
      history.push("/seguimiento");
    } else {
      const fieldsValue = {
        ...values,
        fecha_atencion_juridico:
          values["fecha_atencion_juridico"].format("YYYY-MM-DD"),
        caracterizacioneId: caraterizaciones[0].id,
        userId: usuario.id,
        id: seguimiento_editar.id,
      };

      editarSeguimiento(fieldsValue);
      history.push("/seguimiento");
    }

    // if (data_formulario !== "") {
    //     values["id"] = data_formulario;
    //     console.log(values)
    //     actualizarMortalidad(values);

    // }
    // if (usuario_seleccionado !== null) {
    //     values["id"] = usuario_seleccionado.id;
    //     console.log("Success:", values);
    //     if(usuario.roles[0] === "ROLE_USER" && flag === false ){
    //     actualizarMortalidad(values);
    //     navigation.next();
    //   }else{
    //    navigation.next();
    //   }

    // }
  };

  const onFinishFailed = (errorInfo) => {
    //console.log("Failed:", errorInfo);
  };

  const onChange = (e) => {
    setDatosBasicos({
      ...datosBasicos,
      [e[0].name]: e[0].value,
    });
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Form
            layout="vertical"
            name="basic"
            initialValues={{
              fecha_atencion_juridico: seguimiento_editar
                ? moment(seguimiento_editar.fecha, "YYYY-MM-DD")
                : "",
              remitido_juridico: seguimiento_editar
                ? seguimiento_editar.remitido_juridico === "Si"
                  ? "1"
                  : "2"
                : undefined,
              entidad_juridico: seguimiento_editar
                ? seguimiento_editar.entidad
                : "",
              tiempo_espera_juridico: seguimiento_editar
                ? seguimiento_editar.tiempo
                : "",
              resumen_juridico: seguimiento_editar
                ? seguimiento_editar.resumen
                : "",
              tuvo_solucion_juridico: seguimiento_editar
                ? seguimiento_editar.solucion === "Si"
                  ? "1"
                  : "2"
                : undefined,
              observaciones_juridico: seguimiento_editar
                ? seguimiento_editar.observaciones
                : "",
            }}
            onFieldsChange={onChange}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Card className="borde">
              <div className="titulo">
                <p>Nuevo Seguimiento Jurídico</p>
              </div>
              <Row gutter={[40, 16]} justify="left">
                <Col lg={12} md={12} xs={24}>
                  <Form.Item
                    label="1. Fecha de la atención"
                    name="fecha_atencion_juridico"
                    rules={[
                      {
                        required: true,
                        message: "Este campo es requerido",
                      },
                    ]}
                    value={fecha_atencion_juridico}
                  >
                    <DatePicker
                      disabled={true}
                      format={dateFormat}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col lg={12} md={12} xs={24}>
                  <Form.Item
                    label="2. ¿Remitido a alguna entidad?"
                    name="remitido_juridico"
                    rules={[
                      {
                        required: true,
                        message: "Este campo es requerido",
                      },
                    ]}
                    value={remitido_juridico}
                  >
                    <Select disabled={true} placeholder="Seleccione">
                      <Select.Option value="1">Si</Select.Option>
                      <Select.Option value="2">No</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[40, 16]} justify="left">
                <Col lg={12} md={12} xs={24}>
                  <Form.Item
                    label="3. Entidad"
                    name="entidad_juridico"
                    rules={[
                      {
                        required: true,
                        message: "Este campo es requerido",
                      },
                    ]}
                    value={entidad_juridico}
                  >
                    <Input disabled={true} placeholder="Digite la entidad" />
                  </Form.Item>
                </Col>
                <Col lg={12} md={12} xs={24}>
                  <Form.Item
                    label="4. Tiempo de espera (dias)"
                    name="tiempo_espera_juridico"
                    rules={[
                      {
                        required: true,
                        message: "Este campo es requerido",
                      },
                    ]}
                    value={tiempo_espera_juridico}
                  >
                    <InputNumber
                      disabled={true}
                      style={{ width: "100%" }}
                      placeholder="Digite los dias"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[40, 16]} justify="left">
                <Col lg={12} md={12} xs={24}>
                  <Form.Item
                    label="5. Resumen de la respuesta del caso"
                    name="resumen_juridico"
                    rules={[
                      {
                        required: true,
                        message: "Este campo es requerido",
                      },
                    ]}
                    value={resumen_juridico}
                  >
                    <Input.TextArea
                      disabled={true}
                      placeholder="Digite la información"
                    />
                  </Form.Item>
                </Col>
                <Col lg={12} md={12} xs={24}>
                  <Form.Item
                    label="6. ¿Tuvo solución?"
                    name="tuvo_solucion_juridico"
                    rules={[
                      {
                        required: true,
                        message: "Este campo es requerido",
                      },
                    ]}
                    value={tuvo_solucion_juridico}
                  >
                    <Select disabled={true} placeholder="Seleccione">
                      <Select.Option value="1">Si</Select.Option>
                      <Select.Option value="2">No</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[40, 16]} justify="left">
                <Col lg={12} md={12} xs={24}>
                  <Form.Item
                    label="7. Observaciones"
                    name="observaciones_juridico"
                    rules={[
                      {
                        required: true,
                        message: "Este campo es requerido",
                      },
                    ]}
                    value={observaciones_juridico}
                  >
                    <Input.TextArea
                      disabled={true}
                      placeholder="Digite la información"
                    />
                  </Form.Item>
                </Col>
                <Col lg={12} md={12} xs={24}></Col>
              </Row>

              <Divider />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Form.Item>
                  <Button type="primary" onClick={() => history.goBack()}>
                    Atras
                  </Button>
                </Form.Item>
              </div>
            </Card>
          </Form>
        </Container>
      </div>
    </>
  );
};
