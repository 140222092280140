import React, { useEffect, useContext } from "react";

// MetisMenu
import MetisMenu from "metismenujs";

import { Link } from "react-router-dom";

import { ContextAutenticacion } from "../../contexts/autenticacion/ContextAutenticacion";
import { ContextFormulario } from "../../contexts/formulario/ContextFormulario";
import { useHistory } from "react-router-dom";
import ExportData from "../../pages/ExportData/ExportData";

//i18n

const SidebarContent = (props) => {
  const history = useHistory();

  const contextAutenticacion = useContext(ContextAutenticacion);
  const { usuario, cerrarSesion } = contextAutenticacion;

  const contextformulario = useContext(ContextFormulario);
  const { borrarUsuarioSeleccionado } = contextformulario;
  // // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    var pathName = window.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      var matchingMenuItem = null;
      var ul = document.getElementById("side-menu");
      var items = ul.getElementsByTagName("a");
      for (var i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [window.location.pathname]);

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  }

  const redireccionar = () => {
    borrarUsuarioSeleccionado();
    history.push("/");
  };

  const redireccionar1 = () => {
    borrarUsuarioSeleccionado();
    history.push("/asignacion_admin");
  };

  const redireccionar2 = () => {
    borrarUsuarioSeleccionado();
    history.push("/sin-asignar");
  };

  const redireccionar4 = () => {
    borrarUsuarioSeleccionado();
    history.push("/asignados");
  };

  const redireccionar3 = () => {
    borrarUsuarioSeleccionado();
    history.push("/formulario");
  };

  const redireccionar5 = () => {
    borrarUsuarioSeleccionado();
    history.push("/asesor");
  };

  const redireccionar6 = () => {
    borrarUsuarioSeleccionado();
    history.push("/asesor_cerrado");
  };

  const redireccionar7 = () => {
    borrarUsuarioSeleccionado();
    history.push("/asignadas_admin");
  };

  const redireccionar8 = () => {
    borrarUsuarioSeleccionado();
    history.push("/seguimiento_admin_cerado");
  };

  const redireccionar9 = () => {
    borrarUsuarioSeleccionado();
    history.push("/borrador_admin");
  };

  const cerrar_sesion = () => {
    borrarUsuarioSeleccionado();
    cerrarSesion();
  };

  return (
    <React.Fragment>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <li className="menu-title">{"Menu"} </li>

          {usuario.roles[0] === "ROLE_USER" && (
            <>
              <li onClick={() => redireccionar()}>
                <Link to="/dashboard" className="waves-effect">
                  <i className="bx bx-home-circle"></i>
                  <span>{"Inicio"}</span>
                </Link>
              </li>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-user-circle"></i>
                  <span>{"Casos"}</span>
                </Link>
                <ul className="sub-menu">
                  <li onClick={() => redireccionar3()}>
                    <Link to="/formulario">{"Registrar"}</Link>
                  </li>
                  <li onClick={() => redireccionar2()}>
                    <Link to="/sin-asignar">{"Sin Asignar"}</Link>
                  </li>
                  <li onClick={() => redireccionar4()}>
                    <Link to="/asignados">{"Asignados"}</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="#" className="waves-effect">
                  <i className="bx bx-home-circle"></i>
                  <span
                    onClick={() => {
                      cerrar_sesion();
                    }}
                  >
                    {"Cerrar Sesión"}
                  </span>
                </Link>
              </li>
            </>
          )}
          {usuario.roles[0] === "ROLE_MODERATOR" && (
            <>
              <li onClick={() => redireccionar()}>
                <Link to="/inicio" className="waves-effect">
                  <i className="bx bx-home-circle"></i>
                  <span>{"Inicio"}</span>
                </Link>
              </li>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-user-circle"></i>
                  <span>{"Casos"}</span>
                </Link>
                <ul className="sub-menu">
                  <li onClick={() => redireccionar5()}>
                    <Link to="/asesor">{"Asignados"}</Link>
                  </li>
                  <li onClick={() => redireccionar6()}>
                    <Link to="/asesor_cerrado">{"Finalizados"}</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="#" className="waves-effect">
                  <i className="bx bx-home-circle"></i>
                  <span onClick={contextAutenticacion.cerrarSesion}>
                    {"Cerrar Sesión"}
                  </span>
                </Link>
              </li>
            </>
          )}
          {usuario.roles[0] === "ROLE_ADMIN" && (
            <>
              <li>
                <Link to="/inicio" className="waves-effect">
                  <i className="bx bx-home-circle"></i>
                  <span>{"Inicio"}</span>
                </Link>
              </li>

              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-archive"></i>
                  <span>{"Casos"}</span>
                </Link>
                <ul className="sub-menu">
                  <li onClick={() => redireccionar9()}>
                    <Link to="/borrador_admin">{"Borrador"}</Link>
                  </li>
                  <li onClick={() => redireccionar1()}>
                    <Link to="/asignacion_admin">{"Sin Asignar"}</Link>
                  </li>
                  <li onClick={() => redireccionar7()}>
                    <Link to="/asignadas_admin">{"Asignados"}</Link>
                  </li>
                  {/* <li>
                    <Link to="/">{"Cerrados por asesor"}</Link>
                  </li> */}
                  <li onClick={() => redireccionar8()}>
                    <Link to="/seguimiento_admin_cerado">{"Cerrados"}</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-user-circle"></i>
                  <span>{"Usuarios"}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/usuarios">{"Gestión Usuarios"}</Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="#" className="waves-effect">
                  <i className="bx bx-home-circle"></i>
                  <span onClick={contextAutenticacion.cerrarSesion}>
                    {"Cerrar Sesión"}
                  </span>
                </Link>
              </li>
              <div
                style={{
                  paddingLeft: "2rem",
                  position: "absolute",
                  bottom: 0,
                  marginTop: "15rem",
                }}
              ></div>
            </>
          )}
        </ul>
      </div>
    </React.Fragment>
  );
};

export default SidebarContent;
