import React, {useContext} from 'react';



//Simple bar
import SimpleBar from "simplebar-react";

import SidebarContent from "./SidebarContent";

import { ContextAutenticacion } from '../../contexts/autenticacion/ContextAutenticacion';

const Sidebar = (props) => {

       const contextAutenticacion = useContext(ContextAutenticacion);
  const { usuario} = contextAutenticacion;
  
    return (
      <React.Fragment>
          <div id="vertical-menu" className="vertical-menu">
              <div data-simplebar className="h-100">
                  {props.type !== "condensed" ? (
                      <SimpleBar style={{ maxHeight: "100%" }}>
                          {usuario && (
 <SidebarContent />
                          )}
                         
                      </SimpleBar>
                  ) : <SidebarContent />}
              </div>

          </div>
      </React.Fragment>
    );
  }


export default Sidebar;

