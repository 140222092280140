import React from "react";
import Container from '@material-ui/core/Container';

export const Submit = () => {
  return (
    <Container maxWidth="sm" style={{ marginTop: '4rem' }}>      
      <h3>Formulario Finalizado. Debe esperar la asignación del administrador del sistema!</h3>
    </Container>
  );
};
