import React, { useState, useContext, useEffect } from "react";
import { ContextFormulario } from "../../contexts/formulario/ContextFormulario";
import {
  Form,
  Button,
  Row,
  Col,
  Card,
  Select,
  Divider,
  Radio,
  Checkbox,
} from "antd";
import "./styles.css";
import { ContextAutenticacion } from "../../contexts/autenticacion/ContextAutenticacion";
import { useHistory } from "react-router-dom";

export const Asignacion = ({ navigation }) => {
  const history = useHistory();
  const [disabled, setDisabled] = useState(true);
  const [datosBasicos, setDatosBasicos] = useState({
    tipo_asignacion: undefined,
    asignacion_empleabilidad: undefined,
    asignacion_juridica: undefined,
    asignacion_psicologica: undefined,
  });

  const contextformulario = useContext(ContextFormulario);
  const {
    actualizarTipoAsesoria,
    usuario_seleccionado_asignacion,
    formulario_basico,
    obtenerAsesores,
    asesores_asignados,
    asesores,
    borrarUsuarioSeleccionado,
  } = contextformulario;
  const contextAutenticacion = useContext(ContextAutenticacion);
  const { usuario } = contextAutenticacion;
  const [data_formulario, setDataFormulario] = useState("");

  const {
    tipo_asignacion,
    asignacion_empleabilidad,
    asignacion_juridica,
    asignacion_psicologica,
  } = datosBasicos;

  useEffect(() => {
    if (usuario_seleccionado_asignacion !== null) {
      setDatosBasicos({
        tipo_asignacion: usuario_seleccionado_asignacion.tipo_asignacion,
      });
    }
  }, [usuario_seleccionado_asignacion]);

  useEffect(() => {
    if (formulario_basico.length > 0) {
      setDataFormulario(formulario_basico[0]);
    }
  }, [formulario_basico]);

  useEffect(() => {
    obtenerAsesores();
  }, []);

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };

  const checkStyle = {
    display: "block",
    marginRight: "0",
  };

  const checkStyle1 = {
    display: "block",
    marginRight: "0",
    marginBottom: "1rem",
    paddingTop: "0.5rem",
  };

  const onFinish = (values) => {
    borrarUsuarioSeleccionado();
    history.push("/");
  };

  const onFinishFailed = (errorInfo) => {
    //console.log("Failed:", errorInfo);
  };

  const onChange = (e) => {
    setDatosBasicos({
      ...datosBasicos,
      [e[0].name]: e[0].value,
    });
  };

  const array_asignados = [];
  //console.log(asesores_asignados);
  let array_nuevo = [];
  if (asesores_asignados !== null) {
    array_nuevo = asesores_asignados.asesor_caracterizaciones;
  }

  if (array_nuevo && array_nuevo.length >= 1) {
    //console.log(array_nuevo);
    for (
      var i = 0;
      i < asesores_asignados.asesor_caracterizaciones.length;
      i++
    ) {
      array_asignados.push({
        id: parseInt(asesores_asignados.asesor_caracterizaciones[i].userId),
        tipo: parseInt(asesores_asignados.asesor_caracterizaciones[i].tipo),
      });
    }
  }

  //console.log(array_asignados);
  let empleo;
  let psico;
  let juridi;
  for (var i = 0; i < array_asignados.length; i++) {
    if (array_asignados[i].tipo === 1) {
      juridi = array_asignados[i].id;
      //console.log(juridi);
    } else if (array_asignados[i].tipo === 3) {
      psico = array_asignados[i].id;
    } else {
      empleo = array_asignados[i].id;
    }
  }

  return (
    <>
      <Form
        layout="vertical"
        name="basic"
        initialValues={{
          tipo_asignacion: usuario_seleccionado_asignacion
            ? usuario_seleccionado_asignacion.tipo_asignacion
            : "",
          asignacion_juridica: juridi,
          asignacion_psicologica: psico,
          asignacion_empleabilidad: empleo,
        }}
        onFieldsChange={onChange}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Card className="borde">
          <div className="titulo">
            <p>Escogencia de la atención u orientación.</p>
          </div>
          <Divider />
          <Row gutter={[40, 16]} justify="center">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="Tipo de actuación requerida:"
                name="tipo_asignacion"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={tipo_asignacion}
              >
                <Checkbox.Group disabled={disabled}>
                  <Checkbox style={checkStyle} value={1}>
                    {" "}
                    Asesoria Jurídica
                  </Checkbox>
                  <Checkbox style={checkStyle} value={3}>
                    Atención psicológica
                  </Checkbox>
                  <Checkbox style={checkStyle} value={2}>
                    Empleabilidad
                  </Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          {tipo_asignacion && tipo_asignacion.includes(1) ? (
            <Row gutter={[40, 16]} justify="center">
              <Col lg={12} md={12} xs={24}>
                <Form.Item
                  label="Escoger asesor para juridica:"
                  name="asignacion_juridica"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                  value={asignacion_juridica}
                >
                  <Select disabled={disabled} placeholder="Seleccione">
                    {asesores.map((asesor) => (
                      <Select.Option value={asesor.id} key={asesor.id}>
                        {asesor.username}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          ) : null}

          {tipo_asignacion && tipo_asignacion.includes(3) ? (
            <Row gutter={[40, 16]} justify="center">
              <Col lg={12} md={12} xs={24}>
                <Form.Item
                  label="Escoger asesor para psicologica:"
                  name="asignacion_psicologica"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                  value={asignacion_psicologica}
                >
                  <Select disabled={disabled} placeholder="Seleccione">
                    {asesores.map((asesor) => (
                      <Select.Option value={asesor.id} key={asesor.id}>
                        {asesor.username}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          ) : null}
          {tipo_asignacion && tipo_asignacion.includes(2) ? (
            <Row gutter={[40, 16]} justify="center">
              <Col lg={12} md={12} xs={24}>
                <Form.Item
                  label="Escoger asesor para empleabilidad:"
                  name="asignacion_empleabilidad"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                  value={asignacion_empleabilidad}
                >
                  <Select disabled={disabled} placeholder="Seleccione">
                    {asesores.map((asesor) => (
                      <Select.Option value={asesor.id} key={asesor.id}>
                        {asesor.username}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          ) : null}
          <Divider />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Form.Item>
              <Button type="primary" danger htmlType="submit">
                Finalizar
              </Button>
            </Form.Item>
          </div>
        </Card>
      </Form>
    </>
  );
};
