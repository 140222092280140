import instance from './axios-api';

const tokenAuth = token =>{
     
    if(token){
        instance.defaults.headers.common['x-access-token']= token;
        //console.log(instance.defaults.headers)
    }else{
        delete instance.defaults.headers.common['x-access-token'];
    }
}

export default tokenAuth