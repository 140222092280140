import React, { useState, useContext, useEffect } from "react";
import { ContextFormulario } from "../../contexts/formulario/ContextFormulario";
import {
  Form,
  Input,
  Button,
  Radio,
  Row,
  Col,
  Card,
  Select,
  InputNumber,
  Checkbox,
  Space,
  Divider,
} from "antd";
import "./styles.css";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { ContextAutenticacion } from "../../contexts/autenticacion/ContextAutenticacion";
import { useHistory } from "react-router-dom";

export const DatosBasicos = ({ navigation }) => {
  const history = useHistory();
  const [datosBasicos, setDatosBasicos] = useState({
    nombre: "",
    apellido: "",
    sexo: undefined,
    genero: "",
    edad: "",
    telefono: "",
    correo_electronico: "",
    ciudad_domicilio: "",
    barrio_domicilio: "",
    tipo_documento: undefined,
    documento: "",
    descripcion_otro: "",
    origen_pais: "",
    origen_departamento: "",
    origen_ciudad: "",
  });

  const [flag, setFlag] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [on, setOn] = useState("");

  const contextformulario = useContext(ContextFormulario);
  const {
    agregarDatosBasicos,
    usuario_seleccionado,
    actualizarDatosBasicos,
    listado,
    borrarUsuarioSeleccionado,
  } = contextformulario;

  const contextAutenticacion = useContext(ContextAutenticacion);
  const { usuario } = contextAutenticacion;

  //console.log(usuario_seleccionado);

  const {
    tipo_documento,
    documento,
    nombre,
    apellido,
    sexo,
    genero,
    ciudad_domicilio,
    origen_pais,
    origen_departamento,
    origen_ciudad,
    descripcion_otro,
    edad,
    telefono,
    correo_electronico,
    barrio_domicilio,
  } = datosBasicos;

  useEffect(() => {
    if (usuario_seleccionado !== null) {
      setDatosBasicos({
        nombres: usuario_seleccionado.persona.nombre,
        apellidos: usuario_seleccionado.persona.apellido,
        sexo: usuario_seleccionado.persona.sexo,
        genero: usuario_seleccionado.persona.genero,
        edad: usuario_seleccionado.persona.edad,
        telefono: usuario_seleccionado.telefono,
        correo_electronico: usuario_seleccionado.correo_electronico,
        ciudad_domicilio: usuario_seleccionado.ciudad_domicilio,
        barrio_domicilio: usuario_seleccionado.barrio_domicilio,
        documento: usuario_seleccionado.persona.tipo_documento,
        numero_documento: usuario_seleccionado.persona.documento,
        descripcion_otro: usuario_seleccionado.persona.descripcion_otro,
        origen_pais: usuario_seleccionado.persona.origen_pais,
        origen_departamento: usuario_seleccionado.persona.origen_departamento,
        origen_ciudad: usuario_seleccionado.persona.origen_ciudad,
      });
    }
  }, [usuario_seleccionado]);

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };

  const checkStyle = {
    display: "block",
    marginRight: "0",
  };

  const checkStyle1 = {
    display: "block",
    marginRight: "0",
    marginBottom: "1rem",
    paddingTop: "0.5rem",
  };

  const onFinish = (values) => {
    navigation.next();
  };

  const onFinishFailed = (errorInfo) => {
    //console.log("Failed:", errorInfo);
  };

  const onChange = (e) => {
    setDatosBasicos({
      ...datosBasicos,
      [e[0].name]: e[0].value,
    });
  };

  //console.log(listado);

  const goBack = () => {
    borrarUsuarioSeleccionado();
    history.goBack();
  };

  return (
    <>
      <Form
        layout="vertical"
        name="basic"
        initialValues={{
          nombre: usuario_seleccionado
            ? usuario_seleccionado.persona.nombre
            : "",
          apellido: usuario_seleccionado
            ? usuario_seleccionado.persona.apellido
            : "",
          sexo: usuario_seleccionado
            ? usuario_seleccionado.persona.sexo
            : undefined,
          genero: usuario_seleccionado
            ? usuario_seleccionado.persona.genero
            : undefined,
          edad: usuario_seleccionado ? usuario_seleccionado.persona.edad : "",
          telefono: usuario_seleccionado ? usuario_seleccionado.telefono : "",
          correo_electronico: usuario_seleccionado
            ? usuario_seleccionado.correo_electronico
            : "",
          ciudad_domicilio: usuario_seleccionado
            ? usuario_seleccionado.ciudad_domicilio
            : "",
          barrio_domicilio: usuario_seleccionado
            ? usuario_seleccionado.barrio_domicilio
            : "",
          tipo_documento: usuario_seleccionado
            ? usuario_seleccionado.persona.tipo_documento
            : undefined,
          documento: usuario_seleccionado
            ? usuario_seleccionado.persona.documento
            : "",
          descripcion_otro: usuario_seleccionado
            ? usuario_seleccionado.persona.descripcion_otro
            : "",
          origen_pais: usuario_seleccionado
            ? usuario_seleccionado.persona.origen_pais
            : "",
          origen_departamento: usuario_seleccionado
            ? usuario_seleccionado.persona.origen_departamento
            : "",
          origen_ciudad: usuario_seleccionado
            ? usuario_seleccionado.persona.origen_ciudad
            : "",
        }}
        onFieldsChange={onChange}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Card className="borde">
          <div className="titulo">
            <p>1. Datos personales del encuestado</p>
          </div>
          <Row gutter={[40, 16]} justify="left">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="1. Nombres:"
                name="nombre"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={nombre}
              >
                <Input disabled={disabled} placeholder="Digite sus nombres" />
              </Form.Item>
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="2. Apellidos:"
                name="apellido"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={apellido}
              >
                <Input disabled={disabled} placeholder="Digite sus apellidos" />
              </Form.Item>
            </Col>
          </Row>

          <Divider />
          <Row gutter={[40, 16]} justify="center">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="3. Sexo:"
                name="sexo"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={sexo}
              >
                <Select disabled={disabled} placeholder="Seleccione">
                  <Select.Option value={1}>Masculino</Select.Option>
                  <Select.Option value={2}>Femenino</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="4. Género:"
                name="genero"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={genero}
              >
                <Select disabled={disabled} placeholder="Seleccione">
                  <Select.Option value="1">Hombre</Select.Option>
                  <Select.Option value="2">Mujer</Select.Option>
                  <Select.Option value="3">Hombre Transgenero</Select.Option>
                  <Select.Option value="4">Mujer Transgenero</Select.Option>
                  <Select.Option value="5">Intersexual</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={[40, 16]} justify="center">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="5. Edad:"
                name="edad"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={edad}
              >
                <Input disabled={disabled} placeholder="Digite su edad" />
              </Form.Item>
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="6. Telefono:"
                name="telefono"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={telefono}
              >
                <InputNumber
                  disabled={disabled}
                  style={{ width: "100%" }}
                  placeholder="Digite su telefono"
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={[40, 16]} justify="center">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="7. Correo electronico:"
                name="correo_electronico"
                rules={[
                  {
                    type: "email",
                    required: false,
                    message: "Digite un correo valido",
                  },
                ]}
                value={correo_electronico}
              >
                <Input
                  disabled={disabled}
                  placeholder="Digite su correo electronico"
                />
              </Form.Item>
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="8. Ciudad/Corregimiento de domicilio:"
                name="ciudad_domicilio"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={ciudad_domicilio}
              >
                <Input
                  disabled={disabled}
                  placeholder="Digite su Ciudad/Corregimiento"
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={[40, 16]} justify="center">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="9. Barrio de domicilio:"
                name="barrio_domicilio"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={barrio_domicilio}
              >
                <Input
                  disabled={disabled}
                  placeholder="Digite su barrio de domicilio"
                />
              </Form.Item>
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="10. Tipo de documento de identidad:"
                name="tipo_documento"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={tipo_documento}
              >
                <Select
                  disabled={disabled}
                  placeholder="Seleccione su tipo de documento de identidad"
                >
                  <Select.Option value={1}>
                    Cédula de identidad venezolana
                  </Select.Option>
                  <Select.Option value={2}>Cedula de ciudadanía</Select.Option>
                  <Select.Option value={3}>Cédula de Extranjería</Select.Option>
                  <Select.Option value={4}>Pasaporte</Select.Option>
                  <Select.Option value={5}>Registro Civil</Select.Option>
                  <Select.Option value={6}>Tarjeta de identidad</Select.Option>
                  <Select.Option value={7}>PEP</Select.Option>
                  <Select.Option value={8}>Visa</Select.Option>
                  <Select.Option value={9}>Otro</Select.Option>
                </Select>
              </Form.Item>

              {tipo_documento === 9 ? (
                <Form.Item
                  style={{ paddingTop: "1rem" }}
                  label="Digite la descripción"
                  name="descripcion_otro"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                  value={descripcion_otro}
                >
                  <Input disabled={disabled} placeholder="Descripción" />
                </Form.Item>
              ) : null}
            </Col>
          </Row>
          <Divider />
          <Row gutter={[40, 16]} justify="left">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="11. Número de documento:"
                name="documento"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={documento}
              >
                <Input
                  disabled={disabled}
                  placeholder="Digite número de documento"
                />
              </Form.Item>
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="12. Lugar de origen:"
                name="origen_pais"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={origen_pais}
              >
                <Input disabled={disabled} placeholder="País" />
              </Form.Item>

              <Form.Item
                style={{ paddingTop: "0.5rem" }}
                name="origen_departamento"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={origen_departamento}
              >
                <Input disabled={disabled} placeholder="Estado/Departamento" />
              </Form.Item>

              <Form.Item
                style={{ paddingTop: "0.5rem" }}
                name="origen_ciudad"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={origen_ciudad}
              >
                <Input disabled={disabled} placeholder="Municipio/Ciudad" />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Form.Item>
              <Button
                style={{ marginRight: "2rem" }}
                type="primary"
                onClick={() => goBack()}
              >
                Atras
              </Button>
              <Button type="primary" htmlType="submit">
                Siguiente
              </Button>
            </Form.Item>
          </div>
        </Card>
      </Form>
    </>
  );
};
