//Registro e inicio de sesion
export const REGISTRO_EXITOSO = "REGISTRO_EXITOSO";
export const REGISTRO_ERROR = "REGISTRO_ERROR";
export const OBTENER_USUARIO = "OBTENER_USUARIO";
export const LOGIN_EXITOSO = "LOGIN_EXITOSO";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const CERRAR_SESION = "CERRAR_SESION";

//Formulario
export const REGISTRAR_DATOS_BASICOS = "REGISTRAR_DATOS_BASICOS"
export const ACTUALIZAR_DATOS_BASICOS = "ACTUALIZAR_DATOS_BASICOS"
export const ACTUALIZAR_DATOS_PERSONALES = "ACTUALIZAR_DATOS_PERSONALES"
export const ACTUALIZAR_ENTORNO_FAMILIAR = "ACTUALIZAR_ENTORNO_FAMILIAR"
export const ACTUALIZAR_SALUD_FAMILIAR = "ACTUALIZAR_SALUD_FAMILIAR"
export const ACTUALIZAR_MORBILIDAD_MENOR = "ACTUALIZAR_MORBILIDAD_MENOR"
export const ACTUALIZAR_MORBILIDAD_MAYOR = "ACTUALIZAR_MORBILIDAD_MAYOR"
export const ACTUALIZAR_MORBILIDAD_CRONICA = "ACTUALIZAR_MORBILIDAD_CRONICA"
export const ACTUALIZAR_MORTALIDAD = "ACTUALIZAR_MORTALIDAD"
export const ACTUALIZAR_SALUD_MENTAL = "ACTUALIZAR_SALUD_MENTAL"
export const ACTUALIZAR_JURIDICA = "ACTUALIZAR_JURIDICA"
export const ACTUALIZAR_PSICOLOGICA = "ACTUALIZAR_PSICOLOGICA"
export const ACTUALIZAR_EMPLEABILIDAD = "ACTUALIZAR_EMPLEABILIDAD"
export const OBTENER_USUARIO_SELECCIONADO_DATOS_PERSONALES = "OBTENER_USUARIO_SELECCIONADO_DATOS_PERSONALES"
export const OBTENER_USUARIO_SELECCIONADO_ENTORNO_FAMILIAR = "OBTENER_USUARIO_SELECCIONADO_ENTORNO_FAMILIAR"
export const OBTENER_USUARIO_SELECCIONADO_SALUD_FAMILIAR = "OBTENER_USUARIO_SELECCIONADO_SALUD_FAMILIAR"
export const OBTENER_USUARIO_SELECCIONADO_MORBILIDAD = "OBTENER_USUARIO_SELECCIONADO_MORBILIDAD"
export const OBTENER_USUARIO_SELECCIONADO_SALUD_MENTAL = "OBTENER_USUARIO_SELECCIONADO_SALUD_MENTAL"
export const OBTENER_USUARIO_SELECCIONADO_JURIDICA = "OBTENER_USUARIO_SELECCIONADO_JURIDICA"
export const OBTENER_USUARIO_ASIGNACION = "OBTENER_USUARIO_ASIGNACION"
export const ASESORES_ASIGNADOS = "ASESORES_ASIGNADOS"
export const OBTENER_SEGUIMIENTO_SELECCIONADO = "OBTENER_SEGUIMIENTO_SELECCIONADO"
export const REGISTRAR_SEGUIMIENTO_JURIDICO = "REGISTRAR_SEGUIMIENTO_JURIDICO"
export const OBTENER_SEGUIMIENTOS_JURIDICA = "OBTENER_SEGUIMIENTOS_JURIDICA"
export const SEGUIMIENTO_SELECCIONADO_EDITAR = "SEGUIMIENTO_SELECCIONADO_EDITAR"
export const EDITAR_SEGUIMIENTO = "EDITAR_SEGUIMIENTO"
export const ELIMINAR_SEGUIMIENTO_JURIDICO = "ELIMINAR_SEGUIMIENTO_JURIDICO"
export const OBTENER_SEGUIMIENTOS_EMPLEABILIDAD = "OBTENER_SEGUIMIENTOS_EMPLEABILIDAD"
export const REGISTRAR_SEGUIMIENTO_EMPLEABILIDAD = "REGISTRAR_SEGUIMIENTO_EMPLEABILIDAD"
export const EDITAR_SEGUIMIENTO_EMPLEABILIDAD = "EDITAR_SEGUIMIENTO_EMPLEABILIDAD"
export const ELIMINAR_SEGUIMIENTO_EMPLEABILIDAD = "ELIMINAR_SEGUIMIENTO_EMPLEABILIDAD"
export const OBTENER_SEGUIMIENTOS_PSICOSOCIAL = "OBTENER_SEGUIMIENTOS_PSICOSOCIAL"
export const REGISTRAR_SEGUIMIENTO_PSICOSOCIAL = "REGISTRAR_SEGUIMIENTO_PSICOSOCIAL"
export const EDITAR_SEGUIMIENTO_PSICOSOCIAL = "EDITAR_SEGUIMIENTO_PSICOSOCIAL"
export const ELIMINAR_SEGUIMIENTO_PSICOSOCIAL = "ELIMINAR_SEGUIMIENTO_PSICOSOCIAL"


export const OBTENER_USUARIO_SELECCIONADO_BACK = "OBTENER_USUARIO_SELECCIONADO_BACK"
export const ACTUALIZAR_USUARIO_ADMIN = "ACTUALIZAR_USUARIO_ADMIN"







export const ACTUALIZAR_TIPO_ASESORIA = "ACTUALIZAR_TIPO_ASESORIA"
export const ERROR_FORMULARIO = "ERROR_FORMULARIO";

//Tablas
export const OBTENER_PRESTAMOS = "OBTENER_PRESTAMOS"
export const OBTENER_CARACTERIZACIONES = "OBTENER_CARACTERIZACIONES"
export const ELIMINAR_CARACTERIZACION = "ELIMINAR_CARACTERIZACION"

export const PRESTAMO_ERROR = "PRESTAMO_ERROR";

//Usuarios
export const REGISTRAR_USUARIO = "REGISTRAR_USUARIO"
export const OBTENER_USUARIOS = "OBTENER_USUARIOS"
export const OBTENER_USUARIO_SELECCIONADO = "OBTENER_USUARIO_SELECCIONADO"
export const USUARIO_SELECCIONADO_EDITAR = "USUARIO_SELECCIONADO_EDITAR"
export const ELIMINAR_USUARIO = "ELIMINAR_USUARIO"


//Alertas
export const MOSTRAR_ALERTA = "MOSTRAR_ALERTA";
export const OCULTAR_ALERTA = "OCULTAR_ALERTA";


//Borrar
export const BORRAR = "BORRAR";

//Asesor
export const USUARIO_SELECCIONADO_ASESOR = "USUARIO_SELECCIONADO_ASESOR";
export const OBTENER_ASESORES = "OBTENER_ASESORES";

//Asignar asesor
export const ASIGNAR_ASESOR = "ASIGNAR_ASESOR";


