import React, { useState, useContext, useEffect } from "react";
import { ContextFormulario } from "../../contexts/formulario/ContextFormulario";
import {
  Form,
  Input,
  Button,
  Radio,
  Row,
  Col,
  Card,
  Select,
  InputNumber,
  Checkbox,
  Space,
  Divider,
} from "antd";
import "./styles.css";
import { ContextAutenticacion } from "../../contexts/autenticacion/ContextAutenticacion";
import { useHistory } from "react-router-dom";

export const Juridica = ({ navigation }) => {
  const history = useHistory();
  const [flag, setFlag] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [datosBasicos, setDatosBasicos] = useState({
    medio_atencion: "",
    recibido_tipo_ayuda: "",
    asistio_organizacion: "",
    asistio_organizacion_otro: "",
    requerimientos_organizacion: "",
    requerimientos_organizacion_otro: "",
    califica_atencion: "",
    califica_atencion_porque: "",
    resolver_situacion: "",
    tiempo_solicitud: "",
    tiempo_solicitud_otro: "",
    principal_necesidad: "",
    principal_necesidad_otro: "",
    resumen_consulta: "",
    documentos_entregados: "",
  });

  const contextformulario = useContext(ContextFormulario);
  const {
    actualizarJuridica,
    usuario_seleccionado,
    formulario_basico,
    usuario_seleccionado_juridica,
  } = contextformulario;
  const contextAutenticacion = useContext(ContextAutenticacion);
  const { usuario } = contextAutenticacion;
  const [data_formulario, setDataFormulario] = useState("");

  const {
    medio_atencion,
    recibido_tipo_ayuda,
    asistio_organizacion,
    asistio_organizacion_otro,
    requerimientos_organizacion,
    requerimientos_organizacion_otro,
    califica_atencion,
    califica_atencion_porque,
    resolver_situacion,
    tiempo_solicitud,
    tiempo_solicitud_otro,
    principal_necesidad,
    principal_necesidad_otro,
    resumen_consulta,
    documentos_entregados,
  } = datosBasicos;

  useEffect(() => {
    if (
      usuario_seleccionado !== null &&
      usuario_seleccionado_juridica !== null
    ) {
      setDatosBasicos({
        medio_atencion: usuario_seleccionado.medio_atencion,
        recibido_tipo_ayuda: usuario_seleccionado.recibido_tipo_ayuda,
        asistio_organizacion:
          usuario_seleccionado_juridica.asistio_organizacion,
        asistio_organizacion_otro:
          usuario_seleccionado.asistio_organizacion_otro,
        requerimientos_organizacion:
          usuario_seleccionado_juridica.requerimientos_organizacion,
        requerimientos_organizacion_otro:
          usuario_seleccionado.requerimientos_organizacion_otro,
        califica_atencion: usuario_seleccionado.califica_atencion,
        califica_atencion_porque: usuario_seleccionado.califica_atencion_porque,
        resolver_situacion: usuario_seleccionado.resolver_situacion,
        tiempo_solicitud: usuario_seleccionado.tiempo_solicitud,
        tiempo_solicitud_otro: usuario_seleccionado.tiempo_solicitud_otro,
        principal_necesidad: usuario_seleccionado_juridica.principal_necesidad,
        principal_necesidad_otro: usuario_seleccionado.principal_necesidad_otro,
        resumen_consulta: usuario_seleccionado.resumen_consulta,
        documentos_entregados: usuario_seleccionado.documentos_entregados,
      });
    }
  }, [usuario_seleccionado]);

  useEffect(() => {
    if (formulario_basico.length > 0) {
      setDataFormulario(formulario_basico[0]);
    }
  }, [formulario_basico]);

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };

  const checkStyle = {
    display: "block",
    marginRight: "0",
  };

  const checkStyle1 = {
    display: "block",
    marginRight: "0",
    marginBottom: "1rem",
    paddingTop: "0.5rem",
  };

  const onFinish = (values) => {
    navigation.next();
  };

  const onFinishFailed = (errorInfo) => {
    //console.log("Failed:", errorInfo);
  };

  const onChange = (e) => {
    setDatosBasicos({
      ...datosBasicos,
      [e[0].name]: e[0].value,
    });
  };

  return (
    <>
      <Form
        layout="vertical"
        name="basic"
        initialValues={{
          medio_atencion: usuario_seleccionado
            ? usuario_seleccionado.medio_atencion
            : undefined,
          recibido_tipo_ayuda: usuario_seleccionado
            ? usuario_seleccionado.recibido_tipo_ayuda
            : "",
          asistio_organizacion: usuario_seleccionado_juridica
            ? usuario_seleccionado_juridica.asistio_organizacion
            : "",
          asistio_organizacion_otro: usuario_seleccionado
            ? usuario_seleccionado.asistio_organizacion_otro
            : "",
          requerimientos_organizacion: usuario_seleccionado_juridica
            ? usuario_seleccionado_juridica.requerimientos_organizacion
            : "",
          requerimientos_organizacion_otro: usuario_seleccionado
            ? usuario_seleccionado.requerimientos_organizacion_otro
            : "",
          califica_atencion: usuario_seleccionado
            ? usuario_seleccionado.califica_atencion
            : "",
          califica_atencion_porque: usuario_seleccionado
            ? usuario_seleccionado.califica_atencion_porque
            : "",
          resolver_situacion: usuario_seleccionado
            ? usuario_seleccionado.resolver_situacion
            : "",
          tiempo_solicitud: usuario_seleccionado
            ? usuario_seleccionado.tiempo_solicitud
            : "",
          tiempo_solicitud_otro: usuario_seleccionado
            ? usuario_seleccionado.tiempo_solicitud_otro
            : "",
          principal_necesidad: usuario_seleccionado_juridica
            ? usuario_seleccionado_juridica.principal_necesidad
            : "",
          principal_necesidad_otro: usuario_seleccionado
            ? usuario_seleccionado.principal_necesidad_otro
            : "",
          resumen_consulta: usuario_seleccionado
            ? usuario_seleccionado.resumen_consulta
            : "",
          documentos_entregados: usuario_seleccionado
            ? usuario_seleccionado.documentos_entregados
            : "",
        }}
        onFieldsChange={onChange}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Card className="borde">
          <div className="titulo">
            <p>3. Asesoría Jurídica</p>
          </div>
          <Row gutter={[40, 16]} justify="left">
            <Col lg={12} md={12} xs={24}>
              <p style={{ fontWeight: "500" }}>
                <span style={{ color: "#ff4d4f" }}>* </span>90. En la actualidad
                ¿cuál considera que es la principal necesidad de usted y su
                familia? (Puede marcar varios ítems)
              </p>
              <Row>
                <Col lg={14} md={12} xs={12}>
                  <Form.Item
                    name="principal_necesidad"
                    rules={[
                      {
                        required: true,
                        message: "Este campo es requerido",
                      },
                    ]}
                    value={principal_necesidad}
                  >
                    <Checkbox.Group disabled={disabled}>
                      <Checkbox style={checkStyle1} value={1}>
                        Empleo digno
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={2}>
                        Educación
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={3}>
                        Afiliación a la salud
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={4}>
                        Vivienda
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={5}>
                        Alimentos
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={6}>
                        Legalización de la situación
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={7}>
                        Otro
                      </Checkbox>
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
                <Col lg={10} md={12} xs={12}>
                  {principal_necesidad !== null &&
                  principal_necesidad.includes(7) ? (
                    <Form.Item
                      style={{ marginTop: "18.5rem" }}
                      name="principal_necesidad_otro"
                      rules={[
                        {
                          required: true,
                          message: "Este campo es requerido",
                        },
                      ]}
                      value={principal_necesidad_otro}
                    >
                      <Input
                        disabled={disabled}
                        placeholder="Otro"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  ) : null}
                </Col>
              </Row>
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="91. ¿Ha recibido algún tipo de ayuda, atención, o apoyo de otra entidad u organización estatal?"
                name="recibido_tipo_ayuda"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={recibido_tipo_ayuda}
              >
                <Select disabled={disabled} placeholder="Seleccione">
                  <Select.Option value="1">Si</Select.Option>
                  <Select.Option value="2">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[40, 16]} justify="left">
            <Col lg={12} md={12} xs={24}>
              <p style={{ fontWeight: "500" }}>
                <span style={{ color: "#ff4d4f" }}> </span>92. ¿A qué tipo de
                organización o institución asistió? (puede marcar varias
                opciones)
              </p>
              <Row>
                <Col lg={14} md={12} xs={12}>
                  <Form.Item
                    name="asistio_organizacion"
                    rules={[
                      {
                        required: false,
                        message: "Este campo es requerido",
                      },
                    ]}
                    value={asistio_organizacion}
                  >
                    <Checkbox.Group disabled={disabled}>
                      <Checkbox style={checkStyle1} value={1}>
                        Migración Colombia
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={2}>
                        Institución de salud
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={3}>
                        Institución educativa
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={4}>
                        Registraduría
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={5}>
                        Casa de justicia
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={6}>
                        Otro
                      </Checkbox>
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
                <Col lg={10} md={12} xs={12}>
                  {asistio_organizacion !== null &&
                  asistio_organizacion.includes(6) ? (
                    <Form.Item
                      style={{ marginTop: "17.5rem" }}
                      name="asistio_organizacion_otro"
                      rules={[
                        {
                          required: false,
                          message: "Este campo es requerido",
                        },
                      ]}
                      value={asistio_organizacion_otro}
                    >
                      <Input
                        disabled={disabled}
                        placeholder="Otro"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  ) : null}
                </Col>
              </Row>
            </Col>

            <Col lg={12} md={12} xs={24}>
              <p style={{ fontWeight: "500" }}>
                <span style={{ color: "#ff4d4f" }}> </span>93. ¿Qué tipos de
                requerimientos solicito usted en esa institución? (puede marcar
                varios)
              </p>
              <Row>
                <Col lg={14} md={12} xs={12}>
                  <Form.Item
                    name="requerimientos_organizacion"
                    rules={[
                      {
                        required: false,
                        message: "Este campo es requerido",
                      },
                    ]}
                    value={requerimientos_organizacion}
                  >
                    <Checkbox.Group disabled={disabled}>
                      <Checkbox style={checkStyle1} value={1}>
                        Jurídicos
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={2}>
                        Educación
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={3}>
                        Salud
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={4}>
                        Empleo
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={5}>
                        Documentación
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={6}>
                        Otro
                      </Checkbox>
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
                <Col lg={10} md={12} xs={12}>
                  {requerimientos_organizacion !== null &&
                  requerimientos_organizacion.includes(6) ? (
                    <Form.Item
                      style={{ marginTop: "17.5rem" }}
                      name="requerimientos_organizacion_otro"
                      rules={[
                        {
                          required: false,
                          message: "Este campo es requerido",
                        },
                      ]}
                      value={requerimientos_organizacion_otro}
                    >
                      <Input
                        disabled={disabled}
                        placeholder="Otro"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[40, 16]} justify="left">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="94. ¿Con respecto a la pregunta anterior, como califica la atención y el servicio que recibió? "
                name="califica_atencion"
                rules={[
                  {
                    required: false,
                    message: "Este campo es requerido",
                  },
                ]}
                value={califica_atencion}
              >
                <Select disabled={disabled} placeholder="Seleccione">
                  <Select.Option value="1">Muy bueno</Select.Option>
                  <Select.Option value="2">Regular</Select.Option>
                  <Select.Option value="3">Malo</Select.Option>
                </Select>
              </Form.Item>
              {califica_atencion === "2" || califica_atencion === "3" ? (
                <Form.Item
                  style={{ paddingTop: "1rem" }}
                  label="¿Por qué?"
                  name="califica_atencion_porque"
                  rules={[
                    {
                      required: false,
                      message: "Este campo es requerido",
                    },
                  ]}
                  value={califica_atencion_porque}
                >
                  <Input disabled={disabled} placeholder="Digite" />
                </Form.Item>
              ) : null}
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="95. ¿Con respecto a la pregunta anterior logro resolver la situación que tenía?"
                name="resolver_situacion"
                rules={[
                  {
                    required: false,
                    message: "Este campo es requerido",
                  },
                ]}
                value={resolver_situacion}
              >
                <Select disabled={disabled} placeholder="Seleccione">
                  <Select.Option value="1">
                    Si me ayudaron enseguida
                  </Select.Option>
                  <Select.Option value="2">
                    Toco hacer una serie de trámites y al final no se solucionó
                  </Select.Option>
                  <Select.Option value="3">
                    Toco hacer una serie de trámites y al final se solucionó
                  </Select.Option>
                  <Select.Option value="4">No ayudaron</Select.Option>
                  <Select.Option value="5">
                    Aún está a la espera de respuesta
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[40, 16]} justify="left">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="96. Cuanto tiempo se demoró la solitud que usted fue a realizar:"
                name="tiempo_solicitud"
                rules={[
                  {
                    required: false,
                    message: "Este campo es requerido",
                  },
                ]}
                value={tiempo_solicitud}
              >
                <Select disabled={disabled} placeholder="Seleccione">
                  <Select.Option value="1">N° de Meses</Select.Option>
                  <Select.Option value="2">Inmediato</Select.Option>
                  <Select.Option value="3">Se demoraron mucho</Select.Option>
                  <Select.Option value="4">Nunca le respondieron</Select.Option>
                  <Select.Option value="5">Otro</Select.Option>
                </Select>
              </Form.Item>
              {tiempo_solicitud === "1" || tiempo_solicitud === "5" ? (
                <Form.Item
                  style={{ paddingTop: "1rem" }}
                  label="¿Por qué?"
                  name="tiempo_solicitud_otro"
                  rules={[
                    {
                      required: false,
                      message: "Este campo es requerido",
                    },
                  ]}
                  value={tiempo_solicitud_otro}
                >
                  <Input disabled={disabled} placeholder="Digite" />
                </Form.Item>
              ) : null}
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="97. Medio de atención"
                name="medio_atencion"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={medio_atencion}
              >
                <Select disabled={disabled} placeholder="Seleccione">
                  <Select.Option value="1">Presencial</Select.Option>
                  <Select.Option value="2">Telefónica</Select.Option>
                  <Select.Option value="3">Brigada Jurídica</Select.Option>
                  <Select.Option value="4">Virtual</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[40, 16]} justify="left">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                style={{ paddingTop: "1rem" }}
                label="98. Resumen hecho de la consulta jurídica:"
                name="resumen_consulta"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={resumen_consulta}
              >
                <Input.TextArea disabled={disabled} placeholder="Digite" />
              </Form.Item>
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                style={{ paddingTop: "1rem", display: "none" }}
                label="99. Relacionar los documentos entregado por el usuario:"
                name="documentos_entregados"
                rules={[
                  {
                    required: false,
                    message: "Este campo es requerido",
                  },
                ]}
                value={documentos_entregados}
              >
                <Input.TextArea disabled={disabled} placeholder="Digite" />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Form.Item>
              <Button
                style={{ marginRight: "2rem" }}
                type="primary"
                onClick={() => navigation.previous()}
              >
                Atras
              </Button>
              <Button type="primary" htmlType="submit">
                Siguiente
              </Button>
            </Form.Item>
          </div>
        </Card>
      </Form>
    </>
  );
};
