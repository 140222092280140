import React,{useContext, useEffect} from 'react';

// contextos
import ProviderAutenticacion from './contexts/autenticacion/ContextAutenticacion';

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
// Rutas
import Rutas from './routes/Rutas';


import tokenAuth from "./axios/tokenAuth";

// Estilos
import './assets/css/antd.css';
import './assets/scss/theme.scss';
import '../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import ProviderFormulario from './contexts/formulario/ContextFormulario';
import AlertaState from "./contexts/alerta/alertaState"





 //Revisar si tenemos un token
 const token = localStorage.getItem("token");
 if (token) {
   tokenAuth(token);
 }

// console.log(token)


  

function App(props) {

 
 
 

  return (
    <React.Fragment>
       
      <ProviderFormulario>
          <AlertaState>
      <ProviderAutenticacion>
       <Rutas />
      </ProviderAutenticacion>
  </AlertaState>
      </ProviderFormulario>
    </React.Fragment>
  );
}

export default App;
