import React, { useContext, useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { userRoutes, authRoutes, adminRoutes, asesorRoutes } from './allRoutes';
import { ContextAutenticacion } from '../contexts/autenticacion/ContextAutenticacion';
import NonAuthLayout from '../components/NonAuthLayout';
import Authmiddleware from './middleware/Authmiddleware';
import VerticalLayout from '../components/VerticalLayout/';
import { Spin } from 'antd';




const Rutas = () => {
  const contextAutenticacion = useContext(ContextAutenticacion);
   const {autenticado, cargando, usuarioAutenticado, usuario} = contextAutenticacion;

    useEffect(() => {
      
      usuarioAutenticado();
        // eslint-disable-next-line
    }, [])

    //console.log(autenticado)
  
  
  

   const token = localStorage.getItem("token");
	//console.log(token)

  const getLayout = () => {
    let layoutCls = VerticalLayout;
    return layoutCls;
  };

  const Layout = getLayout();

  const NonAuthmiddleware = ({ component: Component, layout: Layout }) => (
    <Route
      render={(props) => {
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );

  

  return (
  

    <BrowserRouter>
      <Switch>
        { autenticado === null && authRoutes.map((route, idx) => (
          <NonAuthmiddleware
            path={route.path}
            layout={NonAuthLayout}
            component={route.component}
            key={idx}
          />
        ))}


      {usuario !== null && usuario.roles[0] === "ROLE_USER" && autenticado  && userRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            layout={Layout}
            component={route.component}
            key={idx}
          />
        ))} 
         
         {usuario !== null && usuario.roles[0] === "ROLE_MODERATOR" && autenticado  &&  asesorRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            layout={Layout}
            component={route.component}
            key={idx}
          />
        ))}

          {usuario !== null && usuario.roles[0] === "ROLE_ADMIN" && autenticado  &&  adminRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            layout={Layout}
            component={route.component}
            key={idx}
          />
        ))}

      </Switch>
    </BrowserRouter>
  );
};

export default Rutas;
