import React from 'react';
import { Row, Col, CardBody, Card, Container } from "reactstrap";
import {MultiStepForm} from '../../components/MultiStepForm';
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

const Formulario = () => {
    return ( 
   <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            titulo='Registrar Caso'
            breadcrumb={{
              actual: 'Registrar Caso',
              linkActual: '#',
              anterior: 'Registrar Caso',
              linkAnterior: '#',
            }}
          />
            <MultiStepForm/>
        </Container>
      </div>
    </React.Fragment>


     );
}
 
export default Formulario;