import React, { useState, useContext, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import megamenuImg from "../../assets/images/megamenu-img.png";
import logo from "../../assets/images/logotipo-oficial-unicartagena.png";
import logoLightPng from "../../assets/images/logo-light.png";
import logoLightSvg from "../../assets/images/logo-light.svg";
import logoDark from "../../assets/images/logo.png";

// import images
import github from "../../assets/images/brands/github.png";
import bitbucket from "../../assets/images/brands/bitbucket.png";
import dribbble from "../../assets/images/brands/dribbble.png";
import dropbox from "../../assets/images/brands/dropbox.png";
import mail_chimp from "../../assets/images/brands/mail_chimp.png";
import slack from "../../assets/images/brands/slack.png";

import { ContextAutenticacion } from "../../contexts/autenticacion/ContextAutenticacion";

//i18n
import { withNamespaces } from "react-i18next";

const Header = (props) => {
  const [search, setsearch] = useState(false);
  const [megaMenu, setmegaMenu] = useState(false);
  const [socialDrp, setsocialDrp] = useState(false);
  const [layout, setLayout] = useState("default");

  const contextAutenticacion = useContext(ContextAutenticacion);
  const { predio, obtenerPredio } = contextAutenticacion;

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  //  useEffect(() => {
  //   obtenerPredio()
  //  }, [])

  //  console.log(predio)

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function changeBodyAttribute(attribute, value) {
    if (document.body) document.body.setAttribute(attribute, value);
    return true;
  }

  function manageBodyClass(cssClass, action = "toggle") {
    switch (action) {
      case "add":
        if (document.body) document.body.classList.add(cssClass);
        break;
      case "remove":
        if (document.body) document.body.classList.remove(cssClass);
        break;
      default:
        if (document.body) document.body.classList.toggle(cssClass);
        break;
    }

    return true;
  }

  function changeLeftSidebarType(sidebarType, isMobile) {
    try {
      switch (sidebarType) {
        case "compact":
          changeBodyAttribute("data-sidebar-size", "small");
          manageBodyClass("sidebar-enable", "remove");
          manageBodyClass("vertical-collpsed", "remove");
          break;
        case "icon":
          changeBodyAttribute("data-keep-enlarged", "true");
          manageBodyClass("vertical-collpsed", "add");
          break;
        case "condensed":
          manageBodyClass("sidebar-enable", "add");
          if (!isMobile) manageBodyClass("vertical-collpsed", "add");
          break;

        default:
          changeBodyAttribute("data-sidebar-size", "");
          manageBodyClass("sidebar-enable", "remove");
          if (!isMobile) manageBodyClass("vertical-collpsed", "remove");

          break;
      }
    } catch (error) {}
  }

  function tToggle() {
    if (layout === "default") {
      changeLeftSidebarType("condensed", isMobile);
      setLayout("condensed");
    } else if (layout === "condensed") {
      changeLeftSidebarType("default", isMobile);
      setLayout("default");
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="30" width="50" />
                </span>
                <span className="logo-lg">
                  <img src={logo} alt="" height="50" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoLightSvg} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoLightPng} alt="" height="19" />
                </span>
              </Link>
            </div>
            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item waves-effect"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars"></i>
            </button>
          </div>
          {/* <div className="d-flex justify-content-end align-items-right pl-5 pt-3">
                   {predio ?  <p style={{fontSize:'14px'}}>Predio Seleccionado: <span style={{color:"#189716"}}>{predio.ID_PREDIO}</span> </p> : <p style={{fontSize:'14px'}}>No hay predio seleccionado</p> }
                </div> */}
          <div className="d-flex ">
            {/* <LanguageDropdown /> */}

            <Dropdown
              className="d-none d-lg-inline-block ml-1"
              isOpen={socialDrp}
              toggle={() => {
                setsocialDrp(!socialDrp);
              }}
            >
              {/*  <DropdownToggle className="btn header-item noti-icon waves-effect" tag="button">
                    <i className="bx bx-customize"></i>
                  </DropdownToggle>*/}
              <DropdownMenu className="dropdown-menu-lg" right>
                <div className="px-lg-2">
                  <Row className="no-gutters">
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={github} alt="Github" />
                        <span>GitHub</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={bitbucket} alt="bitbucket" />
                        <span>Bitbucket</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={dribbble} alt="dribbble" />
                        <span>Dribbble</span>
                      </Link>
                    </Col>
                  </Row>

                  <Row className="no-gutters">
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={dropbox} alt="dropbox" />
                        <span>Dropbox</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={mail_chimp} alt="mail_chimp" />
                        <span>Mail Chimp</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={slack} alt="slack" />
                        <span>Slack</span>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </DropdownMenu>
            </Dropdown>

            <div className="dropdown d-none d-lg-inline-block ml-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen();
                }}
                className="btn header-item noti-icon waves-effect"
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen"></i>
              </button>
            </div>
            {/* <NotificationDropdown /> */}
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default withNamespaces()(Header);
