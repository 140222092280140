import React, { useState, useContext, useEffect } from "react";
import { ContextFormulario } from "../../contexts/formulario/ContextFormulario";
import {
  Form,
  Input,
  Button,
  Radio,
  Row,
  Col,
  Card,
  Select,
  InputNumber,
  Checkbox,
  Space,
  Divider,
} from "antd";
import "./styles.css";
import { ContextAutenticacion } from "../../contexts/autenticacion/ContextAutenticacion";
import { useHistory } from "react-router-dom";

export const EntornoFamiliar = ({ navigation }) => {
  const history = useHistory();
  const [flag, setFlag] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [entornoFamiliar, setEntornoFamiliar] = useState({
    viviendo: "",
    viviendo_hijos: "",
    viviendo_hijas: "",
    viviendo_quien: "",
    integrantes_totales: "",
    integrantes_ninos: "",
    integrantes_adultos: "",
    hogares_residen: undefined,
    tipo_vivienda: undefined,
    tenencia_vivienda: undefined,
    tenencia_otra: "",
    condiciones_vivienda: undefined,
    ambiente_cocina: "",
    ambiente_sala: "",
    ambiente_habitacion: "",
    ambiente_bano: "",
    electrodomesticos: "",
    electrodomesticos_otro: "",
    prepara_alimentos: undefined,
    cuenta_bano: undefined,
    otro_bano: "",
    dormir: "",
    dormir_otro: "",
    espacio_seguro: undefined,
    espacio_seguro_porque: "",
    cambia_vivienda: undefined,
    motivo_vivienda: "",
  });

  const contextformulario = useContext(ContextFormulario);
  const {
    actualizarEntornoFamiliar,
    usuario_seleccionado,
    formulario_basico,
    usuario_seleccionado_entorno_familiar,
  } = contextformulario;
  const contextAutenticacion = useContext(ContextAutenticacion);
  const { usuario } = contextAutenticacion;
  const [data_formulario, setDataFormulario] = useState("");

  const {
    viviendo,
    viviendo_hijos,
    viviendo_hijas,
    viviendo_quien,
    integrantes_totales,
    integrantes_ninos,
    integrantes_adultos,
    hogares_residen,
    tipo_vivienda,
    tenencia_vivienda,
    tenencia_otra,
    condiciones_vivienda,
    ambiente_cocina,
    ambiente_sala,
    ambiente_habitacion,
    ambiente_bano,
    electrodomesticos,
    prepara_alimentos,
    cuenta_bano,
    otro_bano,
    dormir,
    dormir_otro,
    espacio_seguro,
    espacio_seguro_porque,
    cambia_vivienda,
    motivo_vivienda,
    electrodomesticos_otro,
  } = entornoFamiliar;

  useEffect(() => {
    if (
      usuario_seleccionado !== null &&
      usuario_seleccionado_entorno_familiar !== null
    ) {
      setEntornoFamiliar({
        condicion_migratoria: usuario_seleccionado.condicion_migratoria,
        viviendo: usuario_seleccionado_entorno_familiar.viviendo,
        viviendo_hijos: usuario_seleccionado.viviendo_hijos,
        viviendo_hijas: usuario_seleccionado.viviendo_hijas,
        viviendo_quien: usuario_seleccionado.viviendo_quien,
        integrantes_totales: usuario_seleccionado.integrantes_totales,
        integrantes_ninos: usuario_seleccionado.integrantes_ninos,
        integrantes_adultos: usuario_seleccionado.integrantes_adultos,
        hogares_residen: usuario_seleccionado.hogares_residen,
        tipo_vivienda: usuario_seleccionado.tipo_vivienda,
        tenencia_vivienda: usuario_seleccionado.tenencia_vivienda,
        tenencia_otra: usuario_seleccionado.tenencia_otra,
        condiciones_vivienda: usuario_seleccionado.condiciones_vivienda,
        ambiente_cocina: usuario_seleccionado.ambiente_cocina,
        ambiente_sala: usuario_seleccionado.ambiente_sala,
        ambiente_habitacion: usuario_seleccionado.ambiente_habitacion,
        ambiente_bano: usuario_seleccionado.ambiente_bano,
        electrodomesticos:
          usuario_seleccionado_entorno_familiar.electrodomesticos,
        electrodomesticos_otro:
          usuario_seleccionado_entorno_familiar.electrodomesticos_otro,
        prepara_alimentos: usuario_seleccionado.prepara_alimentos,
        cuenta_bano: usuario_seleccionado.cuenta_bano,
        otro_bano: usuario_seleccionado.otro_bano,
        dormir: usuario_seleccionado_entorno_familiar.dormir,
        dormir_otro: usuario_seleccionado.dormir_otro,
        espacio_seguro: usuario_seleccionado.espacio_seguro,
        espacio_seguro_porque: usuario_seleccionado.espacio_seguro_porque,
        cambia_vivienda: usuario_seleccionado.cambia_vivienda,
        motivo_vivienda: usuario_seleccionado.motivo_vivienda,
      });
    }
  }, [usuario_seleccionado]);

  useEffect(() => {
    if (formulario_basico.length > 0) {
      setDataFormulario(formulario_basico[0]);
    }
  }, [formulario_basico]);

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };

  const checkStyle = {
    display: "block",
    marginRight: "0",
  };

  const checkStyle1 = {
    display: "block",
    marginRight: "0",
    marginBottom: "1rem",
    paddingTop: "0.5rem",
  };

  const onFinish = (values) => {
    navigation.next();
  };

  const onFinishFailed = (errorInfo) => {
    //console.log("Failed:", errorInfo);
  };

  const onChange = (e) => {
    setEntornoFamiliar({
      ...entornoFamiliar,
      [e[0].name]: e[0].value,
    });
  };

  //console.log(usuario_seleccionado);

  return (
    <>
      <Form
        layout="vertical"
        name="entornoFamiliar"
        initialValues={{
          viviendo: usuario_seleccionado_entorno_familiar
            ? usuario_seleccionado_entorno_familiar.viviendo
            : "",
          viviendo_hijos: usuario_seleccionado
            ? usuario_seleccionado.viviendo_hijos
            : "",
          viviendo_hijas: usuario_seleccionado
            ? usuario_seleccionado.viviendo_hijas
            : "",
          viviendo_quien: usuario_seleccionado
            ? usuario_seleccionado.viviendo_quien
            : "",
          integrantes_totales: usuario_seleccionado
            ? usuario_seleccionado.integrantes_totales
            : "",
          integrantes_ninos: usuario_seleccionado
            ? usuario_seleccionado.integrantes_ninos
            : "",
          integrantes_adultos: usuario_seleccionado
            ? usuario_seleccionado.integrantes_adultos
            : "",
          hogares_residen: usuario_seleccionado
            ? usuario_seleccionado.hogares_residen
            : "",
          tipo_vivienda: usuario_seleccionado
            ? usuario_seleccionado.tipo_vivienda
            : "",
          tenencia_vivienda: usuario_seleccionado
            ? usuario_seleccionado.tenencia_vivienda
            : "",
          tenencia_otra: usuario_seleccionado
            ? usuario_seleccionado.tenencia_otra
            : "",
          condiciones_vivienda: usuario_seleccionado
            ? usuario_seleccionado.condiciones_vivienda
            : "",
          ambiente_cocina: usuario_seleccionado
            ? usuario_seleccionado.ambiente_cocina
            : "",
          ambiente_sala: usuario_seleccionado
            ? usuario_seleccionado.ambiente_sala
            : "",
          ambiente_habitacion: usuario_seleccionado
            ? usuario_seleccionado.ambiente_habitacion
            : "",
          ambiente_bano: usuario_seleccionado
            ? usuario_seleccionado.ambiente_bano
            : "",
          electrodomesticos: usuario_seleccionado_entorno_familiar
            ? usuario_seleccionado_entorno_familiar.electrodomesticos
            : "",
          electrodomesticos_otro: usuario_seleccionado_entorno_familiar
            ? usuario_seleccionado_entorno_familiar.electrodomesticos_otro
            : "",
          prepara_alimentos: usuario_seleccionado
            ? usuario_seleccionado.prepara_alimentos
            : "",
          cuenta_bano: usuario_seleccionado
            ? usuario_seleccionado.cuenta_bano
            : "",
          otro_bano: usuario_seleccionado ? usuario_seleccionado.otro_bano : "",
          dormir: usuario_seleccionado_entorno_familiar
            ? usuario_seleccionado_entorno_familiar.dormir
            : "",
          dormir_otro: usuario_seleccionado
            ? usuario_seleccionado.dormir_otro
            : "",
          espacio_seguro: usuario_seleccionado
            ? usuario_seleccionado.espacio_seguro
            : "",
          espacio_seguro_porque: usuario_seleccionado
            ? usuario_seleccionado.espacio_seguro_porque
            : "",
          cambia_vivienda: usuario_seleccionado
            ? usuario_seleccionado.cambia_vivienda
            : "",
          motivo_vivienda: usuario_seleccionado
            ? usuario_seleccionado.motivo_vivienda
            : "",
        }}
        onFieldsChange={onChange}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Card className="borde">
          <div className="titulo">
            <p>1.2 Datos del entorno familiar (vivienda)</p>
          </div>
          <Row gutter={[40, 16]} justify="left">
            <Col lg={12} md={12} xs={24}>
              <p style={{ fontWeight: "500" }}>
                <span style={{ color: "#ff4d4f" }}>* </span>41. ¿Con quienes
                está viviendo en este momento? (en la misma vivienda)
              </p>
              <Row>
                <Col lg={6} md={6} xs={12}>
                  <Form.Item
                    name="viviendo"
                    rules={[
                      {
                        required: true,
                        message: "Este campo es requerido",
                      },
                    ]}
                    value={viviendo}
                  >
                    <Checkbox.Group disabled={disabled}>
                      <Checkbox style={checkStyle1} value={1}>
                        Pareja
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={2}>
                        Hijos
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={3}>
                        Hijas
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={4}>
                        Madre
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={5}>
                        Padre
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={6}>
                        Tíos(as)
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={7}>
                        Abuelo
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={8}>
                        Abuela
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={9}>
                        Otro
                      </Checkbox>
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
                <Col lg={12} md={12} xs={12}>
                  {viviendo !== null && viviendo.includes(2) ? (
                    <Form.Item
                      style={{ marginTop: "3rem" }}
                      name="viviendo_hijos"
                      rules={[
                        {
                          required: true,
                          message: "Este campo es requerido",
                        },
                      ]}
                      value={viviendo_hijos}
                    >
                      <InputNumber
                        placeholder="¿Cuantos?"
                        style={{ width: "100%" }}
                        min={0}
                        disabled={disabled}
                      />
                    </Form.Item>
                  ) : null}

                  {viviendo !== null && viviendo.includes(3) ? (
                    <Form.Item
                      style={{ position: "absolute", top: "6rem" }}
                      name="viviendo_hijas"
                      rules={[
                        {
                          required: true,
                          message: "Este campo es requerido",
                        },
                      ]}
                      value={viviendo_hijas}
                    >
                      <InputNumber
                        placeholder="¿Cuantas?"
                        style={{ width: "100%" }}
                        min={0}
                        disabled={disabled}
                      />
                    </Form.Item>
                  ) : null}

                  {viviendo !== null && viviendo.includes(9) ? (
                    <Form.Item
                      style={{ position: "absolute", top: "23rem" }}
                      name="viviendo_quien"
                      rules={[
                        {
                          required: true,
                          message: "Este campo es requerido",
                        },
                      ]}
                      value={viviendo_quien}
                    >
                      <Input
                        disabled={disabled}
                        placeholder="Quien?"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  ) : null}
                </Col>
              </Row>
            </Col>
            <Col lg={12} md={12} xs={24}>
              <p style={{ fontWeight: "500" }}>
                <span style={{ color: "#ff4d4f" }}>* </span>42. Número de
                integrantes del hogar en Cartagena/Colombia:
              </p>
              <Form.Item
                label="Integrantes totales"
                style={{ marginTop: "3rem" }}
                name="integrantes_totales"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={integrantes_totales}
              >
                <InputNumber
                  placeholder="Cuántas personas viven en total"
                  style={{ width: "100%" }}
                  min={0}
                  disabled={disabled}
                />
              </Form.Item>
              <Form.Item
                label="Integrantes niños"
                style={{ marginTop: "3rem" }}
                name="integrantes_ninos"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={integrantes_ninos}
              >
                <InputNumber
                  placeholder="Cuántos niños"
                  style={{ width: "100%" }}
                  min={0}
                  disabled={disabled}
                />
              </Form.Item>
              <Form.Item
                label="Integrantes adultos"
                style={{ marginTop: "3rem" }}
                name="integrantes_adultos"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={integrantes_adultos}
              >
                <InputNumber
                  placeholder="Cuántos adultos"
                  style={{ width: "100%" }}
                  min={0}
                  disabled={disabled}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={[40, 16]} justify="center">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="43. ¿Cuántos hogares residen de manera habitual en esta vivienda? "
                name="hogares_residen"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={hogares_residen}
              >
                <Select disabled={disabled} placeholder="Seleccione">
                  <Select.Option value="1">1</Select.Option>
                  <Select.Option value="2">2</Select.Option>
                  <Select.Option value="3">3</Select.Option>
                  <Select.Option value="4">4</Select.Option>
                  <Select.Option value="5">5</Select.Option>
                  <Select.Option value="6">6</Select.Option>
                  <Select.Option value="7">7</Select.Option>
                  <Select.Option value="8">8</Select.Option>
                  <Select.Option value="9">9</Select.Option>
                  <Select.Option value="10">10</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="44. Tipo de vivienda"
                name="tipo_vivienda"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={tipo_vivienda}
              >
                <Select disabled={disabled} placeholder="Seleccione">
                  <Select.Option value="1">Casa</Select.Option>
                  <Select.Option value="2">Apartamento</Select.Option>
                  <Select.Option value="3">
                    Cuartos en inquilinatos
                  </Select.Option>
                  <Select.Option value="4">
                    Improvisada Carpa, refugios naturales, plásticos
                  </Select.Option>
                  <Select.Option value="5">Arriendos colectivos</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Divider />
          <Row gutter={[40, 16]} justify="center">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="45. Calidad de tenencia de la vivienda"
                name="tenencia_vivienda"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={tenencia_vivienda}
              >
                <Select disabled={disabled} placeholder="Seleccione">
                  <Select.Option value="1">Propia</Select.Option>
                  <Select.Option value="2">En arriendo</Select.Option>
                  <Select.Option value="3">Familiar</Select.Option>
                  <Select.Option value="4">En cuidado-prestada</Select.Option>
                  <Select.Option value="5">A cambio de trabajo</Select.Option>
                  <Select.Option value="6">Otra</Select.Option>
                </Select>
              </Form.Item>

              {tenencia_vivienda === "6" ? (
                <Form.Item
                  style={{ paddingTop: "1rem" }}
                  label="¿Cúal?"
                  name="tenencia_otra"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                  value={tenencia_otra}
                >
                  <Input disabled={disabled} placeholder="Digite" />
                </Form.Item>
              ) : null}
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="46. Condiciones de la vivienda (habitabilidad)"
                name="condiciones_vivienda"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={condiciones_vivienda}
              >
                <Select disabled={disabled} placeholder="Seleccione">
                  <Select.Option value="1">Altamente adecuadas</Select.Option>
                  <Select.Option value="2">Adecuaciones buenas</Select.Option>
                  <Select.Option value="3">
                    Adecuaciones regulares
                  </Select.Option>
                  <Select.Option value="4">
                    Adecuaciones poco aptas
                  </Select.Option>
                  <Select.Option value="5">Inadecuadas</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Divider />
          <Row gutter={[40, 16]} justify="left">
            <Col lg={12} md={12} xs={24}>
              <p style={{ fontWeight: "500" }}>
                <span style={{ color: "#ff4d4f" }}>* </span>47. La vivienda
                tiene por separados los siguientes ambientes:
              </p>
              <Form.Item
                label="Cocina:"
                name="ambiente_cocina"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={ambiente_cocina}
              >
                <Radio.Group disabled={disabled}>
                  <Radio style={radioStyle} value="1">
                    Si
                  </Radio>
                  <Radio style={radioStyle} value="2">
                    No
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="Sala o comedor:"
                name="ambiente_sala"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={ambiente_sala}
              >
                <Radio.Group disabled={disabled}>
                  <Radio style={radioStyle} value="1">
                    Si
                  </Radio>
                  <Radio style={radioStyle} value="2">
                    No
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="Habitación de adultos:"
                name="ambiente_habitacion"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={ambiente_habitacion}
              >
                <Radio.Group disabled={disabled}>
                  <Radio style={radioStyle} value="1">
                    Si
                  </Radio>
                  <Radio style={radioStyle} value="2">
                    No
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="Baño:"
                name="ambiente_bano"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={ambiente_bano}
              >
                <Radio.Group disabled={disabled}>
                  <Radio style={radioStyle} value="1">
                    Si
                  </Radio>
                  <Radio style={radioStyle} value="2">
                    No
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col lg={12} md={12} xs={24}>
              <p style={{ fontWeight: "500" }}>
                <span style={{ color: "#ff4d4f" }}>* </span>48. Con que
                electrodomésticos cuenta en su hogar, marque con una X cuando
                corresponda:
              </p>
              <Row>
                <Col lg={14} md={12} xs={12}>
                  <Form.Item
                    name="electrodomesticos"
                    rules={[
                      {
                        required: true,
                        message: "Este campo es requerido",
                      },
                    ]}
                    value={electrodomesticos}
                  >
                    <Checkbox.Group disabled={disabled}>
                      <Checkbox style={checkStyle} value={1}>
                        Televisor
                      </Checkbox>
                      <Checkbox style={checkStyle} value={2}>
                        Refrigerador/Nevera
                      </Checkbox>
                      <Checkbox style={checkStyle} value={3}>
                        Lavadora
                      </Checkbox>
                      <Checkbox style={checkStyle} value={4}>
                        Estufa
                      </Checkbox>
                      <Checkbox style={checkStyle} value={5}>
                        Computadora
                      </Checkbox>
                      <Checkbox style={checkStyle} value={6}>
                        Licuadora
                      </Checkbox>
                      <Checkbox style={checkStyle} value={7}>
                        Radio
                      </Checkbox>
                      <Checkbox style={checkStyle} value={8}>
                        Otro
                      </Checkbox>
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
                <Col lg={10} md={12} xs={12}>
                  {electrodomesticos !== null &&
                  electrodomesticos.includes(8) ? (
                    <Form.Item
                      style={{ marginTop: "9rem" }}
                      name="electrodomesticos_otro"
                      rules={[
                        {
                          required: true,
                          message: "Este campo es requerido",
                        },
                      ]}
                      value={electrodomesticos_otro}
                    >
                      <Input
                        disabled={disabled}
                        placeholder="Otro"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Row>

          <Divider />
          <Row gutter={[40, 16]} justify="center">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="49. Si la vivienda no cuenta con cocina ¿dónde prepara usted los alimentos?"
                name="prepara_alimentos"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={prepara_alimentos}
              >
                <Select disabled={disabled} placeholder="Seleccione">
                  <Select.Option value="1">Mesa en la sala</Select.Option>
                  <Select.Option value="2">En un cuarto</Select.Option>
                  <Select.Option value="3">En el patio</Select.Option>
                  <Select.Option value="4">
                    En la puerta de la calle
                  </Select.Option>
                  <Select.Option value="5">No aplica</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="50. Si la vivienda no cuenta con baño, ¿dónde realiza usted sus necesidades fisiológicas?"
                name="cuenta_bano"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={cuenta_bano}
              >
                <Select disabled={disabled} placeholder="Seleccione">
                  <Select.Option value="1">
                    Presta un baño a un vecino
                  </Select.Option>
                  <Select.Option value="2">Terreno baldío</Select.Option>
                  <Select.Option value="3">Baños públicos</Select.Option>
                  <Select.Option value="4">Otro</Select.Option>
                  <Select.Option value="5">No aplica</Select.Option>
                </Select>
              </Form.Item>
              {cuenta_bano === "4" ? (
                <Form.Item
                  style={{ paddingTop: "1rem" }}
                  label="¿Cúal?"
                  name="otro_bano"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                  value={otro_bano}
                >
                  <Input disabled={disabled} placeholder="Digite" />
                </Form.Item>
              ) : null}
            </Col>
          </Row>
          <Divider />
          <Row gutter={[40, 16]} justify="left">
            <Col lg={12} md={12} xs={24}>
              <p style={{ fontWeight: "500" }}>
                <span style={{ color: "#ff4d4f" }}>* </span>51. Qué utilizan
                para dormir las personas del hogar (puede marcar varias)
              </p>
              <Row>
                <Col lg={14} md={12} xs={12}>
                  <Form.Item
                    name="dormir"
                    rules={[
                      {
                        required: true,
                        message: "Este campo es requerido",
                      },
                    ]}
                    value={dormir}
                  >
                    <Checkbox.Group disabled={disabled}>
                      <Checkbox style={checkStyle1} value={1}>
                        Cama con colchón
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={2}>
                        Cama sin colchón
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={3}>
                        Colchón al piso
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={4}>
                        Colchonetas
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={5}>
                        Hamaca/Chinchorro
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={6}>
                        Esteras
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={7}>
                        Otro
                      </Checkbox>
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
                <Col lg={10} md={12} xs={12}>
                  {dormir !== null && dormir.includes(7) ? (
                    <Form.Item
                      style={{ marginTop: "17rem" }}
                      name="dormir_otro"
                      rules={[
                        {
                          required: true,
                          message: "Este campo es requerido",
                        },
                      ]}
                      value={dormir_otro}
                    >
                      <Input
                        disabled={disabled}
                        placeholder="Otro"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  ) : null}
                </Col>
              </Row>
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="52. ¿Considera que la vivienda se encuentra en un espacio seguro y adecuado para la convivencia familiar y vecinal?"
                name="espacio_seguro"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={espacio_seguro}
              >
                <Select disabled={disabled} placeholder="Seleccione">
                  <Select.Option value="1">Si</Select.Option>
                  <Select.Option value="2">No</Select.Option>
                </Select>
              </Form.Item>
              {espacio_seguro === "2" ? (
                <Form.Item
                  style={{ paddingTop: "1rem" }}
                  label="¿Por qué?"
                  name="espacio_seguro_porque"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                  value={espacio_seguro_porque}
                >
                  <Input disabled={disabled} placeholder="Digite" />
                </Form.Item>
              ) : null}
            </Col>
          </Row>
          <Divider />
          <Row gutter={[40, 16]} justify="left">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="53.	¿Cambia con frecuencia de vivienda?: "
                name="cambia_vivienda"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={cambia_vivienda}
              >
                <Select disabled={disabled} placeholder="Seleccione">
                  <Select.Option value="1">No</Select.Option>
                  <Select.Option value="2">Si</Select.Option>
                </Select>
              </Form.Item>
              {cambia_vivienda === "2" ? (
                <Form.Item
                  style={{ paddingTop: "1rem" }}
                  label="Motivo"
                  name="motivo_vivienda"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                  value={motivo_vivienda}
                >
                  <Input disabled={disabled} placeholder="Digite" />
                </Form.Item>
              ) : null}
            </Col>
          </Row>
          <Divider />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Form.Item>
              <Button
                style={{ marginRight: "2rem" }}
                type="primary"
                onClick={() => navigation.previous()}
              >
                Atras
              </Button>
              <Button type="primary" htmlType="submit">
                Siguiente
              </Button>
            </Form.Item>
          </div>
        </Card>
      </Form>
    </>
  );
};
