import React from "react";
import { useForm, useStep } from "react-hooks-helper";
import { DatosPersonales } from "./stepForm/DatosPersonales";
import { DatosBasicos } from "./stepForm/DatosBasicos";
import { EntornoFamiliar } from "./stepForm/EntornoFamiliar";
import { SaludFamiliar } from "./stepForm/SaludFamiliar";
import { MorbilidadMenores } from "./stepForm/MorbilidadMenores";
import { MorbilidadMayores } from "./stepForm/MorbilidadMayores";
import { MorbilidadCronica } from "./stepForm/MorbilidadCronica";
import { Mortalidad } from "./stepForm/Mortalidad";
import { SaludMental } from "./stepForm/SaludMental";
import { Review } from "./stepForm/Review";
import { Asignacion } from "./stepForm/Asignacion";
import { Submit } from "./stepForm/Submit";
import { Empleabilidad } from "./stepForm/Empleabilidad";
import { Juridica } from "./stepForm/Juridica";
import { Psicologica } from "./stepForm/Psicologica";
import { SeleccionarAsignacion } from "./stepForm/SeleccionarAsignacion";
import { AsignarAsesoria } from "./stepForm/AsignarAsesoria";
import { SubmitFinal } from "./stepForm/SubmitFinal";

const defaultData = {
  firstName: "",
  lastName: "",
  nickName: "",
  genero: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  phone: "",
  email: "",
};

const steps = [
  { id: "datosbasicos" },
  { id: "datos_personales" },
  { id: "entorno_familiar" },
  { id: "salud_familiar" },
  { id: "morbilidad_menores" },
  { id: "morbilidad_mayores" },
  { id: "morbilidad_cronica" },
  { id: "mortalidad" },
  { id: "salud_mental" },
  { id: "asesoria_juridica" },
  { id: "asesoria_psicologica" },
  { id: "asesoria_empleabilidad" },
  // { id: "review" },
  { id: "seleccionar_asignacion" },
  { id: "asignar_asesoria" },

  { id: "asignacion" },
  { id: "submit" },
  { id: "submit_final" },
];

export const MultiStepForm = () => {
  const [formData, setForm] = useForm(defaultData);
  const { step, navigation } = useStep({
    steps,
    initialStep: 0,
  });

  const props = { formData, setForm, navigation };

  switch (step.id) {
    case "datosbasicos":
      return <DatosBasicos {...props} />;
    case "datos_personales":
      return <DatosPersonales {...props} />;
    case "entorno_familiar":
      return <EntornoFamiliar {...props} />;
    case "salud_familiar":
      return <SaludFamiliar {...props} />;
    case "morbilidad_menores":
      return <MorbilidadMenores {...props} />;
    case "morbilidad_mayores":
      return <MorbilidadMayores {...props} />;
    case "morbilidad_cronica":
      return <MorbilidadCronica {...props} />;
    case "mortalidad":
      return <Mortalidad {...props} />;
    case "salud_mental":
      return <SaludMental {...props} />;
    case "asesoria_juridica":
      return <Juridica {...props} />;
    case "asesoria_psicologica":
      return <Psicologica {...props} />;
    case "asesoria_empleabilidad":
      return <Empleabilidad {...props} />;
    case "seleccionar_asignacion":
      return <SeleccionarAsignacion {...props} />;
    case "asignar_asesoria":
      return <AsignarAsesoria {...props} />;
    case "asignacion":
      return <Asignacion {...props} />;
    case "submit":
      return <Submit {...props} />;
    case "submit_final":
      return <SubmitFinal {...props} />;
  }

  return (
    <div>
      <h1>Pagina no encontrada</h1>
    </div>
  );
};
