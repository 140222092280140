import React, { useState, useContext, useEffect } from "react";
import { ContextFormulario } from "../../contexts/formulario/ContextFormulario";
import {
  Form,
  Input,
  Button,
  Radio,
  Row,
  Col,
  Card,
  Select,
  InputNumber,
  Checkbox,
  Space,
  Divider,
} from "antd";
import "./styles.css";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { ContextAutenticacion } from "../../contexts/autenticacion/ContextAutenticacion";
import { Alert } from "antd";
import instance from "../../axios/axios-api";
import { useHistory } from "react-router-dom";

export const DatosPersonales = ({ navigation }) => {
  const [bandera, setBandera] = useState(false);
  const [cambio, setCambio] = useState(false);
  const [error, setError] = useState(false);
  const [loadingGuardar, setLoadingGuardar] = useState(false);
  const [loadingAtras, setLoadingAtras] = useState(false);
  const [errorConexion, setErrorConexion] = useState(false);
  const history = useHistory();

  const [datosBasicos, setDatosBasicos] = useState({
    condicion_migratoria: "",
    motivos_migracion: [],
    permanencia_anos: "0",
    permanencia_mes: "0",
    estado_civil: undefined,
    sabe_leer: "",
    sabe_escribir: "",
    escolaridad: undefined,
    escolaridad_cual: "",
    homologacion: "",
    discapacidad: "",
    composicion: [],
    hijos_estudiando: undefined,
    hijos_estudiando_porque: "",
    obstaculos: undefined,
    obstaculos_razon: "",
    obstaculos_cual: "",
    ocupacion: undefined,
    ocupacion_cual: "",
    prestaciones_sociales: "",
    cuidado_hijos: undefined,
    horas: "",
    miembros_horas: "",
    ingresos_semanales: "",
    ingresos_hogar: "",
    vivia_origenes: "",
    cuantos_hijos: "",
    cuantas_hijas: "",
    quien_origen: "",
    viven_venezuela: "",
    cuantos_hijos_vnzla: "",
    cuantas_hijas_vnzla: "",
    quien_vnzla: "",
    ciudad_anterior: undefined,
    ciudad_anterior_cual: "",
    razon_cartagena: undefined,
    razon_cartagena_cual: "",
    quedarse_colombia: undefined,
    quedarse_colombia_donde: "",
    acompanado: undefined,
    paradero: "",
    paradero_ciudades_cuales: "",
    paradero_cartagena: "",
    paradero_desconocido: "",
    paradero_otro: "",
    cambios_familia: "",
    cambios_familia_especifique: "",
    condiciones_vida: undefined,
    apoyo_economico: undefined,
    envia_dinero: undefined,
    descripcion_otro: "",
    envia_dinero_especifique: "",
  });

  const contextformulario = useContext(ContextFormulario);
  const {
    usuarioSeleccionado,
    usuarioSeleccionadoback,
    usuario_seleccionado_back,
    loading,
    actualizarDatosPersonales,
    usuario_seleccionado,
    usuario_seleccionado_datos_personales,
    formulario_basico,
  } = contextformulario;
  const contextAutenticacion = useContext(ContextAutenticacion);
  const { usuario } = contextAutenticacion;

  const [data_formulario, setDataFormulario] = useState("");

  const {
    condicion_migratoria,
    motivos_migracion,
    permanencia_anos,
    permanencia_mes,
    estado_civil,
    sabe_leer,
    sabe_escribir,
    escolaridad,
    escolaridad_cual,
    homologacion,
    discapacidad,
    composicion,
    hijos_estudiando,
    hijos_estudiando_porque,
    obstaculos,
    obstaculos_razon,
    obstaculos_cual,
    ocupacion,
    ocupacion_cual,
    prestaciones_sociales,
    cuidado_hijos,
    horas,
    miembros_horas,
    ingresos_semanales,
    ingresos_hogar,
    vivia_origenes,
    cuantos_hijos,
    cuantas_hijas,
    quien_origen,
    viven_venezuela,
    cuantos_hijos_vnzla,
    cuantas_hijas_vnzla,
    quien_vnzla,
    ciudad_anterior,
    ciudad_anterior_cual,
    razon_cartagena,
    razon_cartagena_cual,
    quedarse_colombia,
    quedarse_colombia_donde,
    acompanado,
    paradero,
    paradero_ciudades_cuales,
    paradero_cartagena,
    paradero_desconocido,
    paradero_otro,
    cambios_familia,
    cambios_familia_especifique,
    condiciones_vida,
    apoyo_economico,
    envia_dinero,
    envia_dinero_especifique,
  } = datosBasicos;

  useEffect(() => {
    if (
      usuario_seleccionado !== null &&
      usuario_seleccionado_datos_personales !== null
    ) {
      setDatosBasicos({
        condicion_migratoria: usuario_seleccionado.condicion_migratoria,
        motivos_migracion:
          usuario_seleccionado_datos_personales.motivos_migracion,
        permanencia_anos: usuario_seleccionado.permanencia_anos,
        permanencia_mes: usuario_seleccionado.permanencia_mes,
        estado_civil: usuario_seleccionado.estado_civil,
        sabe_leer: usuario_seleccionado.sabe_leer,
        sabe_escribir: usuario_seleccionado.sabe_escribir,
        escolaridad: usuario_seleccionado.escolaridad,
        escolaridad_cual: usuario_seleccionado.escolaridad_cual,
        homologacion: usuario_seleccionado.homologacion,
        discapacidad: usuario_seleccionado.discapacidad,
        composicion: usuario_seleccionado_datos_personales.composicion,
        hijos_estudiando: usuario_seleccionado.hijos_estudiando,
        hijos_estudiando_porque: usuario_seleccionado.hijos_estudiando_porque,
        obstaculos: usuario_seleccionado.obstaculos,
        obstaculos_razon: usuario_seleccionado.obstaculos_razon,
        obstaculos_cual: usuario_seleccionado.obstaculos_cual,
        ocupacion: usuario_seleccionado.ocupacion,
        ocupacion_cual: usuario_seleccionado.ocupacion_cual,
        prestaciones_sociales: usuario_seleccionado.prestaciones_sociales,
        cuidado_hijos: usuario_seleccionado.cuidado_hijos,
        horas: usuario_seleccionado.horas,
        miembros_horas: usuario_seleccionado.miembros_horas,
        ingresos_semanales: usuario_seleccionado.ingresos_semanales,
        ingresos_hogar: usuario_seleccionado.ingresos_hogar,
        vivia_origenes: usuario_seleccionado_datos_personales.vivia_origenes,
        cuantos_hijos: usuario_seleccionado.cuantos_hijos,
        cuantas_hijas: usuario_seleccionado.cuantas_hijas,
        quien_origen: usuario_seleccionado.quien_origen,
        viven_venezuela: usuario_seleccionado_datos_personales.viven_venezuela,
        cuantos_hijos_vnzla: usuario_seleccionado.cuantos_hijos_vnzla,
        cuantas_hijas_vnzla: usuario_seleccionado.cuantas_hijas_vnzla,
        quien_vnzla: usuario_seleccionado.quien_vnzla,
        ciudad_anterior: usuario_seleccionado.ciudad_anterior,
        ciudad_anterior_cual: usuario_seleccionado.ciudad_anterior_cual,
        razon_cartagena: usuario_seleccionado.razon_cartagena,
        razon_cartagena_cual: usuario_seleccionado.razon_cartagena_cual,
        quedarse_colombia: usuario_seleccionado.quedarse_colombia,
        quedarse_colombia_donde: usuario_seleccionado.quedarse_colombia_donde,
        acompanado: usuario_seleccionado_datos_personales.acompanado,
        paradero: usuario_seleccionado_datos_personales.paradero,
        paradero_ciudades_cuales: usuario_seleccionado.paradero_ciudades_cuales,
        paradero_cartagena: usuario_seleccionado.paradero_cartagena,
        paradero_desconocido: usuario_seleccionado.paradero_desconocido,
        paradero_otro: usuario_seleccionado.paradero_otro,
        cambios_familia: usuario_seleccionado_datos_personales.cambios_familia,
        cambios_familia_especifique:
          usuario_seleccionado.cambios_familia_especifique,
        condiciones_vida: usuario_seleccionado.condiciones_vida,
        apoyo_economico: usuario_seleccionado.apoyo_economico,
        envia_dinero: usuario_seleccionado.envia_dinero,
        envia_dinero_especifique: usuario_seleccionado.envia_dinero_especifique,
      });

      setCambio(false);
    }
  }, [usuario_seleccionado]);

  useEffect(() => {
    if (formulario_basico.length > 0) {
      setDataFormulario(formulario_basico[0]);
    }
  }, [formulario_basico]);

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };

  const checkStyle = {
    display: "block",
    marginRight: "0",
  };

  const checkStyle1 = {
    display: "block",
    marginRight: "0",
    marginBottom: "1rem",
    paddingTop: "0.5rem",
  };

  const onFinish = (values) => {
    if (usuario_seleccionado !== null) {
      values["id"] = usuario_seleccionado.id;
      //console.log("Success:", values);
      if (cambio === true) {
        // actualizarDatosPersonales(values);
        // navigation.next();
        setLoadingGuardar(true);
        instance
          .put("/api/caracterizaciones/update_names", values)
          .then((res) => {
            if (res.status === 200) {
              setLoadingGuardar(false);
              navigation.next();
              //console.log(res);
            } else {
              setLoadingGuardar(false);
              setErrorConexion(true);
            }
          })
          .catch(function (error) {
            setLoadingGuardar(false);
            setErrorConexion(true);
          });
      } else {
        navigation.next();
      }
    } else {
      values["id"] = data_formulario;
      //values["id"] = localStorage.getItem("id");
      //console.log(data_formulario);
      //console.log(values);
      // actualizarDatosPersonales(values);
      // navigation.next();
      setLoadingGuardar(true);
      instance
        .put("/api/caracterizaciones/update_names", values)
        .then((res) => {
          if (res.status === 200) {
            setLoadingGuardar(false);
            navigation.next();
            //console.log(res);
          } else {
            setLoadingGuardar(false);
            setErrorConexion(true);
          }
        })
        .catch(function (error) {
          setLoadingGuardar(false);
          setErrorConexion(true);
        });
    }
  };

  const onFinishFailed = (errorInfo) => {
    setError(true);
  };

  const goBack = async () => {
    if (usuario_seleccionado !== null) {
      let id = usuario_seleccionado.id;
      setLoadingAtras(true);
      usuarioSeleccionado(id);
      setTimeout(() => {
        navigation.previous();
        setLoadingAtras(false);
      }, 3000);
    } else {
      //console.log("Va de corrido");
      let id = data_formulario;
      setLoadingAtras(true);
      usuarioSeleccionado(id);
      setTimeout(() => {
        navigation.previous();
        setLoadingAtras(false);
      }, 3000);
    }
  };

  const onChange = (e) => {
    setDatosBasicos({
      ...datosBasicos,
      [e[0].name]: e[0].value,
    });

    setBandera(true);
    if (bandera) {
      setCambio(true);
    } else {
      setCambio(false);
    }
  };

  //console.log(motivos_migracion);

  return (
    <>
      <Form
        layout="vertical"
        name="basic"
        initialValues={{
          condicion_migratoria: usuario_seleccionado
            ? usuario_seleccionado.condicion_migratoria
            : "",
          motivos_migracion: usuario_seleccionado_datos_personales
            ? usuario_seleccionado_datos_personales.motivos_migracion
            : "",
          permanencia_anos: usuario_seleccionado
            ? usuario_seleccionado.permanencia_anos
            : 0,
          permanencia_mes: usuario_seleccionado
            ? usuario_seleccionado.permanencia_mes
            : 0,
          estado_civil: usuario_seleccionado
            ? usuario_seleccionado.estado_civil
            : undefined,
          sabe_leer: usuario_seleccionado ? usuario_seleccionado.sabe_leer : "",
          sabe_escribir: usuario_seleccionado
            ? usuario_seleccionado.sabe_escribir
            : "",
          escolaridad: usuario_seleccionado
            ? usuario_seleccionado.escolaridad
            : undefined,
          escolaridad_cual: usuario_seleccionado
            ? usuario_seleccionado.escolaridad_cual
            : "",
          homologacion: usuario_seleccionado
            ? usuario_seleccionado.homologacion
            : "",
          discapacidad: usuario_seleccionado
            ? usuario_seleccionado.discapacidad
            : "",
          composicion: usuario_seleccionado_datos_personales
            ? usuario_seleccionado_datos_personales.composicion
            : "",
          hijos_estudiando: usuario_seleccionado
            ? usuario_seleccionado.hijos_estudiando
            : "",
          hijos_estudiando_porque: usuario_seleccionado
            ? usuario_seleccionado.hijos_estudiando_porque
            : "",
          obstaculos: usuario_seleccionado
            ? usuario_seleccionado.obstaculos
            : "",
          obstaculos_razon: usuario_seleccionado
            ? usuario_seleccionado.obstaculos_razon
            : "",
          obstaculos_cual: usuario_seleccionado
            ? usuario_seleccionado.obstaculos_cual
            : "",
          ocupacion: usuario_seleccionado ? usuario_seleccionado.ocupacion : "",
          ocupacion_cual: usuario_seleccionado
            ? usuario_seleccionado.ocupacion_cual
            : "",
          prestaciones_sociales: usuario_seleccionado
            ? usuario_seleccionado.prestaciones_sociales
            : "",
          cuidado_hijos: usuario_seleccionado
            ? usuario_seleccionado.cuidado_hijos
            : "",
          horas: usuario_seleccionado ? usuario_seleccionado.horas : "",
          miembros_horas: usuario_seleccionado
            ? usuario_seleccionado.miembros_horas
            : "",
          ingresos_semanales: usuario_seleccionado
            ? usuario_seleccionado.ingresos_semanales
            : "",
          ingresos_hogar: usuario_seleccionado
            ? usuario_seleccionado.ingresos_hogar
            : "",
          vivia_origenes: usuario_seleccionado_datos_personales
            ? usuario_seleccionado_datos_personales.vivia_origenes
            : "",
          cuantos_hijos: usuario_seleccionado
            ? usuario_seleccionado.cuantos_hijos
            : "",
          cuantas_hijas: usuario_seleccionado
            ? usuario_seleccionado.cuantas_hijas
            : "",
          quien_origen: usuario_seleccionado
            ? usuario_seleccionado.quien_origen
            : "",
          viven_venezuela: usuario_seleccionado_datos_personales
            ? usuario_seleccionado_datos_personales.viven_venezuela
            : "",
          cuantos_hijos_vnzla: usuario_seleccionado
            ? usuario_seleccionado.cuantos_hijos_vnzla
            : "",
          cuantas_hijas_vnzla: usuario_seleccionado
            ? usuario_seleccionado.cuantas_hijas_vnzla
            : "",
          quien_vnzla: usuario_seleccionado
            ? usuario_seleccionado.quien_vnzla
            : "",
          ciudad_anterior: usuario_seleccionado
            ? usuario_seleccionado.ciudad_anterior
            : "",
          ciudad_anterior_cual: usuario_seleccionado
            ? usuario_seleccionado.ciudad_anterior_cual
            : "",
          razon_cartagena: usuario_seleccionado
            ? usuario_seleccionado.razon_cartagena
            : "",
          razon_cartagena_cual: usuario_seleccionado
            ? usuario_seleccionado.razon_cartagena_cual
            : "",
          quedarse_colombia: usuario_seleccionado
            ? usuario_seleccionado.quedarse_colombia
            : "",
          quedarse_colombia_donde: usuario_seleccionado
            ? usuario_seleccionado.quedarse_colombia_donde
            : "",
          acompanado: usuario_seleccionado_datos_personales
            ? usuario_seleccionado_datos_personales.acompanado
            : "",
          paradero: usuario_seleccionado_datos_personales
            ? usuario_seleccionado_datos_personales.paradero
            : "",
          paradero_ciudades_cuales: usuario_seleccionado
            ? usuario_seleccionado.paradero_ciudades_cuales
            : "",
          paradero_cartagena: usuario_seleccionado
            ? usuario_seleccionado.paradero_cartagena
            : "",
          paradero_desconocido: usuario_seleccionado
            ? usuario_seleccionado.paradero_desconocido
            : "",
          paradero_otro: usuario_seleccionado
            ? usuario_seleccionado.paradero_otro
            : "",
          cambios_familia: usuario_seleccionado_datos_personales
            ? usuario_seleccionado_datos_personales.cambios_familia
            : "",
          cambios_familia_especifique: usuario_seleccionado
            ? usuario_seleccionado.cambios_familia_especifique
            : "",
          condiciones_vida: usuario_seleccionado
            ? usuario_seleccionado.condiciones_vida
            : "",
          apoyo_economico: usuario_seleccionado
            ? usuario_seleccionado.apoyo_economico
            : "",
          envia_dinero: usuario_seleccionado
            ? usuario_seleccionado.envia_dinero
            : "",
          envia_dinero_especifique: usuario_seleccionado
            ? usuario_seleccionado.envia_dinero_especifique
            : "",
        }}
        onFieldsChange={onChange}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Card className="borde">
          <div className="titulo">
            <p>1. Datos personales del encuestado</p>
          </div>
          <Row gutter={[40, 16]} justify="left">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="13. Tipo de condición migratoria:"
                name="condicion_migratoria"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={condicion_migratoria}
              >
                <Radio.Group>
                  <Radio style={radioStyle} value="1">
                    Migrante regular
                  </Radio>
                  <Radio style={radioStyle} value="2">
                    Migrante irregular
                  </Radio>
                  <Radio style={radioStyle} value="3">
                    Colombiano retornado
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="14. ¿Cuales fueron las causas que motivaron la migración? (puede marcar varios)"
                name="motivos_migracion"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={motivos_migracion}
              >
                <Checkbox.Group>
                  <Checkbox style={checkStyle} value="1">
                    Escasez de alimentos
                  </Checkbox>
                  <Checkbox style={checkStyle} value="2">
                    Ausencia de servicios médicos y medicinas
                  </Checkbox>
                  <Checkbox style={checkStyle} value="3">
                    Necesidad de buscar mayores ingresos{" "}
                  </Checkbox>
                  <Checkbox style={checkStyle} value="4">
                    Inseguridad en el país
                  </Checkbox>
                  <Checkbox style={checkStyle} value="5">
                    Amenazas directas a su vida o familiares
                  </Checkbox>
                  <Checkbox style={checkStyle} value="6">
                    Alto costo de bienes y servicios{" "}
                  </Checkbox>
                  <Checkbox style={checkStyle} value="7">
                    Dificultades de acceso a la educación
                  </Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={[40, 16]} justify="left">
            <Col lg={12} md={12} xs={24}>
              <p style={{ fontWeight: "500" }}>
                <span style={{ color: "#ff4d4f" }}>* </span>15. Tiempo de
                permanencia en Colombia:
              </p>
              <Form.Item
                label="Años:"
                name="permanencia_anos"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={permanencia_anos}
              >
                <InputNumber placeholder="Años" style={{ width: "100%" }} />
              </Form.Item>

              <Form.Item
                label="Meses:"
                style={{ paddingTop: "0.5rem" }}
                name="permanencia_mes"
                rules={[
                  {
                    required: false,
                    message: "Este campo es requerido",
                  },
                ]}
                value={permanencia_mes}
              >
                <InputNumber placeholder="Meses" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="16. Estado civil"
                name="estado_civil"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={estado_civil}
              >
                <Select placeholder="Seleccione estado civil">
                  <Select.Option value="1">Soltero(a)</Select.Option>
                  <Select.Option value="2">Casado(a)</Select.Option>
                  <Select.Option value="3">Unión libre</Select.Option>
                  <Select.Option value="4">Otro</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Divider />

          <Row gutter={[40, 16]} justify="left">
            <Col lg={12} md={12} xs={24}>
              <p style={{ fontWeight: "500" }}>
                <span style={{ color: "#ff4d4f" }}>* </span>17. Nivel mas alto
                de escolaridad
              </p>

              <Form.Item
                label="Sabe leer:"
                name="sabe_leer"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={sabe_leer}
              >
                <Radio.Group>
                  <Radio value="1">Si</Radio>
                  <Radio value="2">No</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="Sabe escribir:"
                name="sabe_escribir"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={sabe_escribir}
              >
                <Radio.Group>
                  <Radio value="1">Si</Radio>
                  <Radio value="2">No</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                label="Seleccione escolaridad:"
                name="escolaridad"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={escolaridad}
              >
                <Select placeholder="Seleccione escolaridad">
                  <Select.Option value="1">Primaria Completa</Select.Option>
                  <Select.Option value="2">Primaria incompleta</Select.Option>
                  <Select.Option value="3">Secundaria completa</Select.Option>
                  <Select.Option value="4">Secundaria incompleta</Select.Option>
                  <Select.Option value="5">técnica o tecnológica</Select.Option>
                  <Select.Option value="6">Universitaria</Select.Option>
                  <Select.Option value="7">Postgrado</Select.Option>
                  <Select.Option value="8">Oficio</Select.Option>
                </Select>
              </Form.Item>

              {escolaridad === "5" ||
              escolaridad === "6" ||
              escolaridad === "7" ||
              escolaridad === "8" ? (
                <Form.Item
                  style={{ paddingTop: "1rem" }}
                  label="Digite la descripción"
                  name="escolaridad_cual"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                  value={escolaridad_cual}
                >
                  <Input />
                </Form.Item>
              ) : null}
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="18. ¿Ha necesitado homologar sus títulos académicos?"
                name="homologacion"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={homologacion}
              >
                <Radio.Group>
                  <Radio style={radioStyle} value="1">
                    Si
                  </Radio>
                  <Radio style={radioStyle} value="2">
                    No
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={[40, 16]} justify="left">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="19. Tiene alguna discapacidad:"
                name="discapacidad"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={discapacidad}
              >
                <Radio.Group>
                  <Radio style={radioStyle} value="1">
                    Motora
                  </Radio>
                  <Radio style={radioStyle} value="2">
                    Auditiva
                  </Radio>
                  <Radio style={radioStyle} value="3">
                    Visual
                  </Radio>
                  <Radio style={radioStyle} value="4">
                    Del habla
                  </Radio>
                  <Radio style={radioStyle} value="5">
                    Mental
                  </Radio>
                  <Radio style={radioStyle} value="6">
                    Otra
                  </Radio>
                  <Radio style={radioStyle} value="7">
                    Ninguna
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col lg={12} md={12} xs={24}>
              <p style={{ fontWeight: "500" }}>
                <span style={{ color: "#ff4d4f" }}>* </span>20. composición
                familiar, en Colombia
              </p>

              <Form.List name="composicion">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field) => (
                      <Space
                        key={field.key}
                        style={{ display: "flex", marginBottom: 8 }}
                        align="baseline"
                      >
                        <Form.Item
                          {...field}
                          name={[field.name, "parentezco"]}
                          fieldKey={[field.fieldKey, "parentezco"]}
                          rules={[
                            { required: true, message: "Digite un parentezco" },
                          ]}
                        >
                          <Input placeholder="Parentezco" />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, "edad"]}
                          fieldKey={[field.fieldKey, "edad"]}
                          rules={[
                            { required: true, message: "Digite una edad" },
                          ]}
                        >
                          <Input placeholder="Edad" />
                        </Form.Item>
                        <MinusCircleOutlined
                          onClick={() => remove(field.name)}
                        />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Agregar Parentezco
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
          <Divider />
          <Row gutter={[40, 16]} justify="center">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="21. ¿Sus hijos menores de edad se encuentran estudiando?"
                name="hijos_estudiando"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={hijos_estudiando}
              >
                <Select placeholder="Seleccione">
                  <Select.Option value="1">Si</Select.Option>
                  <Select.Option value="2">No</Select.Option>
                  <Select.Option value="3">No tiene</Select.Option>
                </Select>
              </Form.Item>

              {hijos_estudiando === "2" ? (
                <Form.Item
                  style={{ paddingTop: "1rem" }}
                  label="¿Por qué"
                  name="hijos_estudiando_porque"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                  value={hijos_estudiando_porque}
                >
                  <Input placeholder="Digite el motivo" />
                </Form.Item>
              ) : null}
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="22. ¿Ha presentado obstáculos para matricular a sus hijos?"
                name="obstaculos"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={obstaculos}
              >
                <Select placeholder="Seleccione">
                  <Select.Option value="1">Si</Select.Option>
                  <Select.Option value="2">No</Select.Option>
                  <Select.Option value="3">No tiene hijos</Select.Option>
                </Select>
              </Form.Item>

              {obstaculos === "1" ? (
                <Form.Item
                  label="Razón"
                  name="obstaculos_razon"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                  value={obstaculos_razon}
                >
                  <Select placeholder="Seleccione">
                    <Select.Option value="1">Documentación</Select.Option>
                    <Select.Option value="2">Edad del menor</Select.Option>
                    <Select.Option value="3">
                      Validación de los cursos aprobados
                    </Select.Option>
                    <Select.Option value="4">Otro</Select.Option>
                  </Select>
                </Form.Item>
              ) : null}

              {obstaculos_razon === "4" ? (
                <Form.Item
                  label="Razón"
                  name="obstaculos_cual"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                  value={obstaculos_cual}
                >
                  <Input />
                </Form.Item>
              ) : null}
            </Col>
          </Row>
          <Divider />
          <Row gutter={[40, 16]} justify="center">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="23. Ocupación"
                name="ocupacion"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={ocupacion}
              >
                <Select placeholder="Seleccione">
                  <Select.Option value="1">Empleado</Select.Option>
                  <Select.Option value="2">Ama de Casa</Select.Option>
                  <Select.Option value="3">Desempleado</Select.Option>
                  <Select.Option value="4">Estudiante</Select.Option>
                  <Select.Option value="5">
                    Trabajador independiente
                  </Select.Option>
                  <Select.Option value="6">Informal</Select.Option>
                  <Select.Option value="7">Negocio propio</Select.Option>
                </Select>
              </Form.Item>

              {ocupacion === "1" || ocupacion === "5" ? (
                <Form.Item
                  style={{ paddingTop: "1rem" }}
                  label="Actividad"
                  name="ocupacion_cual"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                  value={ocupacion_cual}
                >
                  <Input placeholder="Digite actividad" />
                </Form.Item>
              ) : null}
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="24. ¿Recibe prestaciones sociales? (solo si se responde en la pregunta anterior las opciones a, e)."
                name="prestaciones_sociales"
                rules={[
                  {
                    required: false,
                    message: "Este campo es requerido",
                  },
                ]}
                value={prestaciones_sociales}
              >
                <Radio.Group>
                  <Radio style={radioStyle} value="1">
                    Si
                  </Radio>
                  <Radio style={radioStyle} value="2">
                    No
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={[40, 16]} justify="center">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="25. ¿Quién le cuida a sus hijos (as) mientras usted labora?"
                name="cuidado_hijos"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={cuidado_hijos}
              >
                <Select placeholder="Seleccione">
                  <Select.Option value="1">Su pareja</Select.Option>
                  <Select.Option value="2">Algun familiar</Select.Option>
                  <Select.Option value="3">Vecina(o)</Select.Option>
                  <Select.Option value="4">Nadie, los deja solos</Select.Option>
                  <Select.Option value="5">
                    Se los lleva al lugar de trabajo
                  </Select.Option>
                  <Select.Option value="6">No aplica</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="26. ¿Cuantas horas trabaja a diario?"
                name="horas"
                rules={[
                  {
                    required: false,
                    message: "Este campo es requerido",
                  },
                ]}
                value={horas}
              >
                <InputNumber
                  placeholder="Digite las horas de trabajo"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={[40, 16]} justify="center">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="27. ¿De los miembros de su hogar, cuantas trabajan?"
                name="miembros_horas"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={miembros_horas}
              >
                <InputNumber
                  placeholder="Digite las horas de trabajo"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="28. Cuantos son sus ingresos semanales"
                name="ingresos_semanales"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={ingresos_semanales}
              >
                <InputNumber
                  placeholder="Digite sus ingresos"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={[40, 16]} justify="center">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="29. ¿Cuantos son los ingresos semanales del hogar"
                name="ingresos_hogar"
                rules={[
                  {
                    required: false,
                    message: "Este campo es requerido",
                  },
                ]}
                value={ingresos_hogar}
              >
                <InputNumber
                  placeholder="Digite ingresos semnales del hogar"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col lg={12} md={12} xs={24}>
              <p style={{ fontWeight: "500" }}>
                <span style={{ color: "#ff4d4f" }}>* </span>30. ¿Con quién vivía
                en su país de origen?:
              </p>
              <Row>
                <Col lg={12} md={12} xs={12}>
                  <Form.Item
                    name="vivia_origenes"
                    rules={[
                      {
                        required: true,
                        message: "Este campo es requerido",
                      },
                    ]}
                    value={vivia_origenes}
                  >
                    <Checkbox.Group>
                      <Checkbox style={checkStyle1} value={1}>
                        Pareja
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={2}>
                        Hijos
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={3}>
                        Hijas
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={4}>
                        Madre
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={5}>
                        Padre
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={6}>
                        Tíos(as)
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={7}>
                        Abuelo
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={8}>
                        Abuela
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={9}>
                        Hermanos(as)
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={10}>
                        Otro
                      </Checkbox>
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
                <Col lg={6} md={6} xs={12}>
                  {vivia_origenes !== null && vivia_origenes.includes(2) ? (
                    <Form.Item
                      style={{ marginTop: "3rem" }}
                      name="cuantos_hijos"
                      rules={[
                        {
                          required: true,
                          message: "Este campo es requerido",
                        },
                      ]}
                      value={cuantos_hijos}
                    >
                      <InputNumber
                        placeholder="¿Cuantos?"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  ) : null}

                  {vivia_origenes !== null && vivia_origenes.includes(3) ? (
                    <Form.Item
                      style={{ position: "absolute", top: "6rem" }}
                      name="cuantas_hijas"
                      rules={[
                        {
                          required: true,
                          message: "Este campo es requerido",
                        },
                      ]}
                      value={cuantas_hijas}
                    >
                      <InputNumber
                        placeholder="¿Cuantas?"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  ) : null}

                  {vivia_origenes !== null && vivia_origenes.includes(10) ? (
                    <Form.Item
                      style={{ position: "absolute", top: "23rem" }}
                      name="quien_origen"
                      rules={[
                        {
                          required: true,
                          message: "Este campo es requerido",
                        },
                      ]}
                      value={quien_origen}
                    >
                      <Input placeholder="Quien?" />
                    </Form.Item>
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Row>
          <Divider />
          <Row gutter={[40, 16]} justify="center">
            <Col lg={12} md={12} xs={24}>
              <p style={{ fontWeight: "500" }}>
                <span style={{ color: "#ff4d4f" }}>* </span>31. De las personas
                con quien vivía, ¿Quienes aún estan en venezuela?:
              </p>
              <Row>
                <Col lg={12} md={12} xs={12}>
                  <Form.Item
                    name="viven_venezuela"
                    rules={[
                      {
                        required: true,
                        message: "Este campo es requerido",
                      },
                    ]}
                    value={viven_venezuela}
                  >
                    <Checkbox.Group>
                      <Checkbox style={checkStyle1} value={1}>
                        Pareja
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={2}>
                        Hijos
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={3}>
                        Hijas
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={4}>
                        Madre
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={5}>
                        Padre
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={6}>
                        Tíos(as)
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={7}>
                        Abuelo
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={8}>
                        Abuela
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={9}>
                        Hermanos(as)
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={10}>
                        Otro
                      </Checkbox>
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
                <Col lg={12} md={12} xs={12}>
                  {viven_venezuela !== null && viven_venezuela.includes(2) ? (
                    <Form.Item
                      style={{ marginTop: "3rem" }}
                      name="cuantos_hijos_vnzla"
                      rules={[
                        {
                          required: true,
                          message: "Este campo es requerido",
                        },
                      ]}
                      value={cuantos_hijos_vnzla}
                    >
                      <InputNumber
                        placeholder="¿Cuantos?"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  ) : null}

                  {viven_venezuela !== null && viven_venezuela.includes(3) ? (
                    <Form.Item
                      style={{ position: "absolute", top: "6rem" }}
                      name="cuantas_hijas_vnzla"
                      rules={[
                        {
                          required: true,
                          message: "Este campo es requerido",
                        },
                      ]}
                      value={cuantas_hijas_vnzla}
                    >
                      <InputNumber
                        placeholder="¿Cuantas?"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  ) : null}

                  {viven_venezuela !== null && viven_venezuela.includes(10) ? (
                    <Form.Item
                      style={{ position: "absolute", top: "23rem" }}
                      name="quien_vnzla"
                      rules={[
                        {
                          required: true,
                          message: "Este campo es requerido",
                        },
                      ]}
                      value={quien_vnzla}
                    >
                      <Input placeholder="Quien?" />
                    </Form.Item>
                  ) : null}
                </Col>
              </Row>
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="32. ¿Vivió en otra ciudad antes de venir a Cartagena?"
                name="ciudad_anterior"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={ciudad_anterior}
              >
                <Select placeholder="Seleccione">
                  <Select.Option value="1">Si</Select.Option>
                  <Select.Option value="2">No</Select.Option>
                </Select>
              </Form.Item>

              {ciudad_anterior === "1" ? (
                <Form.Item
                  style={{ paddingTop: "1rem" }}
                  label="Digite la ciudad"
                  name="ciudad_anterior_cual"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                  value={ciudad_anterior_cual}
                >
                  <Input placeholder="Ciudad" />
                </Form.Item>
              ) : null}
            </Col>
          </Row>
          <Divider />
          <Row gutter={[40, 16]} justify="center">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="33. ¿Por qué llegó a Cartagena? "
                name="razon_cartagena"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={razon_cartagena}
              >
                <Select placeholder="Seleccione">
                  <Select.Option value="1">Tiene familiares</Select.Option>
                  <Select.Option value="2">Conocidos</Select.Option>
                  <Select.Option value="3">Oferta laboral</Select.Option>
                  <Select.Option value="4">Falta de ingresos</Select.Option>
                  <Select.Option value="5">
                    Recomendación de alguien
                  </Select.Option>
                  <Select.Option value="6">Otro</Select.Option>
                </Select>
              </Form.Item>

              {razon_cartagena === "6" ? (
                <Form.Item
                  style={{ paddingTop: "1rem" }}
                  label="Razones"
                  name="razon_cartagena_cual"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                  value={razon_cartagena_cual}
                >
                  <Input placeholder="Ciudad" />
                </Form.Item>
              ) : null}
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="34. ¿Planea quedarse en Colombia o está de paso?"
                name="quedarse_colombia"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={quedarse_colombia}
              >
                <Select placeholder="Seleccione">
                  <Select.Option value="1">De paso</Select.Option>
                  <Select.Option value="2">Quedarse</Select.Option>
                </Select>
              </Form.Item>

              {quedarse_colombia === "1" ? (
                <Form.Item
                  style={{ paddingTop: "1rem" }}
                  label="¿A donde planea llegar?"
                  name="quedarse_colombia_donde"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                  value={quedarse_colombia_donde}
                >
                  <Input placeholder="Ciudad" />
                </Form.Item>
              ) : null}
            </Col>
          </Row>
          <Divider />
          <Row gutter={[40, 16]} justify="center">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="35. ¿Cuándo llegó a Colombia, llegó solo(a) o con otras personas (puede marcar varias)"
                name="acompanado"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={acompanado}
              >
                <Checkbox.Group>
                  <Checkbox style={checkStyle} value={1}>
                    Llegó solo
                  </Checkbox>
                  <Checkbox style={checkStyle} value={2}>
                    Amigos
                  </Checkbox>
                  <Checkbox style={checkStyle} value={3}>
                    Familiares
                  </Checkbox>
                  <Checkbox style={checkStyle} value={4}>
                    Desconocidos
                  </Checkbox>
                  <Checkbox style={checkStyle} value={5}>
                    Todos los anteriores
                  </Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </Col>
            <Col lg={12} md={12} xs={24}>
              <p style={{ fontWeight: "500" }}>
                <span style={{ color: "#ff4d4f" }}>* </span>36. ¿Qué pasó con
                esas personas que venían con usted en proceso migratorio?
              </p>
              <Row>
                <Col lg={14} md={12} xs={12}>
                  <Form.Item
                    name="paradero"
                    rules={[
                      {
                        required:
                          acompanado && acompanado.includes(1) ? false : false,
                        message: "Este campo es requerido",
                      },
                    ]}
                    value={paradero}
                  >
                    <Checkbox.Group
                      disabled={
                        acompanado && acompanado.includes(1) ? true : false
                      }
                    >
                      <Checkbox style={checkStyle1} value={1}>
                        Se fueron para otras ciudades
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={2}>
                        Se quedaron en Cartagena
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={3}>
                        Desconoce su paradero
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={4}>
                        Otro
                      </Checkbox>
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
                <Col lg={10} md={12} xs={12}>
                  {paradero !== null && paradero.includes(1) ? (
                    <Form.Item
                      style={{ marginTop: "0rem" }}
                      name="paradero_ciudades_cuales"
                      rules={[
                        {
                          required: true,
                          message: "Este campo es requerido",
                        },
                      ]}
                      value={paradero_ciudades_cuales}
                    >
                      <Input placeholder="¿Cuales?" style={{ width: "100%" }} />
                    </Form.Item>
                  ) : null}

                  {paradero !== null && paradero.includes(2) ? (
                    <Form.Item
                      style={{
                        position: "absolute",
                        top: "3rem",
                        width: "100%",
                      }}
                      name="paradero_cartagena"
                      rules={[
                        {
                          required: true,
                          message: "Este campo es requerido",
                        },
                      ]}
                      value={paradero_cartagena}
                    >
                      <Input placeholder="¿Cuales?" style={{ width: "100%" }} />
                    </Form.Item>
                  ) : null}

                  {paradero !== null && paradero.includes(3) ? (
                    <Form.Item
                      style={{
                        position: "absolute",
                        top: "6rem",
                        width: "100%",
                      }}
                      name="paradero_desconocido"
                      rules={[
                        {
                          required: true,
                          message: "Este campo es requerido",
                        },
                      ]}
                      value={paradero_desconocido}
                    >
                      <Input />
                    </Form.Item>
                  ) : null}

                  {paradero !== null && paradero.includes(4) ? (
                    <Form.Item
                      style={{
                        position: "absolute",
                        top: "9rem",
                        width: "100%",
                      }}
                      name="paradero_otro"
                      rules={[
                        {
                          required: true,
                          message: "Este campo es requerido",
                        },
                      ]}
                      value={paradero_otro}
                    >
                      <Input />
                    </Form.Item>
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Row>
          <Divider />
          <Row gutter={[40, 16]} justify="center">
            <Col lg={12} md={12} xs={24}>
              <p style={{ fontWeight: "500" }}>
                <span style={{ color: "#ff4d4f" }}>* </span>37. A raíz de su
                llegada a Colombia, ¿qué cambios ha tenido su familia? Puede
                marcar varias opciones
              </p>
              <Row>
                <Col lg={12} md={12} xs={12}>
                  <Form.Item
                    name="cambios_familia"
                    rules={[
                      {
                        required: true,
                        message: "Este campo es requerido",
                      },
                    ]}
                    value={cambios_familia}
                  >
                    <Checkbox.Group>
                      <Checkbox style={checkStyle1} value={1}>
                        Están más unidos
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={2}>
                        Se desintegro la familia
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={3}>
                        Hay problemas familiares
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={4}>
                        Ningún cambio
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={5}>
                        Otro
                      </Checkbox>
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
                <Col lg={12} md={12} xs={12}>
                  {cambios_familia !== null && cambios_familia.includes(5) ? (
                    <Form.Item
                      style={{ marginTop: "12rem" }}
                      name="cambios_familia_especifique"
                      rules={[
                        {
                          required: true,
                          message: "Este campo es requerido",
                        },
                      ]}
                      value={cambios_familia_especifique}
                    >
                      <Input
                        placeholder="Especifique"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  ) : null}
                </Col>
              </Row>
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="38. ¿Considera que han mejorado sus condiciones de vida y la de su familia tras su llegada a Colombia?"
                name="condiciones_vida"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={condiciones_vida}
              >
                <Select placeholder="Seleccione condiciones de vida">
                  <Select.Option value="1">Totalmente</Select.Option>
                  <Select.Option value="2">En algunos aspectos</Select.Option>
                  <Select.Option value="3">Regularmente</Select.Option>
                  <Select.Option value="4">Poco</Select.Option>
                  <Select.Option value="5">Nada</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={[40, 16]} justify="center">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="39. De acuerdo con la pregunta anterior, usted apoya económicamente a su familia en Venezuela: "
                name="apoyo_economico"
                rules={[
                  {
                    required: false,
                    message: "Este campo es requerido",
                  },
                ]}
                value={apoyo_economico}
              >
                <Select placeholder="Seleccione">
                  <Select.Option value="1">Si</Select.Option>
                  <Select.Option value="2">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col lg={12} md={12} xs={24}>
              {apoyo_economico === "1" ? (
                <Form.Item
                  label="40. Cada cuanto tiempo le envía dinero a su familia (solo si se responde SI en la pregunta anterior). "
                  name="envia_dinero"
                  rules={[
                    {
                      required: apoyo_economico === "1" ? true : false,
                      message: "Este campo es requerido",
                    },
                  ]}
                  value={envia_dinero}
                >
                  <Select placeholder="Seleccione">
                    <Select.Option value="1">Mensualmente</Select.Option>
                    <Select.Option value="2">Semanalmente</Select.Option>
                    <Select.Option value="3">Diariamente</Select.Option>
                    <Select.Option value="4">Otro</Select.Option>
                  </Select>
                </Form.Item>
              ) : null}

              {envia_dinero === "4" ? (
                <Form.Item
                  style={{ paddingTop: "1rem" }}
                  label="Especifique"
                  name="envia_dinero_especifique"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                  value={envia_dinero_especifique}
                >
                  <Input placeholder="Digite" />
                </Form.Item>
              ) : null}
            </Col>
          </Row>

          <Divider />
          {errorConexion === true ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Alert
                style={{ width: "50%" }}
                message="Los datos no fueron guardados, por favor revisa tu conexión a internet."
                type="error"
                showIcon
              />
            </div>
          ) : null}

          {error === true ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Alert
                style={{ width: "50%" }}
                message="Por favor verificar los datos ingresados."
                type="error"
                showIcon
              />
            </div>
          ) : null}

          {cambio === true && error === false && errorConexion === false ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Alert
                style={{ width: "50%" }}
                message="Se guardaran los cambios efectuados."
                type="info"
                showIcon
              />
            </div>
          ) : null}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "2rem",
            }}
          >
            <Form.Item>
              <Button
                style={{ marginRight: "2rem" }}
                type="primary"
                loading={loadingAtras}
                onClick={() => goBack()}
              >
                Atras
              </Button>
              <Button type="primary" htmlType="submit" loading={loadingGuardar}>
                Siguiente
              </Button>
            </Form.Item>
          </div>
        </Card>
      </Form>
    </>
  );
};
