import React, { useState, useContext, useEffect } from "react";
import { ContextFormulario } from "../../contexts/formulario/ContextFormulario";
import {
  Form,
  Button,
  Row,
  Col,
  Card,
  Select,
  Divider,
  Radio,
  Checkbox,
} from "antd";
import "./styles.css";
import { ContextAutenticacion } from "../../contexts/autenticacion/ContextAutenticacion";
import { useHistory } from "react-router-dom";
import instance from "../../axios/axios-api";
import { Alert } from "antd";

export const AsignarAsesoria = ({ navigation }) => {
  const history = useHistory();
  const [disabled, setDisabled] = useState(false);
  const [loadingGuardar, setLoadingGuardar] = useState(false);
  const [loadingAtras, setLoadingAtras] = useState(false);
  const [errorConexion, setErrorConexion] = useState(false);
  const [error, setError] = useState(false);
  const [cambio, setCambio] = useState(false);
  const [bandera, setBandera] = useState(false);
  const [datosBasicos, setDatosBasicos] = useState({
    tipo_asignacion: undefined,
    asignacion_empleabilidad: undefined,
    asignacion_juridica: undefined,
    asignacion_psicologica: undefined,
  });

  const contextformulario = useContext(ContextFormulario);
  const {
    actualizarTipoAsesoria,
    usuario_seleccionado_asignacion,
    formulario_basico,
    obtenerAsesores,
    asesores,
    asignarAsesor,
    usuarioSeleccionado,
    usuario_seleccionado,
  } = contextformulario;
  const contextAutenticacion = useContext(ContextAutenticacion);
  const { usuario } = contextAutenticacion;
  const [data_formulario, setDataFormulario] = useState("");

  const {
    tipo_asignacion,
    asignacion_empleabilidad,
    asignacion_juridica,
    asignacion_psicologica,
  } = datosBasicos;

  useEffect(() => {
    if (usuario_seleccionado_asignacion !== null) {
      setDatosBasicos({
        tipo_asignacion: usuario_seleccionado_asignacion.tipo_asignacion,
      });
    }
  }, [usuario_seleccionado_asignacion]);

  useEffect(() => {
    if (formulario_basico.length > 0) {
      setDataFormulario(formulario_basico[0]);
      //console.log(data_formulario);
    }
  }, [formulario_basico]);

  // useEffect(() => {
  //   if (usuario.roles[0] === "ROLE_ADMIN") {
  //     setDisabled(true);
  //   }
  // }, [usuario]);

  useEffect(() => {
    obtenerAsesores();
  }, []);

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };

  const checkStyle = {
    display: "block",
    marginRight: "0",
  };

  const checkStyle1 = {
    display: "block",
    marginRight: "0",
    marginBottom: "1rem",
    paddingTop: "0.5rem",
  };

  //console.log(usuario_seleccionado_asignacion);
  //console.log(data_formulario);
  const onFinish = (values) => {
    if (usuario_seleccionado_asignacion !== null) {
      values["id"] = usuario_seleccionado_asignacion.id;
      //console.log(values);
      // actualizarTipoAsesoria(values);
      setLoadingGuardar(true);
      instance
        .put("/api/caracterizaciones/update/tipo", values)
        .then((res) => {
          if (res.status === 200) {
            setLoadingGuardar(false);
            navigation.go(15);
            //console.log(res);
          } else {
            setLoadingGuardar(false);
            setErrorConexion(true);
          }
        })
        .catch(function (error) {
          setLoadingGuardar(false);
          setErrorConexion(true);
        });
    } else if (data_formulario !== "") {
      //console.log(data_formulario);
      values["id"] = data_formulario;
      //console.log(values);
      // actualizarTipoAsesoria(values);
      setLoadingGuardar(true);
      instance
        .put("/api/caracterizaciones/update/tipo", values)
        .then((res) => {
          if (res.status === 200) {
            setLoadingGuardar(false);
            navigation.go(15);
            //console.log(res);
          } else {
            setLoadingGuardar(false);
            setErrorConexion(true);
          }
        })
        .catch(function (error) {
          setLoadingGuardar(false);
          setErrorConexion(true);
        });
    }
  };

  const onFinishFailed = (errorInfo) => {
    //console.log("Failed:", errorInfo);
    setError(true);
  };

  const goBack = async () => {
    if (usuario_seleccionado !== null) {
      let id = usuario_seleccionado.id;
      setLoadingAtras(true);
      usuarioSeleccionado(id);
      setTimeout(() => {
        navigation.previous();
        setLoadingAtras(false);
      }, 3000);
    } else {
      //console.log("Va de corrido");
      let id = data_formulario;
      setLoadingAtras(true);
      usuarioSeleccionado(id);
      setTimeout(() => {
        navigation.previous();
        setLoadingAtras(false);
      }, 3000);
    }
  };

  const onChange = (e) => {
    setDatosBasicos({
      ...datosBasicos,
      [e[0].name]: e[0].value,
    });
    setBandera(true);

    if (bandera === true) {
      // console.log(datosBasicos)
      setCambio(true);
    } else {
      setCambio(false);
    }
  };

  return (
    <>
      <Form
        layout="vertical"
        name="basic"
        initialValues={{
          tipo_asignacion: usuario_seleccionado_asignacion
            ? usuario_seleccionado_asignacion.tipo_asignacion
            : "",
        }}
        onFieldsChange={onChange}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Card className="borde">
          <div className="titulo">
            <p>Escogencia de la atención u orientación.</p>
          </div>
          <Divider />
          <Row gutter={[40, 16]} justify="center">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="Tipo de actuación requerida:"
                name="tipo_asignacion"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={tipo_asignacion}
              >
                <Checkbox.Group disabled={disabled}>
                  <Checkbox style={checkStyle} value={1}>
                    {" "}
                    Asesoria Jurídica
                  </Checkbox>
                  <Checkbox style={checkStyle} value={3}>
                    Atención psicológica
                  </Checkbox>
                  <Checkbox style={checkStyle} value={2}>
                    Empleabilidad
                  </Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          {/* {tipo_asignacion && tipo_asignacion.includes(1) ? (
            <Row gutter={[40, 16]} justify="center">
              <Col lg={12} md={12} xs={24}>
                <Form.Item
                  label="Escoger asesor para juridica:"
                  name="asignacion_juridica"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                  value={asignacion_juridica}
                >
                  <Select placeholder="Seleccione">
                    {asesores.map((asesor) => (
                      <Select.Option value={asesor.id} key={asesor.id}>
                        {asesor.username}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          ) : null}

          { tipo_asignacion && tipo_asignacion.includes(3) ? (
            <Row gutter={[40, 16]} justify="center">
              <Col lg={12} md={12} xs={24}>
                <Form.Item
                  label="Escoger asesor para psicologica:"
                  name="asignacion_psicologica"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                  value={asignacion_psicologica}
                >
                  <Select placeholder="Seleccione">
                    {asesores.map((asesor) => (
                      <Select.Option value={asesor.id} key={asesor.id}>
                        {asesor.username}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          ) : null}
          { tipo_asignacion && tipo_asignacion.includes(2) ? (
            <Row gutter={[40, 16]} justify="center">
              <Col lg={12} md={12} xs={24}>
                <Form.Item
                  label="Escoger asesor para empleabilidad:"
                  name="asignacion_empleabilidad"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                  value={asignacion_empleabilidad}
                >
                  <Select placeholder="Seleccione">
                    {asesores.map((asesor) => (
                      <Select.Option value={asesor.id} key={asesor.id}>
                        {asesor.username}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          ) : null} */}
          {errorConexion === true ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Alert
                style={{ width: "50%" }}
                message="Los datos no fueron guardados, por favor revisa tu conexión a internet."
                type="error"
                showIcon
              />
            </div>
          ) : null}

          {error === true ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Alert
                style={{ width: "50%" }}
                message="Por favor verificar los datos ingresados."
                type="error"
                showIcon
              />
            </div>
          ) : null}

          {cambio === true && error === false && errorConexion === false ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Alert
                style={{ width: "50%" }}
                message="Se guardaran los cambios efectuados."
                type="info"
                showIcon
              />
            </div>
          ) : null}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "2rem",
            }}
          >
            <Form.Item>
              <Button
                style={{ marginRight: "2rem" }}
                type="primary"
                loading={loadingAtras}
                onClick={() => goBack()}
              >
                Atras
              </Button>
              <Button type="primary" htmlType="submit" loading={loadingGuardar}>
                Siguiente
              </Button>
            </Form.Item>
          </div>
        </Card>
      </Form>
    </>
  );
};
