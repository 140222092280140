import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Badge, Container } from "reactstrap";
import { Link } from "react-router-dom";
import { ContextFormulario } from "../../contexts/formulario/ContextFormulario";
import { ContextAutenticacion } from "../../contexts/autenticacion/ContextAutenticacion";
import AlertaContext from "../../contexts/alerta/alertaContext";
import { Alert } from "antd";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useHistory } from "react-router-dom";

import { DeleteOutlined, UserAddOutlined } from "@ant-design/icons";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

import { Form, Button, Row, Col, Select, Divider, Tooltip, Spin } from "antd";
import Swal from "sweetalert2";
import "./styles.css";

function rowStyleFormat(row, rowIdx) {
  return { backgroundColor: rowIdx % 2 === 0 ? "white" : "#E1E7F1" };
}

function renderShowsTotal(start, to, total) {
  return (
    <span style={{ color: "gray" }}>
      Mostrando filas {start} de {to}, de {total} &nbsp;&nbsp;
    </span>
  );
}

const options = {
  firstPageText: "Primera",
  prePageText: "Atras",
  nextPageText: "Siguiente",
  lastPageText: "Última",
  nextPageTitle: "Primera Pagina",
  prePageTitle: "Pagina Previa",
  firstPageTitle: "Siguiente Pagina",
  lastPageTitle: "Ultima Pagina",
  showTotal: true,
  paginationShowsTotal: renderShowsTotal,
};

const TableAsesor = () => {
  const history = useHistory();

  const contextformulario = useContext(ContextFormulario);
  const {
    obtenerCaraterizacionesAdmin,
    caraterizaciones,
    usuario_seleccionado,
    seguimiento_seleccionado,
    asignarCaso,
    obtenerAsesores,
    usuarioSeleccionado,
    seguimientoSeleccionado,
    usuarioSeleccionadoDatosPersonales,
    usuarioSeleccionadoEntornoFamiliar,
    usuarioSeleccionadoSaludFamiliar,
    usuarioSeleccionadoMorbilidad,
    usuarioSeleccionadoSaludMental,
    usuarioSeleccionadoJuridica,
    usuarioSeleccionadoAsignacion,
    obtenerCaraterizacionesAsesorEstado,
    cerrarSeguimiento,
    AsesoresAsignados,
  } = contextformulario;

  const alertaContext = useContext(AlertaContext);
  const { alerta, mostrarAlerta } = alertaContext;

  const contextAutenticacion = useContext(ContextAutenticacion);
  const { usuario } = contextAutenticacion;

  useEffect(() => {
    obtenerCaraterizacionesAsesorEstado(
      "asignada",
      "sin_completar",
      usuario.id
    );
  }, []);

  function downloadFile(json) {
    seguimientoSeleccionado(json.id);
  }

  function downloadFile1(json) {
    usuarioSeleccionado(json);
    usuarioSeleccionadoDatosPersonales(json);
    usuarioSeleccionadoEntornoFamiliar(json);
    usuarioSeleccionadoSaludFamiliar(json);
    usuarioSeleccionadoMorbilidad(json);
    usuarioSeleccionadoSaludMental(json);
    usuarioSeleccionadoJuridica(json);
    usuarioSeleccionadoAsignacion(json);
    AsesoresAsignados(json);
  }

  function downloadFile2(json) {
    json["userId"] = usuario.id;
    // console.log(json)
    Swal.fire({
      title: "¿Deseas cerrar este seguimiento?",
      text: "Esta acción no se puede deshacer",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Cerrar",
      cancelButtonText: "No, Cancelar",
    }).then(async (result) => {
      //console.log(result.value)
      if (result.value) {
        try {
          cerrarSeguimiento(json);
          setTimeout(() => {
            obtenerCaraterizacionesAsesorEstado(
              "asignada",
              "sin_completar",
              usuario.id
            );
          }, 500);

          Swal.fire("Seguimiento Cerrado", "", "success");
        } catch (error) {
          Swal.fire("Error al cerrar seguimiento", "", "error");
        }
      }
    });
  }

  useEffect(() => {
    if (usuario_seleccionado !== null) {
      history.push("/formulario-ver");
    }
  }, [usuario_seleccionado]);

  useEffect(() => {
    if (seguimiento_seleccionado !== null) {
      history.push("/seguimiento");
    }
  }, [seguimiento_seleccionado]);

  //console.log(usuario)

  function buttons(row, Index) {
    // console.log(Index)
    return (
      <>
        <i
          onClick={() => downloadFile1(Index.id)}
          className="far fa-eye see"
          title="Visualizar Caracterizacion"
        ></i>
        <i
          onClick={() => downloadFile(Index)}
          className="fas fa-list edit"
          title="Visualizar Seguimiento"
        ></i>
        <i
          onClick={() => downloadFile2(Index)}
          className="far fa-times-circle delete"
          title="Cerrar Seguimiento"
        ></i>
      </>
    );
  }

  const array = [];
  //console.log(caraterizaciones)
  for (var i = 0; i < caraterizaciones.length; i++) {
    var tipo = "";
    if (caraterizaciones[i].asesor_caracterizaciones[0].tipo == "1") {
      tipo = "Asesoria Juridica";
    } else if (caraterizaciones[i].asesor_caracterizaciones[0].tipo == "3") {
      tipo = "Atención Psicosocial";
    } else {
      tipo = "Atención Empleabilidad";
    }
    array.push({
      id: caraterizaciones[i].id,
      num: i + 1,
      nombre:
        caraterizaciones[i].persona.nombre +
        " " +
        caraterizaciones[i].persona.apellido,
      creado: caraterizaciones[i].createdAt,
      estado: "Pendiente",
      tipo_asesoria: tipo,
    });
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            titulo="Gestión de casos asignados"
            breadcrumb={{
              actual: "Gestión de casos asignados",
              linkActual: "#",
              anterior: "Gestión de casos asignados",
              linkAnterior: "#",
            }}
          />
          {caraterizaciones && caraterizaciones.length > 0 ? (
            <>
              <div style={{ paddingTop: "2rem", paddingBottom: "2rem" }}>
                <BootstrapTable
                  searchPlaceholder="Búsqueda..."
                  wrapperClasses="table-responsive"
                  striped
                  pagination
                  options={options}
                  data={array}
                  search={true}
                  multiColumnSearch={true}
                  trStyle={rowStyleFormat}
                >
                  <TableHeaderColumn width="60" dataField="num" isKey>
                    #
                  </TableHeaderColumn>
                  <TableHeaderColumn width="120" dataField="nombre">
                    Nombre
                  </TableHeaderColumn>
                  <TableHeaderColumn width="120" dataField="creado">
                    Fecha
                  </TableHeaderColumn>
                  <TableHeaderColumn width="120" dataField="tipo_asesoria">
                    Tipo
                  </TableHeaderColumn>
                  <TableHeaderColumn width="120" dataField="estado">
                    Estado
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    width="100"
                    dataField="buttons"
                    dataFormat={buttons}
                  >
                    Acciones
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            </>
          ) : (
            <Alert
              message="Información"
              description="No existen casos asignados "
              type="info"
              showIcon
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TableAsesor;
