import React, { useState, useContext, useEffect } from "react";
import { ContextFormulario } from "../../contexts/formulario/ContextFormulario";
import {
  Form,
  Input,
  Button,
  Radio,
  Row,
  Col,
  Card,
  Select,
  InputNumber,
  Checkbox,
  Space,
  Divider,
} from "antd";
import "./styles.css";
import { ContextAutenticacion } from "../../contexts/autenticacion/ContextAutenticacion";
import { useHistory } from "react-router-dom";

export const MorbilidadCronica = ({ navigation }) => {
  const history = useHistory();
  const [flag, setFlag] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [datosBasicos, setDatosBasicos] = useState({
    familiares_enfermedades: "",
    familiares_enfermedades_cuales: "",
  });

  const contextformulario = useContext(ContextFormulario);
  const {
    actualizarMorbilidadCronica,
    usuario_seleccionado,
    formulario_basico,
  } = contextformulario;
  const contextAutenticacion = useContext(ContextAutenticacion);
  const { usuario } = contextAutenticacion;

  const [data_formulario, setDataFormulario] = useState("");

  const { familiares_enfermedades, familiares_enfermedades_cuales } =
    datosBasicos;

  useEffect(() => {
    if (usuario_seleccionado !== null) {
      setDatosBasicos({
        familiares_enfermedades: usuario_seleccionado.familiares_enfermedades,
        familiares_enfermedades_cuales:
          usuario_seleccionado.familiares_enfermedades_cuales,
      });
    }
  }, [usuario_seleccionado]);

  useEffect(() => {
    if (formulario_basico.length > 0) {
      setDataFormulario(formulario_basico[0]);
    }
  }, [formulario_basico]);

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };

  const checkStyle = {
    display: "block",
    marginRight: "0",
  };

  const checkStyle1 = {
    display: "block",
    marginRight: "0",
    marginBottom: "1rem",
    paddingTop: "0.5rem",
  };

  const onFinish = (values) => {
    navigation.next();
  };

  const onFinishFailed = (errorInfo) => {
    //console.log("Failed:", errorInfo);
  };

  const onChange = (e) => {
    setDatosBasicos({
      ...datosBasicos,
      [e[0].name]: e[0].value,
    });
  };

  return (
    <>
      <Form
        layout="vertical"
        name="basic"
        initialValues={{
          familiares_enfermedades: usuario_seleccionado
            ? usuario_seleccionado.familiares_enfermedades
            : "",
          familiares_enfermedades_cuales: usuario_seleccionado
            ? usuario_seleccionado.familiares_enfermedades_cuales
            : "",
        }}
        onFieldsChange={onChange}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Card className="borde">
          <div className="titulo">
            <p>1.5.3 Morbilidad Crónica</p>
          </div>
          <Row gutter={[40, 16]} justify="left">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="70. ¿Usted o algún miembro de su familia cercana sufre de alguna de las siguientes enfermedades? (A excepción de menores de cinco años)"
                name="familiares_enfermedades"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={familiares_enfermedades}
              >
                <Select disabled={disabled} placeholder="Seleccione">
                  <Select.Option value="1">Tensión Alta</Select.Option>
                  <Select.Option value="2">Azúcar en la sangre</Select.Option>
                  <Select.Option value="3">
                    Asma/otra enfermedad pulmonar
                  </Select.Option>
                  <Select.Option value="4">
                    Enfermedad de la piel/alergias graves{" "}
                  </Select.Option>
                  <Select.Option value="5">Cáncer o tumores</Select.Option>
                  <Select.Option value="6">
                    Enfermedad cardiaca o cerebrovascular
                  </Select.Option>
                  <Select.Option value="7">Otra</Select.Option>
                  <Select.Option value="8">Ninguna</Select.Option>
                </Select>
              </Form.Item>
              {familiares_enfermedades === "7" ? (
                <Form.Item
                  style={{ paddingTop: "1rem" }}
                  label="Cual"
                  name="familiares_enfermedades_cuales"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                  value={familiares_enfermedades_cuales}
                >
                  <Input disabled={disabled} placeholder="Digite" />
                </Form.Item>
              ) : null}
            </Col>
            <Col lg={12} md={12} xs={24}></Col>
          </Row>
          <Divider />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Form.Item>
              <Button
                style={{ marginRight: "2rem" }}
                type="primary"
                onClick={() => navigation.previous()}
              >
                Atras
              </Button>
              <Button type="primary" htmlType="submit">
                Siguiente
              </Button>
            </Form.Item>
          </div>
        </Card>
      </Form>
    </>
  );
};
