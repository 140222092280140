/*eslint-disable*/
import React, { useContext, useEffect, useState } from "react";

import MaterialTable from "material-table";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Swal from "sweetalert2";
import AddAlert from "@material-ui/icons/AddAlert";

const useStyles = makeStyles(() => ({
  paper: {
    backgroundColor: "red",
  },
}));

const Table = () => {
  const classes = useStyles();

  const [tc, setTC] = useState(false);
  const [aviso, setAviso] = useState("");

  //Extraer clientes del state inicial

  const [columns, setColumns] = useState([
    { title: "Name", field: "name" },
    {
      title: "Surname",
      field: "surname",
      initialEditValue: "initial edit value",
    },
    { title: "Birth Year", field: "birthYear", type: "numeric" },
    {
      title: "Birth Place",
      field: "birthCity",
      lookup: { 34: "İstanbul", 63: "Şanlıurfa" },
    },
  ]);

  const [data, setData] = useState([
    { name: "Mehmet", surname: "Baran", birthYear: 1987, birthCity: 63 },
    { name: "Zerya Betül", surname: "Baran", birthYear: 2017, birthCity: 34 },
  ]);

  return (
    <>
      <MaterialTable
        icons={{
          Edit: () => <EditOutlinedIcon color="primary" />,
          Delete: () => <DeleteOutlineOutlinedIcon color="secondary" />,
        }}
        title="Listado de Personas"
        columns={columns}
        data={data}
        options={{
          search: true,
          actionsColumnIndex: -1,
          // filtering: true,
          // exportButton: true,
        }}
        localization={{
          pagination: {
            labelDisplayedRows: "{from}-{to} de {count}",
            labelRowsSelect: "Filas",
            labelRowsPerPage: "Filas por pagina:",
            firstAriaLabel: "Primera Pagina",
            firstTooltip: "Primera Pagina",
            previousAriaLabel: "Pagina Anterior",
            previousTooltip: "Pagina Anterior",
            nextAriaLabel: "Siguiente Pagina",
            nextTooltip: "Siguiente Pagina",
            lastAriaLabel: "Ultima Pagina",
            lastTooltip: "Ultima Pagina",
          },
          toolbar: {
            onRowsSelected: "{0} z(s) selected",
            searchPlaceholder: "Busqueda",
          },
          header: {
            actions: "Acciones",
          },
          body: {
            emptyDataSourceMessage: "Sin registros",

            editTooltip: "Editar",
            deleteTooltip: "Eliminar",

            editRow: {
              deleteText: "¿Está usted seguro de eliminar este cliente?",
              saveTooltip: "Guardar",
              cancelTooltip: "Cancelar",
            },
          },
        }}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                var validar_correo =
                  /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(
                    newData.correo
                  );

                // console.log(clientes);

                if (
                  !newData.nombre_completo ||
                  !newData.cedula ||
                  !newData.telefono ||
                  !newData.direccion ||
                  !newData.correo
                ) {
                  setTC(true);
                  setAviso("Todos los campos deben ser diligenciados");
                  reject();
                  return;
                }

                if (validar_correo === false) {
                  setTC(true);
                  setAviso("Digite un correo valido");
                  reject();
                  return;
                }

                for (var i = 0; i <= clientes.length - 1; i++) {
                  if (clientes[i].cedula === parseInt(newData.cedula)) {
                    setTC(true);
                    setAviso("Número de cedula ya existe");
                    reject();
                    return;
                  }
                }

                for (var i = 0; i <= clientes.length - 1; i++) {
                  if (clientes[i].correo === newData.correo) {
                    setTC(true);
                    setAviso("El correo ingresado ya existe");
                    reject();
                    return;
                  }
                }

                resolve();
                setTC(false);

                // agregarCliente(newData);
                const data = [...clientes];
                data.push(newData);
                // console.log(newData);
                agregarCliente(newData);
              }, 1000);
            }),

          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                var validar_correo =
                  /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(
                    newData.correo
                  );

                if (
                  !newData.nombre_completo ||
                  !newData.cedula ||
                  !newData.telefono ||
                  !newData.direccion ||
                  !newData.correo
                ) {
                  setTC(true);
                  setAviso("Todos los campos deben ser diligenciados");
                  reject();
                  return;
                }

                if (validar_correo === false) {
                  setTC(true);
                  setAviso("Digite un correo valido");
                  reject();
                  return;
                }

                if (parseInt(oldData.cedula) !== parseInt(newData.cedula)) {
                  for (var i = 0; i <= clientes.length - 1; i++) {
                    if (clientes[i].cedula === parseInt(newData.cedula)) {
                      setTC(true);
                      setAviso("Número de cedula ya existe");
                      reject();
                      return;
                    }
                  }
                }

                // for(var i= 0; i <= clientes.length - 1; i++){
                //   if(clientes[i].correo === newData.correo){
                //   setTC(true);
                //   setAviso("El correo ingresado ya existe");
                //   reject();
                //   return
                //  }
                // }

                setTC(false);
                const dataUpdate = [...clientes];
                const index = oldData.tableData._id;
                dataUpdate[index] = newData;
                //console.log(dataUpdate.undefined);
                actualizarCliente(dataUpdate.undefined);
                Swal.fire("Cliente Actualizado", "", "success");
                resolve();
              }, 1000);
            }),

          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                const dataDelete = [...clientes];
                const index = oldData.tableData.id;
                const remove = dataDelete[index];
                //dataDelete.splice(index, 1);
                eliminarCliente(remove._id);
                resolve();
              }, 1000);
            }),
        }}
      />
    </>
  );
};

export default Table;
