import React, { useState, useContext, useEffect } from "react";
import { ContextFormulario } from "../../contexts/formulario/ContextFormulario";
import {
  Form,
  Input,
  Button,
  Radio,
  Row,
  Col,
  Card,
  Select,
  InputNumber,
  Checkbox,
  Space,
  Divider,
} from "antd";
import "./styles.css";
import { ContextAutenticacion } from "../../contexts/autenticacion/ContextAutenticacion";
import { useHistory } from "react-router-dom";

export const SaludFamiliar = ({ navigation }) => {
  const history = useHistory();
  const [flag, setFlag] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [datosBasicos, setDatosBasicos] = useState({
    afiliacion: undefined,
    condiciones: "",
    condiciones_cuales: "",
    enfermedad: undefined,
    enfermedad_cual: "",
    enfermedad_quien: undefined,
    enfermedad_otro: "",
    atendio_enfermedad: undefined,
    medidas_aislamiento: undefined,
    medidas_aislamiento_porque: "",
    prueba_covid: undefined,
    resultado: undefined,
    tratado: undefined,
  });

  const contextformulario = useContext(ContextFormulario);
  const {
    actualizarSaludFamiliar,
    usuario_seleccionado,
    formulario_basico,
    usuario_seleccionado_salud_familiar,
  } = contextformulario;
  const contextAutenticacion = useContext(ContextAutenticacion);
  const { usuario } = contextAutenticacion;
  const [data_formulario, setDataFormulario] = useState("");

  const {
    afiliacion,
    condiciones,
    condiciones_cuales,
    enfermedad,
    enfermedad_cual,
    enfermedad_quien,
    enfermedad_otro,
    atendio_enfermedad,
    medidas_aislamiento,
    medidas_aislamiento_porque,
    prueba_covid,
    resultado,
    tratado,
  } = datosBasicos;

  useEffect(() => {
    if (
      usuario_seleccionado !== null &&
      usuario_seleccionado_salud_familiar !== null
    ) {
      setDatosBasicos({
        afiliacion: usuario_seleccionado.afiliacion,
        condiciones: usuario_seleccionado_salud_familiar.condiciones,
        condiciones_cuales: usuario_seleccionado.condiciones_cuales,
        enfermedad: usuario_seleccionado.enfermedad,
        enfermedad_cual: usuario_seleccionado.enfermedad_cual,
        enfermedad_quien: usuario_seleccionado.enfermedad_quien,
        enfermedad_otro: usuario_seleccionado.enfermedad_otro,
        atendio_enfermedad: usuario_seleccionado.atendio_enfermedad,
        medidas_aislamiento: usuario_seleccionado.medidas_aislamiento,
        medidas_aislamiento_porque:
          usuario_seleccionado.medidas_aislamiento_porque,
        prueba_covid: usuario_seleccionado.prueba_covid,
        resultado: usuario_seleccionado.resultado,
        tratado: usuario_seleccionado.tratado,
      });
    }
  }, [usuario_seleccionado]);

  useEffect(() => {
    if (formulario_basico.length > 0) {
      setDataFormulario(formulario_basico[0]);
    }
  }, [formulario_basico]);

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };

  const checkStyle = {
    display: "block",
    marginRight: "0",
  };

  const checkStyle1 = {
    display: "block",
    marginRight: "0",
    marginBottom: "1rem",
    paddingTop: "0.5rem",
  };

  const onFinish = (values) => {
    navigation.next();
  };

  const onFinishFailed = (errorInfo) => {
    //console.log("Failed:", errorInfo);
  };

  const onChange = (e) => {
    setDatosBasicos({
      ...datosBasicos,
      [e[0].name]: e[0].value,
    });
  };

  return (
    <>
      <Form
        layout="vertical"
        name="basic"
        initialValues={{
          afiliacion: usuario_seleccionado
            ? usuario_seleccionado.afiliacion
            : "",
          condiciones: usuario_seleccionado_salud_familiar
            ? usuario_seleccionado_salud_familiar.condiciones
            : "",
          condiciones_cuales: usuario_seleccionado
            ? usuario_seleccionado.condiciones_cuales
            : "",
          enfermedad: usuario_seleccionado
            ? usuario_seleccionado.enfermedad
            : "",
          enfermedad_cual: usuario_seleccionado
            ? usuario_seleccionado.enfermedad_cual
            : "",
          enfermedad_quien: usuario_seleccionado
            ? usuario_seleccionado.enfermedad_quien
            : "",
          enfermedad_otro: usuario_seleccionado
            ? usuario_seleccionado.enfermedad_otro
            : "",
          atendio_enfermedad: usuario_seleccionado
            ? usuario_seleccionado.atendio_enfermedad
            : "",
          medidas_aislamiento: usuario_seleccionado
            ? usuario_seleccionado.medidas_aislamiento
            : "",
          medidas_aislamiento_porque: usuario_seleccionado
            ? usuario_seleccionado.medidas_aislamiento_porque
            : "",
          prueba_covid: usuario_seleccionado
            ? usuario_seleccionado.prueba_covid
            : "",
          resultado: usuario_seleccionado ? usuario_seleccionado.resultado : "",
          tratado: usuario_seleccionado ? usuario_seleccionado.tratado : "",
        }}
        onFieldsChange={onChange}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Card className="borde">
          <div className="titulo">
            <p>1.5 Condiciones de salud familiar</p>
          </div>
          <Row gutter={[40, 16]} justify="left">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="54. Tipo de afiliación en salud:"
                name="afiliacion"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={afiliacion}
              >
                <Select disabled={disabled} placeholder="Seleccione">
                  <Select.Option value="1">Contributivo</Select.Option>
                  <Select.Option value="2">Subsidiado</Select.Option>
                  <Select.Option value="3">Sisbén</Select.Option>
                  <Select.Option value="4">Ninguno</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col lg={12} md={12} xs={24}>
              <p style={{ fontWeight: "500" }}>
                <span style={{ color: "#ff4d4f" }}>* </span>55. Usted o algún
                miembro de su hogar se encuentra en alguna de las siguientes
                condiciones. (puede marcar varias opciones)
              </p>
              <Row>
                <Col lg={14} md={12} xs={12}>
                  <Form.Item
                    name="condiciones"
                    rules={[
                      {
                        required: true,
                        message: "Este campo es requerido",
                      },
                    ]}
                    value={condiciones}
                  >
                    <Checkbox.Group disabled={disabled}>
                      <Checkbox style={checkStyle} value={1}>
                        Madre Gestante
                      </Checkbox>
                      <Checkbox style={checkStyle} value={2}>
                        Madre Lactante
                      </Checkbox>
                      <p style={{ paddingTop: "1rem" }}>
                        Enfermedades Crónicas o Catastrófica
                      </p>
                      <Checkbox style={checkStyle} value={3}>
                        Cáncer
                      </Checkbox>
                      <Checkbox style={checkStyle} value={4}>
                        VIH
                      </Checkbox>
                      <Checkbox style={checkStyle} value={5}>
                        Hipertensión
                      </Checkbox>
                      <Checkbox style={checkStyle} value={6}>
                        Diabetes
                      </Checkbox>
                      <Checkbox style={checkStyle} value={7}>
                        Lupus
                      </Checkbox>
                      <Checkbox style={checkStyle} value={8}>
                        Epoc
                      </Checkbox>
                      <Checkbox style={checkStyle} value={9}>
                        Otras enfermedades
                      </Checkbox>
                      <Checkbox style={checkStyle} value={10}>
                        No aplica
                      </Checkbox>
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
                <Col lg={10} md={12} xs={12}>
                  {condiciones !== null && condiciones.includes(9) ? (
                    <Form.Item
                      style={{ marginTop: "14rem" }}
                      name="condiciones_cuales"
                      rules={[
                        {
                          required: true,
                          message: "Este campo es requerido",
                        },
                      ]}
                      value={condiciones_cuales}
                    >
                      <Input
                        disabled={disabled}
                        placeholder="¿Cuales?"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Row>
          <Divider />
          <Row gutter={[40, 16]} justify="left">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="56. ¿Ha tenido usted o algún miembro de su familia alguna enfermedad o accidente en los últimos 30 días?"
                name="enfermedad"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={enfermedad}
              >
                <Select disabled={disabled} placeholder="Seleccione">
                  <Select.Option value="1">Si</Select.Option>
                  <Select.Option value="2">No</Select.Option>
                </Select>
              </Form.Item>

              {enfermedad === "1" ? (
                <Form.Item
                  style={{ paddingTop: "1rem" }}
                  label="¿Qué le sucedió?"
                  name="enfermedad_cual"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                  value={enfermedad_cual}
                >
                  <Input disabled={disabled} placeholder="Digite" />
                </Form.Item>
              ) : null}
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="57. ¿A quien le ocurrió el hecho?"
                name="enfermedad_quien"
                rules={[
                  {
                    required: enfermedad !== "1" ? false : true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={enfermedad_quien}
              >
                <Select disabled={disabled} placeholder="Seleccione">
                  <Select.Option value="1">Hijos</Select.Option>
                  <Select.Option value="2">Conyugue</Select.Option>
                  <Select.Option value="3">Madre</Select.Option>
                  <Select.Option value="4">Padre</Select.Option>
                  <Select.Option value="5">Tíos</Select.Option>
                  <Select.Option value="6">Abuelos</Select.Option>
                  <Select.Option value="7">Otro</Select.Option>
                </Select>
              </Form.Item>

              {enfermedad_quien === "7" ? (
                <Form.Item
                  style={{ paddingTop: "1rem" }}
                  label="¿A quien?"
                  name="enfermedad_otro"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                  value={enfermedad_otro}
                >
                  <Input disabled={disabled} placeholder="Digite" />
                </Form.Item>
              ) : null}
            </Col>
          </Row>
          <Divider />
          <Row gutter={[40, 16]} justify="left">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="58. ¿Como atendió la enfermedad o accidente?"
                name="atendio_enfermedad"
                rules={[
                  {
                    required: enfermedad !== "1" ? false : true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={atendio_enfermedad}
              >
                <Select disabled={disabled} placeholder="Seleccione">
                  <Select.Option value="1">Acudió al medico</Select.Option>
                  <Select.Option value="2">
                    Compro medicina por su cuenta
                  </Select.Option>
                  <Select.Option value="3">Usó remedios caseros</Select.Option>
                  <Select.Option value="4">
                    No usó ningún medicamento
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="59. ¿Ha cumplido las medidas de aislamiento preventivo obligatorio debido a la pandemia del Covid-19?"
                name="medidas_aislamiento"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={medidas_aislamiento}
              >
                <Select disabled={disabled} placeholder="Seleccione">
                  <Select.Option value="1">Si</Select.Option>
                  <Select.Option value="2">No</Select.Option>
                </Select>
              </Form.Item>

              {enfermedad_quien === "2" ? (
                <Form.Item
                  style={{ paddingTop: "1rem" }}
                  label="¿Por qué?"
                  name="medidas_aislamiento_porque"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                  value={medidas_aislamiento_porque}
                >
                  <Input disabled={disabled} placeholder="Digite" />
                </Form.Item>
              ) : null}
            </Col>
          </Row>
          <Divider />
          <Row gutter={[40, 16]} justify="left">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="60. ¿A usted o algún miembro de su familia, le han realizado la prueba de Covid-19?"
                name="prueba_covid"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={prueba_covid}
              >
                <Select disabled={disabled} placeholder="Seleccione">
                  <Select.Option value="1">Si</Select.Option>
                  <Select.Option value="2">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="61. ¿Cuál fue el resultado?"
                name="resultado"
                rules={[
                  {
                    required: prueba_covid !== "1" ? false : true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={resultado}
              >
                <Select disabled={disabled} placeholder="Seleccione">
                  <Select.Option value="1">Positivo</Select.Option>
                  <Select.Option value="2">Negativo</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={[40, 16]} justify="left">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="62. ¿Fue tratado(a)?"
                name="tratado"
                rules={[
                  {
                    //required: prueba_covid !== "1" ? false : true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={tratado}
              >
                <Select disabled={disabled} placeholder="Seleccione">
                  <Select.Option value="1">Si</Select.Option>
                  <Select.Option value="2">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Form.Item>
              <Button
                style={{ marginRight: "2rem" }}
                type="primary"
                onClick={() => navigation.previous()}
              >
                Atras
              </Button>
              <Button type="primary" htmlType="submit">
                Siguiente
              </Button>
            </Form.Item>
          </div>
        </Card>
      </Form>
    </>
  );
};
