import React, { useState, useContext, useEffect } from "react";
import { ContextFormulario } from "../../contexts/formulario/ContextFormulario";
import {
  Form,
  Input,
  Button,
  Radio,
  Row,
  Col,
  Card,
  Select,
  InputNumber,
  Checkbox,
  Space,
  Divider,
} from "antd";
import "./styles.css";
import { ContextAutenticacion } from "../../contexts/autenticacion/ContextAutenticacion";
import { Alert } from "antd";
import instance from "../../axios/axios-api";

export const MorbilidadMayores = ({ navigation }) => {
  const [bandera, setBandera] = useState(false);
  const [cambio, setCambio] = useState(false);
  const [error, setError] = useState(false);
  const [loadingGuardar, setLoadingGuardar] = useState(false);
  const [loadingAtras, setLoadingAtras] = useState(false);
  const [errorConexion, setErrorConexion] = useState(false);

  const [datosBasicos, setDatosBasicos] = useState({
    accidentes: "",
    accidentes_otro: "",
    busco_ayuda: "",
    busco_ayuda_otro: "",
    razon_ayuda: "",
    razon_ayuda_otro: "",
    familiar_enfermedad: "",
  });

  const contextformulario = useContext(ContextFormulario);
  const {
    actualizarMorbilidadMayor,
    usuario_seleccionado,
    formulario_basico,
    usuarioSeleccionado,
    usuario_seleccionado_morbilidad,
  } = contextformulario;
  const contextAutenticacion = useContext(ContextAutenticacion);
  const { usuario } = contextAutenticacion;
  const [data_formulario, setDataFormulario] = useState("");

  const {
    accidentes,
    accidentes_otro,
    busco_ayuda,
    busco_ayuda_otro,
    razon_ayuda,
    razon_ayuda_otro,
    familiar_enfermedad,
  } = datosBasicos;

  useEffect(() => {
    if (
      usuario_seleccionado !== null &&
      usuario_seleccionado_morbilidad !== null
    ) {
      setDatosBasicos({
        accidentes: usuario_seleccionado.accidentes,
        accidentes_otro: usuario_seleccionado.accidentes_otro,
        busco_ayuda: usuario_seleccionado.busco_ayuda,
        busco_ayuda_otro: usuario_seleccionado.busco_ayuda_otro,
        razon_ayuda: usuario_seleccionado.razon_ayuda,
        razon_ayuda_otro: usuario_seleccionado.razon_ayuda_otro,
        familiar_enfermedad:
          usuario_seleccionado_morbilidad.familiar_enfermedad,
      });

      setCambio(false);
    }
  }, [usuario_seleccionado]);

  useEffect(() => {
    if (formulario_basico.length > 0) {
      setDataFormulario(formulario_basico[0]);
    }
  }, [formulario_basico]);

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };

  const checkStyle = {
    display: "block",
    marginRight: "0",
  };

  const checkStyle1 = {
    display: "block",
    marginRight: "0",
    marginBottom: "1rem",
    paddingTop: "0.5rem",
  };

  const onFinish = (values) => {
    if (usuario_seleccionado !== null) {
      values["id"] = usuario_seleccionado.id;
      //console.log("Success:", values);
      if (cambio === true) {
        //  actualizarMorbilidadMayor(values);
        //   navigation.next();
        setLoadingGuardar(true);
        instance
          .put("/api/caracterizaciones/update_morbilidad_mayores", values)
          .then((res) => {
            if (res.status === 200) {
              setLoadingGuardar(false);
              navigation.next();
              //console.log(res);
            } else {
              setLoadingGuardar(false);
              setErrorConexion(true);
            }
          })
          .catch(function (error) {
            setLoadingGuardar(false);
            setErrorConexion(true);
          });
      } else {
        navigation.next();
      }
    } else {
      values["id"] = data_formulario;
      // actualizarMorbilidadMayor(values);
      // navigation.next();
      setLoadingGuardar(true);
      instance
        .put("/api/caracterizaciones/update_morbilidad_mayores", values)
        .then((res) => {
          if (res.status === 200) {
            setLoadingGuardar(false);
            navigation.next();
            //console.log(res);
          } else {
            setLoadingGuardar(false);
            setErrorConexion(true);
          }
        })
        .catch(function (error) {
          setLoadingGuardar(false);
          setErrorConexion(true);
        });
    }
  };

  const onFinishFailed = (errorInfo) => {
    //console.log("Failed:", errorInfo);
    setError(true);
  };

  const goBack = async () => {
    if (usuario_seleccionado !== null) {
      let id = usuario_seleccionado.id;
      setLoadingAtras(true);
      usuarioSeleccionado(id);
      setTimeout(() => {
        navigation.previous();
        setLoadingAtras(false);
      }, 3000);
    } else {
      //console.log("Va de corrido");
      let id = data_formulario;
      setLoadingAtras(true);
      usuarioSeleccionado(id);
      setTimeout(() => {
        navigation.previous();
        setLoadingAtras(false);
      }, 3000);
    }
  };

  const onChange = (e) => {
    setDatosBasicos({
      ...datosBasicos,
      [e[0].name]: e[0].value,
    });
    setBandera(true);
    if (bandera) {
      setCambio(true);
    } else {
      setCambio(false);
    }
  };

  return (
    <>
      <Form
        layout="vertical"
        name="basic"
        initialValues={{
          accidentes: usuario_seleccionado
            ? usuario_seleccionado.accidentes
            : "",
          accidentes_otro: usuario_seleccionado
            ? usuario_seleccionado.accidentes_otro
            : "",
          busco_ayuda: usuario_seleccionado
            ? usuario_seleccionado.busco_ayuda
            : "",
          busco_ayuda_otro: usuario_seleccionado
            ? usuario_seleccionado.busco_ayuda_otro
            : "",
          razon_ayuda: usuario_seleccionado
            ? usuario_seleccionado.razon_ayuda
            : "",
          razon_ayuda_otro: usuario_seleccionado
            ? usuario_seleccionado.razon_ayuda_otro
            : "",
          familiar_enfermedad: usuario_seleccionado_morbilidad
            ? usuario_seleccionado_morbilidad.familiar_enfermedad
            : "",
        }}
        onFieldsChange={onChange}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Card className="borde">
          <div className="titulo">
            <p>1.5.2 Morbilidad en mayores de 5 años</p>
          </div>
          <Row gutter={[40, 16]} justify="left">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="66. En el último mes, usted o alguien de su hogar (excepto menores de cinco años) ha sufrido de:"
                name="accidentes"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={accidentes}
              >
                <Select placeholder="Seleccione">
                  <Select.Option value="1">
                    Accidente casero, familiar o escolar
                  </Select.Option>
                  <Select.Option value="2">
                    Diarrea o malestar en el estómago
                  </Select.Option>
                  <Select.Option value="3">Alergias</Select.Option>
                  <Select.Option value="4">
                    Gripe, tos, resfriado, pulmonía o bronquitis
                  </Select.Option>
                  <Select.Option value="5">Covid-19</Select.Option>
                  <Select.Option value="6">Otra</Select.Option>
                  <Select.Option value="7">No aplica</Select.Option>
                </Select>
              </Form.Item>
              {accidentes === "6" ? (
                <Form.Item
                  style={{ paddingTop: "1rem" }}
                  label="Cual"
                  name="accidentes_otro"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                  value={accidentes_otro}
                >
                  <Input placeholder="Digite" />
                </Form.Item>
              ) : null}
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="67. ¿Dónde buscó ayuda o tratamiento?"
                name="busco_ayuda"
                rules={[
                  {
                    required: accidentes !== "7" ? true : false,
                    message: "Este campo es requerido",
                  },
                ]}
                value={busco_ayuda}
              >
                <Select
                  disabled={accidentes !== "7" ? false : true}
                  placeholder="Seleccione"
                >
                  <Select.Option value="1">No buscó ayuda</Select.Option>
                  <Select.Option value="2">Hospital público</Select.Option>
                  <Select.Option value="3">Farmacia</Select.Option>
                  <Select.Option value="4">Otro</Select.Option>
                </Select>
              </Form.Item>

              {busco_ayuda === "4" ? (
                <Form.Item
                  style={{ paddingTop: "1rem" }}
                  label="¿Donde?"
                  name="busco_ayuda_otro"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                  value={busco_ayuda_otro}
                >
                  <Input placeholder="Digite" />
                </Form.Item>
              ) : null}
            </Col>
          </Row>
          <Divider />
          <Row gutter={[40, 16]} justify="left">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="68. En caso de no buscar ayuda ¿Cuál fue la razón de no haberlo hecho?"
                name="razon_ayuda"
                rules={[
                  {
                    required: accidentes !== "7" ? false : false,
                    message: "Este campo es requerido",
                  },
                ]}
                value={razon_ayuda}
              >
                <Select
                  disabled={accidentes !== "7" ? false : true}
                  placeholder="Seleccione"
                >
                  <Select.Option value="1">No sabía dónde ir</Select.Option>
                  <Select.Option value="2">Queda lejos</Select.Option>
                  <Select.Option value="3">
                    No tiene seguro medico
                  </Select.Option>
                  <Select.Option value="4">
                    No lo considero necesario
                  </Select.Option>
                  <Select.Option value="5">
                    Pensó que no estaba enfermo
                  </Select.Option>
                  <Select.Option value="6">
                    Prestan un mal servicio
                  </Select.Option>
                  <Select.Option value="7">Otro</Select.Option>
                </Select>
              </Form.Item>

              {razon_ayuda === "7" ? (
                <Form.Item
                  style={{ paddingTop: "1rem" }}
                  label="Razón"
                  name="razon_ayuda_otro"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                  value={razon_ayuda_otro}
                >
                  <Input placeholder="Digite" />
                </Form.Item>
              ) : null}
            </Col>
            <Col lg={12} md={12} xs={24}>
              <p style={{ fontWeight: "500", display: "none" }}>
                <span style={{ color: "#ff4d4f" }}>* </span>69. Usted o algún
                miembro de su familia cercana ha sufrido de
              </p>
              <Row>
                <Col lg={12} md={12} xs={12}>
                  <Form.Item
                    style={{ display: "none" }}
                    name="familiar_enfermedad"
                    rules={[
                      {
                        required: false,
                        message: "Este campo es requerido",
                      },
                    ]}
                    value={familiar_enfermedad}
                  >
                    <Checkbox.Group>
                      <Checkbox style={checkStyle} value={1}>
                        Malaria
                      </Checkbox>
                      <Checkbox style={checkStyle} value={2}>
                        Dengue
                      </Checkbox>
                      <Checkbox style={checkStyle} value={3}>
                        Fiebre amarilla
                      </Checkbox>
                      <Checkbox style={checkStyle} value={4}>
                        Cólera
                      </Checkbox>
                      <Checkbox style={checkStyle} value={5}>
                        Leishmaniasis
                      </Checkbox>
                      <Checkbox style={checkStyle} value={6}>
                        Covid-19
                      </Checkbox>
                      <Checkbox style={checkStyle} value={7}>
                        Ninguna
                      </Checkbox>
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Divider />
          {errorConexion === true ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Alert
                style={{ width: "50%" }}
                message="Los datos no fueron guardados, por favor revisa tu conexión a internet."
                type="error"
                showIcon
              />
            </div>
          ) : null}

          {error === true ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Alert
                style={{ width: "50%" }}
                message="Por favor verificar los datos ingresados."
                type="error"
                showIcon
              />
            </div>
          ) : null}

          {cambio === true && error === false && errorConexion === false ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Alert
                style={{ width: "50%" }}
                message="Se guardaran los cambios efectuados."
                type="info"
                showIcon
              />
            </div>
          ) : null}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "2rem",
            }}
          >
            <Form.Item>
              <Button
                style={{ marginRight: "2rem" }}
                type="primary"
                loading={loadingAtras}
                onClick={() => goBack()}
              >
                Atras
              </Button>
              <Button type="primary" htmlType="submit" loading={loadingGuardar}>
                Siguiente
              </Button>
            </Form.Item>
          </div>
        </Card>
      </Form>
    </>
  );
};
