import React, { useContext } from "react";
import Container from "@material-ui/core/Container";
import { Result, Button } from "antd";
import { ContextFormulario } from "../../contexts/formulario/ContextFormulario";
import { useHistory } from "react-router-dom";
import { Navigation } from "@material-ui/icons";

export const SubmitFinal = ({ navigation }) => {
  const history = useHistory();

  const contextformulario = useContext(ContextFormulario);
  const { borrarUsuarioSeleccionado } = contextformulario;

  const redirigir = () => {
    borrarUsuarioSeleccionado();
    history.push("/asignados");
  };

  const redirigir1 = () => {
    borrarUsuarioSeleccionado();
    navigation.go(0);
  };
  return (
    <Result
      status="success"
      title="Caracterización guardada con éxito"
      subTitle="La caracterizacion se encuentra en estado asignada, debido a que se han seleccionado los asesores"
      extra={[
        <Button type="primary" key="console" onClick={() => redirigir()}>
          Ir a asignadas
        </Button>,
        <Button key="buy" onClick={() => redirigir1()}>
          Nueva Caracterización
        </Button>,
      ]}
    />
  );
};
