import React, { useState, useContext, useEffect } from "react";
import { ContextFormulario } from "../../contexts/formulario/ContextFormulario";
import {
  Form,
  Input,
  Button,
  Radio,
  Row,
  Col,
  Card,
  Select,
  InputNumber,
  Checkbox,
  Space,
  Divider,
  DatePicker,
} from "antd";
import "./styles.css";
import { ContextAutenticacion } from "../../contexts/autenticacion/ContextAutenticacion";
import { Container } from "reactstrap";
import moment from "moment";
import { useHistory } from "react-router-dom";

export const VerEmpleabilidad = () => {
  const history = useHistory();
  const [flag, setFlag] = useState(false);
  const [datosBasicos, setDatosBasicos] = useState({
    fecha_atencion_empleabilidad: "",
    genero_empleabilidad: "",
    edad_empleabilidad: "",
    tipo_empleabilidad: "",
    nombre_empleabilidad: "",
    descripcion_empleabilidad: "",
    responsable_empleabilidad: "",
    tuvo_solucion_empleabilidad: "",
    observaciones_empleabilidad: "",
    estado_empleabilidad: "",
  });

  const contextformulario = useContext(ContextFormulario);
  const {
    agregarSeguimientoEmpleabilidad,
    editarSeguimientoEmpleabilidad,
    seguimiento_juridico,
    seguimiento_editar,
    caraterizaciones,
  } = contextformulario;
  const contextAutenticacion = useContext(ContextAutenticacion);
  const { usuario } = contextAutenticacion;

  const {
    fecha_atencion_empleabilidad,
    genero_empleabilidad,
    edad_empleabilidad,
    tipo_empleabilidad,
    nombre_empleabilidad,
    descripcion_empleabilidad,
    responsable_empleabilidad,
    tuvo_solucion_empleabilidad,
    observaciones_empleabilidad,
    estado_empleabilidad,
  } = datosBasicos;

  useEffect(() => {
    if (seguimiento_editar !== null) {
      setDatosBasicos({
        fecha_atencion_empleabilidad: seguimiento_editar.fecha,
        genero_empleabilidad: seguimiento_editar.genero_fix,
        edad_empleabilidad: seguimiento_editar.edad,
        tipo_empleabilidad: seguimiento_editar.tipo_empleabilidad,
        nombre_empleabilidad: seguimiento_editar.nombre_empleabilidad,
        descripcion_empleabilidad: seguimiento_editar.descripcion,
        responsable_empleabilidad: seguimiento_editar.responsable_empleabilidad,
        tuvo_solucion_empleabilidad:
          seguimiento_editar.tuvo_solucion_empleabilidad === "Si" ? 1 : 2,
        observaciones_empleabilidad:
          seguimiento_editar.observaciones_empleabilidad,
      });
    }
  }, [seguimiento_editar]);

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };

  const checkStyle = {
    display: "block",
    marginRight: "0",
  };

  const checkStyle1 = {
    display: "block",
    marginRight: "0",
    marginBottom: "1rem",
    paddingTop: "0.5rem",
  };

  const onFinish = (values) => {};

  const onFinishFailed = (errorInfo) => {
    //console.log("Failed:", errorInfo);
  };

  const onChange = (e) => {
    setDatosBasicos({
      ...datosBasicos,
      [e[0].name]: e[0].value,
    });
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Form
            layout="vertical"
            name="basic"
            initialValues={{
              fecha_atencion_empleabilidad: seguimiento_editar
                ? moment(seguimiento_editar.fecha, "YYYY-MM-DD")
                : "",
              genero_empleabilidad: seguimiento_editar
                ? seguimiento_editar.genero_fix
                : undefined,
              edad_empleabilidad: seguimiento_editar
                ? seguimiento_editar.edad
                : "",
              tipo_empleabilidad: seguimiento_editar
                ? seguimiento_editar.tipo_empleabilidad
                : "",
              nombre_empleabilidad: seguimiento_editar
                ? seguimiento_editar.nombre_empleabilidad
                : "",
              descripcion_empleabilidad: seguimiento_editar
                ? seguimiento_editar.descripcion
                : "",
              responsable_empleabilidad: seguimiento_editar
                ? seguimiento_editar.responsable_empleabilidad
                : "",
              tuvo_solucion_empleabilidad: seguimiento_editar
                ? seguimiento_editar.tuvo_solucion_empleabilidad === "Si"
                  ? "1"
                  : "2"
                : "",

              observaciones_empleabilidad: seguimiento_editar
                ? seguimiento_editar.observaciones_empleabilidad
                : "",
            }}
            onFieldsChange={onChange}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Card className="borde">
              <div className="titulo">
                <p>Nuevo Seguimiento Empleabilidad </p>
              </div>
              <Row gutter={[40, 16]} justify="left">
                <Col lg={12} md={12} xs={24}>
                  <Form.Item
                    label="1. Fecha de la atención"
                    name="fecha_atencion_empleabilidad"
                    rules={[
                      {
                        required: true,
                        message: "Este campo es requerido",
                      },
                    ]}
                    value={fecha_atencion_empleabilidad}
                  >
                    <DatePicker disabled={true} style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
                <Col lg={12} md={12} xs={24}>
                  <Form.Item
                    label="2. Género"
                    name="genero_empleabilidad"
                    rules={[
                      {
                        required: true,
                        message: "Este campo es requerido",
                      },
                    ]}
                    value={genero_empleabilidad}
                  >
                    <Select disabled={true} placeholder="Seleccione">
                      <Select.Option value={1}>Hombre</Select.Option>
                      <Select.Option value={2}>Mujer</Select.Option>
                      <Select.Option value={3}>
                        Hombre Transgenero
                      </Select.Option>
                      <Select.Option value={4}>Mujer Transgenero</Select.Option>
                      <Select.Option value={5}>Intersexual</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[40, 16]} justify="left">
                <Col lg={12} md={12} xs={24}>
                  <Form.Item
                    label="3. Edad"
                    name="edad_empleabilidad"
                    rules={[
                      {
                        required: true,
                        message: "Este campo es requerido",
                      },
                    ]}
                    value={edad_empleabilidad}
                  >
                    <InputNumber
                      disabled={true}
                      style={{ width: "100%" }}
                      placeholder="Digite la edad"
                    />
                  </Form.Item>
                </Col>
                <Col lg={12} md={12} xs={24}>
                  <Form.Item
                    label="4. Tipo de formación (curso-carreras)"
                    name="tipo_empleabilidad"
                    rules={[
                      {
                        required: true,
                        message: "Este campo es requerido",
                      },
                    ]}
                    value={tipo_empleabilidad}
                  >
                    <Input.TextArea
                      disabled={true}
                      placeholder="Digite la información"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[40, 16]} justify="left">
                <Col lg={12} md={12} xs={24}>
                  <Form.Item
                    label="5. Nombre del curso o carrera"
                    name="nombre_empleabilidad"
                    rules={[
                      {
                        required: true,
                        message: "Este campo es requerido",
                      },
                    ]}
                    value={nombre_empleabilidad}
                  >
                    <Input.TextArea
                      disabled={true}
                      placeholder="Digite la información"
                    />
                  </Form.Item>
                </Col>
                <Col lg={12} md={12} xs={24}>
                  <Form.Item
                    label="6. Descripción del proceso adelantado"
                    name="descripcion_empleabilidad"
                    rules={[
                      {
                        required: true,
                        message: "Este campo es requerido",
                      },
                    ]}
                    value={descripcion_empleabilidad}
                  >
                    <Input.TextArea
                      disabled={true}
                      placeholder="Digite la información"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[40, 16]} justify="left">
                <Col lg={12} md={12} xs={24}>
                  <Form.Item
                    label="7. Responsable del caso (código usuario)"
                    name="responsable_empleabilidad"
                    rules={[
                      {
                        required: true,
                        message: "Este campo es requerido",
                      },
                    ]}
                    value={responsable_empleabilidad}
                  >
                    <Input
                      disabled={true}
                      placeholder="Digite la información"
                    />
                  </Form.Item>
                </Col>
                <Col lg={12} md={12} xs={24}>
                  <Form.Item
                    label="8. ¿Tuvo solución?"
                    name="tuvo_solucion_empleabilidad"
                    rules={[
                      {
                        required: true,
                        message: "Este campo es requerido",
                      },
                    ]}
                    value={tuvo_solucion_empleabilidad}
                  >
                    <Select disabled={true} placeholder="Seleccione">
                      <Select.Option value="1">Si</Select.Option>
                      <Select.Option value="2">No</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[40, 16]} justify="left">
                <Col lg={12} md={12} xs={24}>
                  <Form.Item
                    label="8. Observaciones"
                    name="observaciones_empleabilidad"
                    rules={[
                      {
                        required: true,
                        message: "Este campo es requerido",
                      },
                    ]}
                    value={observaciones_empleabilidad}
                  >
                    <Input.TextArea
                      disabled={true}
                      placeholder="Digite la información"
                    />
                  </Form.Item>
                </Col>
                <Col lg={12} md={12} xs={24}></Col>
              </Row>
              <Divider />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Form.Item>
                  <Button type="primary" onClick={() => history.goBack()}>
                    Atras
                  </Button>
                </Form.Item>
              </div>
            </Card>
          </Form>
        </Container>
      </div>
    </>
  );
};
