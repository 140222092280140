import React from 'react';
import { Row, Col, CardBody, Card, Container } from "reactstrap";
import {MultiStepFormVer} from '../../components/MultiStepFormVer';
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

const FormularioVer = () => {
  
    return ( 
   <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            titulo='Registrar Caso'
            breadcrumb={{
              actual: 'Registrar Caso',
              linkActual: '#',
              anterior: 'Registrar Caso',
              linkAnterior: '#',
            }}
          />
            <MultiStepFormVer/>
        </Container>
      </div>
    </React.Fragment>


     );
}
 
export default FormularioVer;