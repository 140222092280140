import React from 'react';
import Input from './Input';
import { Button, Form, Row, Col } from 'antd';

const Formulario = ({ form, setForm, regex, loading, onSubmit }) => {
  const onChangeInput = (evt, id) => {
    if (evt && evt.persist) evt.persist();

    setForm(form => {
      const nuevoForm = {
        ...form,
        inputs: {
          ...form.inputs,
        },
      };

      const nuevoInput = {
        ...nuevoForm.inputs[id],
      };

      if (['input', 'textarea'].includes(form.inputs[id].config.elemento)) {
        nuevoInput.valor = evt.target.value;
      } else if (
        form.inputs[id].config.elemento === 'checkbox' &&
        !form.inputs[id].config.multiple
      ) {
        nuevoInput.valor = evt.target.checked;
      }  else {
        nuevoInput.valor = evt;
      }
      
      nuevoForm.inputs[id] = nuevoInput;
      return nuevoForm;
    });
  };

  
  const componentes = Object.keys(form.inputs).map((elem) => (
    <Input
      key={elem}
      onChange={onChangeInput}
      regex={regex ? regex[elem] : null}
      form
      {...form.inputs[elem]}
    />
  ));

  const valoresIniciales = {};

  for (let elem in form.inputs) {
    if (form.inputs[elem].valor && 
      ((typeof form.inputs[elem].valor === 'string' &&
        form.inputs[elem].valor !== '') ||
      (typeof form.inputs[elem].valor === 'object' &&
        Object.keys(form.inputs[elem].valor)) ||
      typeof form.inputs[elem].valor === 'number'
    )) {
      valoresIniciales[elem] = form.inputs[elem].valor;
    }
  }

  let propsColBoton = { xs: 24 };
  if (form.boton && form.boton.col) propsColBoton = form.boton.col;

  return (
    <div>
      <Form
        id="form"
        className="login-form"
        onFinish={onSubmit}
        initialValues={valoresIniciales}
        scrollToFirstError
      >
        <Row gutter={16} align="middle">
          {componentes}
          <Col {...propsColBoton}>
            {form.boton && (
              <Form.Item>
                <Button
                  className="btn-udec"
                  loading={loading ? loading :  false}
                  htmlType="submit"
                  block
                >
                  {form.boton.label}
                </Button>
              </Form.Item>
            )}
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Formulario;
