import React, { useState, useContext, useEffect } from "react";
import { ContextFormulario } from "../../contexts/formulario/ContextFormulario";
import {
  Form,
  Input,
  Button,
  Radio,
  Row,
  Col,
  Card,
  Select,
  InputNumber,
  Checkbox,
  Space,
  Divider,
} from "antd";
import "./styles.css";
import { ContextAutenticacion } from "../../contexts/autenticacion/ContextAutenticacion";
import { Alert } from "antd";
import { useHistory } from "react-router-dom";

export const SaludMental = ({ navigation }) => {
  const history = useHistory();
  const [bandera, setBandera] = useState(false);
  const [cambio, setCambio] = useState(false);
  const [datosBasicos, setDatosBasicos] = useState({
    discriminacion: "",
    lugares_discriminacion: "",
    lugares_discriminacion_otro: "",
    dedicacion: "",
    dedicacion_otro: "",
    abuso_sexual: "",
    abuso_sexual_observacion: "",
    caso_denunciado: "",
    capturado_culpable: "",
    maltratos: "",
    tipo_maltratos: "",
    tipo_maltratos_otro: "",
    denuncia_caso: "",
    integrante_familia: "",
    integrante_familia_otro: "",
    cambios: "",
    consumo_sustancias: "",
    consumo_sustancias_cuales: "",
    necesidad_retornar: "",

    produjo_discriminacion: "",
    produjo_atendio: "",
    sabe_abuso_sexual: "",
    sentir_relaciones: "",
    situacion_migrante: "",
  });

  const contextformulario = useContext(ContextFormulario);
  const {
    actualizarSaludMental,
    usuario_seleccionado,
    formulario_basico,
    usuario_seleccionado_salud_mental,
  } = contextformulario;
  const contextAutenticacion = useContext(ContextAutenticacion);
  const { usuario } = contextAutenticacion;
  const [data_formulario, setDataFormulario] = useState("");

  const {
    discriminacion,
    lugares_discriminacion,
    lugares_discriminacion_otro,
    dedicacion,
    dedicacion_otro,
    abuso_sexual,
    abuso_sexual_observacion,
    caso_denunciado,
    capturado_culpable,
    maltratos,
    tipo_maltratos,
    tipo_maltratos_otro,
    denuncia_caso,
    integrante_familia,
    integrante_familia_otro,
    cambios,
    consumo_sustancias,
    consumo_sustancias_cuales,
    necesidad_retornar,
    produjo_discriminacion,
    produjo_atendio,
    sabe_abuso_sexual,
    sentir_relaciones,
    situacion_migrante,
  } = datosBasicos;

  useEffect(() => {
    if (
      usuario_seleccionado !== null &&
      usuario_seleccionado_salud_mental !== null
    ) {
      setDatosBasicos({
        discriminacion: usuario_seleccionado.muerte_violenta,
        lugares_discriminacion: usuario_seleccionado.lugares_discriminacion,
        lugares_discriminacion_otro:
          usuario_seleccionado.lugares_discriminacion_otro,
        dedicacion: usuario_seleccionado_salud_mental.dedicacion,
        dedicacion_otro: usuario_seleccionado.dedicacion_otro,
        abuso_sexual: usuario_seleccionado.abuso_sexual,
        abuso_sexual_observacion: usuario_seleccionado.abuso_sexual_observacion,
        caso_denunciado: usuario_seleccionado.caso_denunciado,
        capturado_culpable: usuario_seleccionado.capturado_culpable,
        maltratos: usuario_seleccionado.maltratos,
        tipo_maltratos: usuario_seleccionado.tipo_maltratos,
        tipo_maltratos_otro: usuario_seleccionado.tipo_maltratos_otro,
        denuncia_caso: usuario_seleccionado.denuncia_caso,
        integrante_familia: usuario_seleccionado.integrante_familia,
        integrante_familia_otro: usuario_seleccionado.integrante_familia_otro,
        cambios: usuario_seleccionado.cambios,
        consumo_sustancias: usuario_seleccionado.consumo_sustancias,
        consumo_sustancias_cuales:
          usuario_seleccionado.consumo_sustancias_cuales,
        necesidad_retornar: usuario_seleccionado.necesidad_retornar,
        produjo_discriminacion: usuario_seleccionado.produjo_discriminacion,
        produjo_atendio: usuario_seleccionado.produjo_atendio,
        sabe_abuso_sexual: usuario_seleccionado.sabe_abuso_sexual,
        sentir_relaciones: usuario_seleccionado.sentir_relaciones,
        situacion_migrante: usuario_seleccionado.situacion_migrante,
      });
      setCambio(false);
    }
  }, [usuario_seleccionado]);

  useEffect(() => {
    if (formulario_basico.length > 0) {
      setDataFormulario(formulario_basico[0]);
    }
  }, [formulario_basico]);

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };

  const checkStyle = {
    display: "block",
    marginRight: "0",
  };

  const checkStyle1 = {
    display: "block",
    marginRight: "0",
    marginBottom: "1rem",
    paddingTop: "0.5rem",
  };

  const onFinish = (values) => {
    navigation.next();
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const onChange = (e) => {
    setDatosBasicos({
      ...datosBasicos,
      [e[0].name]: e[0].value,
    });
    setBandera(true);
    if (bandera) {
      setCambio(true);
    } else {
      setCambio(false);
    }
  };

  return (
    <>
      <Form
        layout="vertical"
        name="basic"
        initialValues={{
          discriminacion: usuario_seleccionado
            ? usuario_seleccionado.discriminacion
            : undefined,
          lugares_discriminacion: usuario_seleccionado
            ? usuario_seleccionado.lugares_discriminacion
            : undefined,
          lugares_discriminacion_otro: usuario_seleccionado
            ? usuario_seleccionado.lugares_discriminacion_otro
            : "",
          dedicacion: usuario_seleccionado_salud_mental
            ? usuario_seleccionado_salud_mental.dedicacion
            : "",
          dedicacion_otro: usuario_seleccionado
            ? usuario_seleccionado.dedicacion_otro
            : "",
          abuso_sexual: usuario_seleccionado
            ? usuario_seleccionado.abuso_sexual
            : undefined,
          abuso_sexual_observacion: usuario_seleccionado
            ? usuario_seleccionado.abuso_sexual_observacion
            : "",
          caso_denunciado: usuario_seleccionado
            ? usuario_seleccionado.caso_denunciado
            : undefined,
          capturado_culpable: usuario_seleccionado
            ? usuario_seleccionado.capturado_culpable
            : undefined,
          maltratos: usuario_seleccionado
            ? usuario_seleccionado.maltratos
            : undefined,
          tipo_maltratos: usuario_seleccionado
            ? usuario_seleccionado.tipo_maltratos
            : undefined,
          tipo_maltratos_otro: usuario_seleccionado
            ? usuario_seleccionado.tipo_maltratos_otro
            : "",
          denuncia_caso: usuario_seleccionado
            ? usuario_seleccionado.denuncia_caso
            : undefined,
          integrante_familia: usuario_seleccionado
            ? usuario_seleccionado.integrante_familia
            : undefined,
          integrante_familia_otro: usuario_seleccionado
            ? usuario_seleccionado.integrante_familia_otro
            : "",
          cambios: usuario_seleccionado ? usuario_seleccionado.cambios : "",
          consumo_sustancias: usuario_seleccionado
            ? usuario_seleccionado.consumo_sustancias
            : undefined,
          consumo_sustancias_cuales: usuario_seleccionado
            ? usuario_seleccionado.consumo_sustancias_cuales
            : "",
          necesidad_retornar: usuario_seleccionado
            ? usuario_seleccionado.necesidad_retornar
            : undefined,
          produjo_discriminacion: usuario_seleccionado
            ? usuario_seleccionado.produjo_discriminacion
            : undefined,
          produjo_atendio: usuario_seleccionado
            ? usuario_seleccionado.produjo_atendio
            : undefined,
          sabe_abuso_sexual: usuario_seleccionado
            ? usuario_seleccionado.sabe_abuso_sexual
            : undefined,
          sentir_relaciones: usuario_seleccionado
            ? usuario_seleccionado.sentir_relaciones
            : undefined,
          situacion_migrante: usuario_seleccionado
            ? usuario_seleccionado.situacion_migrante
            : undefined,
        }}
        onFieldsChange={onChange}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Card className="borde">
          <div className="titulo">
            <p>2. Salud Mental</p>
          </div>
          <Row gutter={[40, 16]} justify="left">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="72. ¿Se ha sentido discriminada (o) por ser migrantes en Colombia?"
                name="discriminacion"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={discriminacion}
              >
                <Select disabled={true} placeholder="Seleccione">
                  <Select.Option value="1">Si</Select.Option>
                  <Select.Option value="2">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="73. ¿En qué lugares te has sentido discriminada (o)? "
                name="lugares_discriminacion"
                rules={[
                  {
                    required: discriminacion === "2" ? false : true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={lugares_discriminacion}
              >
                <Select disabled={true} placeholder="Seleccione">
                  <Select.Option value="1">Trabajo</Select.Option>
                  <Select.Option value="2">Calle</Select.Option>
                  <Select.Option value="3">Comunidad</Select.Option>
                  <Select.Option value="4">Vivienda</Select.Option>
                  <Select.Option value="5">Escuela</Select.Option>
                  <Select.Option value="6">Otro</Select.Option>
                </Select>
              </Form.Item>
              {lugares_discriminacion === "6" ? (
                <Form.Item
                  style={{ paddingTop: "1rem" }}
                  label="Donde"
                  name="lugares_discriminacion_otro"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                  value={lugares_discriminacion_otro}
                >
                  <Input disabled={true} placeholder="Digite" />
                </Form.Item>
              ) : null}
            </Col>
          </Row>

          <Row gutter={[40, 16]} justify="left">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="74. ¿Qué produjo en ti la situación de discriminación?"
                name="produjo_discriminacion"
                rules={[
                  {
                    required: discriminacion === "2" ? false : true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={produjo_discriminacion}
              >
                <Select disabled={true} placeholder="Seleccione">
                  <Select.Option value="1">
                    Cansancio, irritabilidad, problemas de sueño
                  </Select.Option>
                  <Select.Option value="2">
                    Estrés en la vida diaria
                  </Select.Option>
                  <Select.Option value="3">Falta de apetito</Select.Option>
                  <Select.Option value="4">
                    Alejamiento de amistades
                  </Select.Option>
                  <Select.Option value="5">
                    Sentimientos de tristeza o desanimo
                  </Select.Option>
                  <Select.Option value="6">
                    Preocupaciones o miedos excesivos
                  </Select.Option>
                  <Select.Option value="7">
                    Cambios en el deseo sexual
                  </Select.Option>
                  <Select.Option value="8">Pensamiento suicida</Select.Option>
                  <Select.Option value="9">
                    Exceso de enojo, hostilidad o violencia
                  </Select.Option>
                  <Select.Option value="10">Ninguno</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="75. ¿Cómo atendió a eso? (Se realiza sí reconoce algún síntoma de la pregunta anterior)"
                name="produjo_atendio"
                rules={[
                  {
                    required:
                      discriminacion !== "2" && produjo_discriminacion !== "10"
                        ? true
                        : false,
                    message: "Este campo es requerido",
                  },
                ]}
                value={produjo_atendio}
              >
                <Select disabled={true} placeholder="Seleccione">
                  <Select.Option value="1">Hice ejercicio</Select.Option>
                  <Select.Option value="2">En el trabajo</Select.Option>
                  <Select.Option value="3">
                    En casa con mi familia
                  </Select.Option>
                  <Select.Option value="4">No le presta atención</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[40, 16]} justify="left">
            <Col lg={12} md={12} xs={24}>
              <p style={{ fontWeight: "500" }}>
                <span style={{ color: "#ff4d4f" }}>* </span>76. ¿A qué se dedica
                usted y su familia en el tiempo libre? (puede marcar más de una
                opción)
              </p>
              <Row>
                <Col lg={14} md={12} xs={12}>
                  <Form.Item
                    name="dedicacion"
                    rules={[
                      {
                        required: true,
                        message: "Este campo es requerido",
                      },
                    ]}
                    value={dedicacion}
                  >
                    <Checkbox.Group disabled={true}>
                      <Checkbox style={checkStyle1} value={1}>
                        Hacer reuniones familiares
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={2}>
                        Paseos
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={3}>
                        Practicar algún tipo de deporte
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={4}>
                        Dormir, descansar.
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={5}>
                        Cada uno se dedicas a hacer distintas cosas
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={6}>
                        Estar en la casa
                      </Checkbox>
                      <Checkbox style={checkStyle1} value={7}>
                        Otro
                      </Checkbox>
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
                <Col lg={10} md={12} xs={12}>
                  {dedicacion !== null && dedicacion.includes(7) ? (
                    <Form.Item
                      style={{ marginTop: "18.5rem" }}
                      name="dedicacion_otro"
                      rules={[
                        {
                          required: true,
                          message: "Este campo es requerido",
                        },
                      ]}
                      value={dedicacion_otro}
                    >
                      <Input
                        disabled={true}
                        placeholder="Otro"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  ) : null}
                </Col>
              </Row>
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="77. ¿Sabe que es un abuso sexual?"
                name="sabe_abuso_sexual"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={sabe_abuso_sexual}
              >
                <Select disabled={true} placeholder="Seleccione">
                  <Select.Option value="1">Si</Select.Option>
                  <Select.Option value="2">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[40, 16]} justify="left">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="78. ¿Conoce algún caso de abuso sexual a un miembro de la población migrante?"
                name="abuso_sexual"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={abuso_sexual}
              >
                <Select disabled={true} placeholder="Seleccione">
                  <Select.Option value="1">Si</Select.Option>
                  <Select.Option value="2">No</Select.Option>
                </Select>
              </Form.Item>
              {abuso_sexual === "1" ? (
                <Form.Item
                  style={{ paddingTop: "1rem" }}
                  label="Observación"
                  name="abuso_sexual_observacion"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                  value={abuso_sexual_observacion}
                >
                  <Input disabled={true} placeholder="Digite" />
                </Form.Item>
              ) : null}
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="79. ¿Sabe usted si el caso fue denunciado?"
                name="caso_denunciado"
                rules={[
                  {
                    required: abuso_sexual === "1" ? true : false,
                    message: "Este campo es requerido",
                  },
                ]}
                value={caso_denunciado}
              >
                <Select disabled={true} placeholder="Seleccione">
                  <Select.Option value="1">Denunciado</Select.Option>
                  <Select.Option value="2">No denunciado</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[40, 16]} justify="left">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="80. ¿El culpable fue capturado?"
                name="capturado_culpable"
                rules={[
                  {
                    required: abuso_sexual === "1" ? true : false,
                    message: "Este campo es requerido",
                  },
                ]}
                value={capturado_culpable}
              >
                <Select disabled={true} placeholder="Seleccione">
                  <Select.Option value="1">Si</Select.Option>
                  <Select.Option value="2">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="81. ¿Al interior de su familia se presentan o se han presentados casos de maltratos o violencias?"
                name="maltratos"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={maltratos}
              >
                <Select disabled={true} placeholder="Seleccione">
                  <Select.Option value="1">Si</Select.Option>
                  <Select.Option value="2">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[40, 16]} justify="left">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="82. ¿Qué tipos de maltratos se presentó? (solo si responde SI en la pregunta anterior)."
                name="tipo_maltratos"
                rules={[
                  {
                    required: maltratos === "1" ? true : false,
                    message: "Este campo es requerido",
                  },
                ]}
                value={tipo_maltratos}
              >
                <Select disabled={true} placeholder="Seleccione">
                  <Select.Option value="1">Físico</Select.Option>
                  <Select.Option value="2">Verbal</Select.Option>
                  <Select.Option value="3">Psicológico</Select.Option>
                  <Select.Option value="4">Infantil</Select.Option>
                  <Select.Option value="5">Otro</Select.Option>
                </Select>
              </Form.Item>

              {tipo_maltratos === "5" ? (
                <Form.Item
                  style={{ paddingTop: "1rem" }}
                  label="Tipo de maltrato"
                  name="tipo_maltratos_otro"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                  value={tipo_maltratos_otro}
                >
                  <Input disabled={true} placeholder="Digite" />
                </Form.Item>
              ) : null}
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="83. ¿Denunciaron el caso? (solo si responde SI en la pregunta anterior)."
                name="denuncia_caso"
                rules={[
                  {
                    required: maltratos === "1" ? true : false,
                    message: "Este campo es requerido",
                  },
                ]}
                value={denuncia_caso}
              >
                <Select disabled={true} placeholder="Seleccione">
                  <Select.Option value="1">Si</Select.Option>
                  <Select.Option value="2">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[40, 16]} justify="left">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="84. Contra que integrante de la familia se presentó el tipo de maltrato:"
                name="integrante_familia"
                rules={[
                  {
                    required: denuncia_caso === "1" ? true : false,
                    message: "Este campo es requerido",
                  },
                ]}
                value={integrante_familia}
              >
                <Select disabled={true} placeholder="Seleccione">
                  <Select.Option value="1">Niños o niñas</Select.Option>
                  <Select.Option value="2">Pareja</Select.Option>
                  <Select.Option value="3">Madre</Select.Option>
                  <Select.Option value="4">Otro</Select.Option>
                </Select>
              </Form.Item>
              {integrante_familia === "4" ? (
                <Form.Item
                  style={{ paddingTop: "1rem" }}
                  label="Observación"
                  name="integrante_familia_otro"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                  value={integrante_familia_otro}
                >
                  <Input disabled={true} placeholder="Digite" />
                </Form.Item>
              ) : null}
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                style={{ paddingTop: "1rem", display: "none" }}
                label="85. Por favor responda si ha sentido que en su familia han ocurrido los siguientes cambios en los menores de 18 años: (leer la lista) "
                name="cambios"
                rules={[
                  {
                    required: false,
                    message: "Este campo es requerido",
                  },
                ]}
                value={cambios}
              >
                <Input.TextArea disabled={true} placeholder="Digite" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[40, 16]} justify="left">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="86. ¿Usted o algún miembro de su familia consume bebidas alcohólicas o sustancias psicoactivas despúes de haberse desplazado de su lugar de origen?"
                name="consumo_sustancias"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={consumo_sustancias}
              >
                <Select disabled={true} placeholder="Seleccione">
                  <Select.Option value="1">Alcohol</Select.Option>
                  <Select.Option value="2">Cigarillo</Select.Option>
                  <Select.Option value="3">Otro</Select.Option>
                  <Select.Option value="4">No consume</Select.Option>
                </Select>
              </Form.Item>
              {consumo_sustancias === "3" ? (
                <Form.Item
                  style={{ paddingTop: "1rem" }}
                  label="¿Cuáles?"
                  name="consumo_sustancias_cuales"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                  value={consumo_sustancias_cuales}
                >
                  <Input disabled={true} placeholder="Digite" />
                </Form.Item>
              ) : null}
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="87. ¿Cómo se siente con las relaciones con sus familiares, amigos y comunidad?"
                name="sentir_relaciones"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={sentir_relaciones}
              >
                <Select disabled={true} placeholder="Seleccione">
                  <Select.Option value="1">Satisfecho</Select.Option>
                  <Select.Option value="2">Poco Satisfecho</Select.Option>
                  <Select.Option value="3">Insatisfecho</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[40, 16]} justify="left">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="88. ¿Considera que su situación de migrante puede afectar la manera en la que se relaciona con los demás?"
                name="situacion_migrante"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={situacion_migrante}
              >
                <Select disabled={true} placeholder="Seleccione">
                  <Select.Option value="1">Si</Select.Option>
                  <Select.Option value="2">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="89. ¿En estos momentos tiene necesidad de retornar a su país?"
                name="necesidad_retornar"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={necesidad_retornar}
              >
                <Select disabled={true} placeholder="Seleccione">
                  <Select.Option value="1">Si</Select.Option>
                  <Select.Option value="2">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Divider />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "2rem",
            }}
          >
            <Form.Item>
              <Button
                style={{ marginRight: "2rem" }}
                type="primary"
                onClick={() => navigation.previous()}
              >
                Atras
              </Button>
              <Button type="primary" htmlType="submit">
                Siguiente
              </Button>
            </Form.Item>
          </div>
        </Card>
      </Form>
    </>
  );
};
