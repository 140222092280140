import React, { createContext, useReducer } from "react";
import ReducerFormulario from "./ReducerFormulario";
import instance from "../../axios/axios-api";

import {
  REGISTRAR_DATOS_BASICOS,
  ERROR_FORMULARIO,
  OBTENER_PRESTAMOS,
  PRESTAMO_ERROR,
  REGISTRAR_USUARIO,
  OBTENER_USUARIOS,
  OBTENER_CARACTERIZACIONES,
  OBTENER_USUARIO_SELECCIONADO,
  ACTUALIZAR_DATOS_BASICOS,
  ACTUALIZAR_TIPO_ASESORIA,
  ELIMINAR_CARACTERIZACION,
  BORRAR,
  USUARIO_SELECCIONADO_EDITAR,
  USUARIO_SELECCIONADO_ASESOR,
  OBTENER_ASESORES,
  ASIGNAR_ASESOR,
  ACTUALIZAR_DATOS_PERSONALES,
  ACTUALIZAR_ENTORNO_FAMILIAR,
  ACTUALIZAR_SALUD_FAMILIAR,
  ACTUALIZAR_MORBILIDAD_MENOR,
  ACTUALIZAR_MORBILIDAD_MAYOR,
  ACTUALIZAR_MORBILIDAD_CRONICA,
  ACTUALIZAR_MORTALIDAD,
  ACTUALIZAR_SALUD_MENTAL,
  ACTUALIZAR_JURIDICA,
  ACTUALIZAR_PSICOLOGICA,
  ACTUALIZAR_EMPLEABILIDAD,
  OBTENER_USUARIO_SELECCIONADO_DATOS_PERSONALES,
  OBTENER_USUARIO_SELECCIONADO_ENTORNO_FAMILIAR,
  OBTENER_USUARIO_SELECCIONADO_SALUD_FAMILIAR,
  OBTENER_USUARIO_SELECCIONADO_MORBILIDAD,
  OBTENER_USUARIO_SELECCIONADO_SALUD_MENTAL,
  OBTENER_USUARIO_SELECCIONADO_JURIDICA,
  OBTENER_USUARIO_ASIGNACION,
  ASESORES_ASIGNADOS,
  OBTENER_SEGUIMIENTO_SELECCIONADO,
  REGISTRAR_SEGUIMIENTO_JURIDICO,
  OBTENER_SEGUIMIENTOS_JURIDICA,
  SEGUIMIENTO_SELECCIONADO_EDITAR,
  EDITAR_SEGUIMIENTO,
  ELIMINAR_SEGUIMIENTO_JURIDICO,
  OBTENER_SEGUIMIENTOS_EMPLEABILIDAD,
  REGISTRAR_SEGUIMIENTO_EMPLEABILIDAD,
  EDITAR_SEGUIMIENTO_EMPLEABILIDAD,
  ELIMINAR_SEGUIMIENTO_EMPLEABILIDAD,
  OBTENER_SEGUIMIENTOS_PSICOSOCIAL,
  REGISTRAR_SEGUIMIENTO_PSICOSOCIAL,
  EDITAR_SEGUIMIENTO_PSICOSOCIAL,
  ELIMINAR_SEGUIMIENTO_PSICOSOCIAL,
  ELIMINAR_USUARIO,
  OBTENER_USUARIO_SELECCIONADO_BACK,
  ACTUALIZAR_USUARIO_ADMIN,
} from "../../types/index";

import { useHistory } from "react-router-dom";

export const ContextFormulario = createContext();

const ProviderFormulario = ({ children }) => {
  const initialState = {
    formulario_basico: [],
    listado: [],
    usuario: [],
    usuarios: [],
    caraterizaciones: [],
    asesores: [],
    caraterizaciones1: [],
    mensaje: null,
    usuario_seleccionado: null,
    seguimiento_seleccionado: null,
    usuario_seleccionado1: null,
    usuario_seleccionado_datos_personales: null,
    usuario_seleccionado_entorno_familiar: null,
    usuario_seleccionado_salud_familiar: null,
    usuario_seleccionado_morbilidad: null,
    usuario_seleccionado_salud_mental: null,
    usuario_seleccionado_juridica: null,
    usuario_seleccionado_asignacion: null,
    asesores_asignados: [],
    seguimiento_juridico: [],
    seguimientos_juridica: [],
    seguimiento_editar: null,
    loading: true,
    usuario_seleccionado_back: null,
  };

  const [state, dispatch] = useReducer(ReducerFormulario, initialState);
  const history = useHistory();

  //Obtener caracterizaciones
  const obtenerCaraterizaciones = async (estado, id) => {
    //console.log(estado, id)
    try {
      const resultado = await instance.get(`/api/caracterizaciones/borrador`, {
        params: { estado, id },
      });
      dispatch({
        type: OBTENER_PRESTAMOS,
        payload: resultado.data,
      });
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
        categoria: "danger",
      };
      dispatch({
        type: PRESTAMO_ERROR,
        payload: alerta,
      });
    }
  };

  //Agregar formulario datos basicos
  const agregarDatosBasicos = async (data) => {
    try {
      // const resultado = await instance.post("api/personas/register", data);
      const resultado = localStorage.getItem("id");
      // console.log(resultado);
      dispatch({
        type: REGISTRAR_DATOS_BASICOS,
        payload: resultado,
      });
    } catch (error) {
      //console.log(error.response);
      const alerta = {
        msg: error.response.data.msg,
        categoria: "danger",
      };
      dispatch({
        type: ERROR_FORMULARIO,
        payload: alerta,
      });
    }
  };

  //Actualizar y/o editar datos basicos
  const actualizarDatosBasicos = async (data) => {
    try {
      const resultado = await instance.put(
        `/api/caracterizaciones/update`,
        data
      );
      //console.log(resultado);
      dispatch({
        type: ACTUALIZAR_DATOS_BASICOS,
        payload: resultado.data.id,
      });
    } catch (error) {
      //console.log(error);
    }
  };

  //Actualizar y/o editar datos personales
  const actualizarDatosPersonales = async (data) => {
    try {
      const resultado = await instance.put(
        `/api/caracterizaciones/update_names`,
        data
      );
      //console.log(resultado);
      dispatch({
        type: ACTUALIZAR_DATOS_PERSONALES,
        payload: resultado.data.id,
      });
    } catch (error) {
      //console.log(error);
    }
  };

  //Actualizar y/o editar datos personales
  const actualizarEntornoFamiliar = async (data) => {
    try {
      const resultado = await instance.put(
        `/api/caracterizaciones/update_familiar`,
        data
      );
      //console.log(resultado);
      dispatch({
        type: ACTUALIZAR_ENTORNO_FAMILIAR,
        payload: resultado.data.id,
      });
    } catch (error) {
      //console.log(error);
    }
  };

  //Actualizar y/o editar salud familiar
  const actualizarSaludFamiliar = async (data) => {
    try {
      const resultado = await instance.put(
        `/api/caracterizaciones/update_salud_familiar`,
        data
      );
      //console.log(resultado);
      dispatch({
        type: ACTUALIZAR_SALUD_FAMILIAR,
        payload: resultado.data.id,
      });
    } catch (error) {
      // console.log(error);
    }
  };

  //Actualizar y/o editar morbilidad menor
  const actualizarMorbilidadMenor = async (data) => {
    try {
      const resultado = await instance.put(
        `/api/caracterizaciones/update_morbilidad_menores`,
        data
      );
      //console.log(resultado);
      dispatch({
        type: ACTUALIZAR_MORBILIDAD_MENOR,
        payload: resultado.data.id,
      });
    } catch (error) {
      //console.log(error);
    }
  };

  //Actualizar y/o editar morbilidad mayor
  const actualizarMorbilidadMayor = async (data) => {
    try {
      const resultado = await instance.put(
        `/api/caracterizaciones/update_morbilidad_mayores`,
        data
      );
      //console.log(resultado);
      dispatch({
        type: ACTUALIZAR_MORBILIDAD_MAYOR,
        payload: resultado.data.id,
      });
    } catch (error) {
      // console.log(error);
    }
  };

  //Actualizar y/o editar morbilidad cronica
  const actualizarMorbilidadCronica = async (data) => {
    try {
      const resultado = await instance.put(
        `/api/caracterizaciones/update_morbilidad_cronica`,
        data
      );
      //console.log(resultado);
      dispatch({
        type: ACTUALIZAR_MORBILIDAD_CRONICA,
        payload: resultado.data.id,
      });
    } catch (error) {
      // console.log(error);
    }
  };

  //Actualizar y/o editar mortalidad
  const actualizarMortalidad = async (data) => {
    try {
      const resultado = await instance.put(
        `/api/caracterizaciones/update_mortalidad`,
        data
      );
      //console.log(resultado);
      dispatch({
        type: ACTUALIZAR_MORTALIDAD,
        payload: resultado.data.id,
      });
    } catch (error) {
      // console.log(error);
    }
  };

  //Actualizar y/o editar salud mental
  const actualizarSaludMental = async (data) => {
    try {
      const resultado = await instance.put(
        `/api/caracterizaciones/update_salud_mental`,
        data
      );
      //console.log(resultado);
      dispatch({
        type: ACTUALIZAR_SALUD_MENTAL,
        payload: resultado.data.id,
      });
    } catch (error) {
      //console.log(error);
    }
  };

  //Actualizar y/o editar juridica
  const actualizarJuridica = async (data) => {
    try {
      const resultado = await instance.put(
        `/api/caracterizaciones/update_juridica`,
        data
      );
      //console.log(resultado);
      dispatch({
        type: ACTUALIZAR_JURIDICA,
        payload: resultado.data.id,
      });
    } catch (error) {
      //console.log(error);
    }
  };

  //Actualizar y/o editar psicologica
  const actualizarPsicologica = async (data) => {
    try {
      const resultado = await instance.put(
        `/api/caracterizaciones/update_psicologica`,
        data
      );
      //console.log(resultado);
      dispatch({
        type: ACTUALIZAR_PSICOLOGICA,
        payload: resultado.data.id,
      });
    } catch (error) {
      //console.log(error);
    }
  };

  //Actualizar y/o editar empleabilidad
  const actualizarEmpleabilidad = async (data) => {
    try {
      const resultado = await instance.put(
        `/api/caracterizaciones/update_empleabilidad`,
        data
      );
      //console.log(resultado);
      dispatch({
        type: ACTUALIZAR_EMPLEABILIDAD,
        payload: resultado.data.id,
      });
    } catch (error) {
      //console.log(error);
    }
  };

  //Actualizar y/o editar tipo de asesoria
  const actualizarTipoAsesoria = async (data) => {
    try {
      const resultado = await instance.put(
        `/api/caracterizaciones/update/tipo`,
        data
      );
      //console.log(resultado);
      dispatch({
        type: ACTUALIZAR_TIPO_ASESORIA,
        payload: resultado.data.id,
      });
    } catch (error) {
      //console.log(error);
    }
  };

  //AsesoresAsignados
  const AsesoresAsignados = async (data) => {
    //console.log(data);
    try {
      const resultado = await instance.get("/api/caracterizacion/Asignacion", {
        params: { data },
      });
      //console.log(resultado);
      dispatch({
        type: ASESORES_ASIGNADOS,
        payload: resultado.data,
      });
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
        categoria: "danger",
      };
      dispatch({
        type: PRESTAMO_ERROR,
        payload: alerta,
      });
    }
  };

  //Agregar nuevos usuarios
  const agregarUsuario = async (data) => {
    try {
      const resultado = await instance.post("api/auth/signup", data);

      dispatch({
        type: REGISTRAR_USUARIO,
        payload: resultado.data,
      });

      obtenerUsuarios();
    } catch (error) {
      // console.log(error.response);
      const alerta = {
        msg: error.response.data.msg,
        categoria: "danger",
      };
      dispatch({
        type: ERROR_FORMULARIO,
        payload: alerta,
      });
    }
  };

  //Obtener los usuarios registrados
  const obtenerUsuarios = async () => {
    try {
      const resultado = await instance.get("/api/users");

      dispatch({
        type: OBTENER_USUARIOS,
        payload: resultado.data,
      });
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
        categoria: "danger",
      };
      dispatch({
        type: PRESTAMO_ERROR,
        payload: alerta,
      });
    }
  };

  //Actualizar y/o editar usuario desde admin
  const actualizarUsuarioAdmin = async (data) => {
    try {
      const resultado = await instance.put(`/api/users/updateadmin`, data);
      //console.log(resultado);
      dispatch({
        type: ACTUALIZAR_USUARIO_ADMIN,
        payload: resultado.data,
      });
      obtenerUsuarios();
    } catch (error) {
      //console.log(error);
    }
  };

  const usuarioSeleccionado = async (data) => {
    //console.log(data)
    try {
      const resultado = await instance.get(
        "/api/caracterizacion/DatosBasicos",
        { params: { data } }
      );
      //console.log(resultado);
      dispatch({
        type: OBTENER_USUARIO_SELECCIONADO,
        payload: resultado.data,
      });
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
        categoria: "danger",
      };
      dispatch({
        type: PRESTAMO_ERROR,
        payload: alerta,
      });
    }
  };

  const usuarioSeleccionadoback = async (data) => {
    //console.log(data)
    try {
      const resultado = await instance.get(
        "/api/caracterizacion/DatosBasicos",
        { params: { data } }
      );
      //console.log(resultado);
      dispatch({
        type: OBTENER_USUARIO_SELECCIONADO_BACK,
        payload: resultado.data,
      });
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
        categoria: "danger",
      };
      dispatch({
        type: PRESTAMO_ERROR,
        payload: alerta,
      });
    }
  };

  const seguimientoSeleccionado = async (data) => {
    //console.log(data)
    try {
      const resultado = await instance.get(
        "/api/caracterizacion/DatosBasicos",
        { params: { data } }
      );

      dispatch({
        type: OBTENER_SEGUIMIENTO_SELECCIONADO,
        payload: resultado.data,
      });
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
        categoria: "danger",
      };
      dispatch({
        type: PRESTAMO_ERROR,
        payload: alerta,
      });
    }
  };

  //Obtener array de datos personales
  const usuarioSeleccionadoDatosPersonales = async (data) => {
    // console.log(data);
    try {
      const resultado = await instance.get(
        "/api/caracterizacion/DatosPersonales",
        { params: { data } }
      );

      dispatch({
        type: OBTENER_USUARIO_SELECCIONADO_DATOS_PERSONALES,
        payload: resultado.data,
      });
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
        categoria: "danger",
      };
      dispatch({
        type: PRESTAMO_ERROR,
        payload: alerta,
      });
    }
  };

  //Obtener array de entorno familiar
  const usuarioSeleccionadoEntornoFamiliar = async (data) => {
    //console.log(data);
    try {
      const resultado = await instance.get(
        "/api/caracterizacion/EntornoFamiliar",
        { params: { data } }
      );

      dispatch({
        type: OBTENER_USUARIO_SELECCIONADO_ENTORNO_FAMILIAR,
        payload: resultado.data,
      });
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
        categoria: "danger",
      };
      dispatch({
        type: PRESTAMO_ERROR,
        payload: alerta,
      });
    }
  };

  //Obtener array de salud familiar
  const usuarioSeleccionadoSaludFamiliar = async (data) => {
    // console.log(data);
    try {
      const resultado = await instance.get(
        "/api/caracterizacion/SaludFamiliar",
        { params: { data } }
      );

      dispatch({
        type: OBTENER_USUARIO_SELECCIONADO_SALUD_FAMILIAR,
        payload: resultado.data,
      });
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
        categoria: "danger",
      };
      dispatch({
        type: PRESTAMO_ERROR,
        payload: alerta,
      });
    }
  };

  //Obtener array de morbilidad
  const usuarioSeleccionadoMorbilidad = async (data) => {
    //console.log(data);
    try {
      const resultado = await instance.get("/api/caracterizacion/Morbilidad", {
        params: { data },
      });

      dispatch({
        type: OBTENER_USUARIO_SELECCIONADO_MORBILIDAD,
        payload: resultado.data,
      });
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
        categoria: "danger",
      };
      dispatch({
        type: PRESTAMO_ERROR,
        payload: alerta,
      });
    }
  };

  //Obtener array de de salud mental
  const usuarioSeleccionadoSaludMental = async (data) => {
    //console.log(data);
    try {
      const resultado = await instance.get("/api/caracterizacion/SaludMental", {
        params: { data },
      });

      dispatch({
        type: OBTENER_USUARIO_SELECCIONADO_SALUD_MENTAL,
        payload: resultado.data,
      });
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
        categoria: "danger",
      };
      dispatch({
        type: PRESTAMO_ERROR,
        payload: alerta,
      });
    }
  };

  //Obtener array de juridica
  const usuarioSeleccionadoJuridica = async (data) => {
    //console.log(data);
    try {
      const resultado = await instance.get(
        "/api/caracterizacion/AsesoriaJuridica",
        { params: { data } }
      );

      dispatch({
        type: OBTENER_USUARIO_SELECCIONADO_JURIDICA,
        payload: resultado.data,
      });
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
        categoria: "danger",
      };
      dispatch({
        type: PRESTAMO_ERROR,
        payload: alerta,
      });
    }
  };

  //Obtener array de asignacion

  const usuarioSeleccionadoAsignacion = async (data) => {
    //console.log(data);
    try {
      const resultado = await instance.get("/api/caracterizacion/Asignacion", {
        params: { data },
      });

      dispatch({
        type: OBTENER_USUARIO_ASIGNACION,
        payload: resultado.data,
      });
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
        categoria: "danger",
      };
      dispatch({
        type: PRESTAMO_ERROR,
        payload: alerta,
      });
    }
  };

  //Eliminar caracterizacion
  const eliminarRegistro = async (data) => {
    let id = data.id;
    try {
      const resultado = await instance.delete("/api/caracterizacion/delete", {
        params: { id },
      });
      dispatch({
        type: ELIMINAR_CARACTERIZACION,
        payload: resultado.data,
      });
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
        categoria: "danger",
      };
      dispatch({
        type: PRESTAMO_ERROR,
        payload: alerta,
      });
    }
  };

  //Obtener los caraterizaciones registradas sin asignar
  const obtenerCaraterizacionesAdmin = async (estado) => {
    try {
      const resultado = await instance.get(`/api/caracterizaciones/admin`, {
        params: { estado },
      });
      // console.log(resultado);
      dispatch({
        type: OBTENER_CARACTERIZACIONES,
        payload: resultado.data,
      });
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
        categoria: "danger",
      };
      dispatch({
        type: PRESTAMO_ERROR,
        payload: alerta,
      });
    }
  };

  //Obtener los caraterizaciones asiganada asesor
  const obtenerCaraterizacionesAsesor = async (estado, id) => {
    try {
      const resultado = await instance.get(
        `/api/caracterizacion/AsesorAsignado`,
        { params: { estado, id } }
      );
      dispatch({
        type: OBTENER_CARACTERIZACIONES,
        payload: resultado.data,
      });
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
        categoria: "danger",
      };
      dispatch({
        type: PRESTAMO_ERROR,
        payload: alerta,
      });
    }
  };

  //Reset al usuario seleccionado
  const borrarUsuarioSeleccionado = () => {
    try {
      dispatch({
        type: BORRAR,
        payload: null,
      });
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
        categoria: "danger",
      };
      dispatch({
        type: PRESTAMO_ERROR,
        payload: alerta,
      });
    }
  };

  //Usuario Seleccionado para editar
  const usuarioSeleccionadoEditar = (data) => {
    try {
      dispatch({
        type: USUARIO_SELECCIONADO_EDITAR,
        payload: data,
      });
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
        categoria: "danger",
      };
      dispatch({
        type: PRESTAMO_ERROR,
        payload: alerta,
      });
    }
  };

  //Selecciona un caso para asignarlo a un asesor
  const asignarCaso = async (data) => {
    try {
      const resultado = await instance.get("/api/caracterizacion/Asignacion", {
        params: { data },
      });
      //console.log(resultado);
      dispatch({
        type: USUARIO_SELECCIONADO_ASESOR,
        payload: resultado.data,
      });
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
        categoria: "danger",
      };
      dispatch({
        type: PRESTAMO_ERROR,
        payload: alerta,
      });
    }
  };

  //Obtener asesores
  const obtenerAsesores = async () => {
    try {
      const resultado = await instance.get("api/users/asesor");
      // console.log(resultado);
      dispatch({
        type: OBTENER_ASESORES,
        payload: resultado.data,
      });
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
        categoria: "danger",
      };
      dispatch({
        type: PRESTAMO_ERROR,
        payload: alerta,
      });
    }
  };

  //Actualizar y/o editar tipo de asesoria
  const asignarAsesor = async (data) => {
    try {
      const resultado = await instance.put(
        `/api/caracterizaciones/update/asesor`,
        data
      );
      //console.log(resultado);
      dispatch({
        type: ASIGNAR_ASESOR,
        payload: resultado.data.id,
      });
      obtenerCaraterizacionesAdmin("sin_asignar");
    } catch (error) {
      //console.log(error);
    }
  };

  /**
   * Seguimientos
   *
   * */

  //Agregar seguimiento juridico
  const agregarSeguimientoJuridico = async (data) => {
    try {
      const resultado = await instance.post(
        "/api/caracterizaciones/create/asesoriajuridica",
        data
      );
      //console.log(resultado);
      dispatch({
        type: REGISTRAR_SEGUIMIENTO_JURIDICO,
        payload: resultado.data.data,
      });

      obtenerSeguimientosJuridica("borrador");
    } catch (error) {
      // console.log(error.response);
      const alerta = {
        msg: error.response.data.msg,
        categoria: "danger",
      };
      dispatch({
        type: ERROR_FORMULARIO,
        payload: alerta,
      });
    }
  };

  //Agregar seguimiento juridico
  const agregarSeguimientoEmpleabilidad = async (data) => {
    try {
      const resultado = await instance.post(
        "/api/caracterizaciones/create/asesoriaempleabilidad",
        data
      );
      //console.log(resultado.data.data);
      dispatch({
        type: REGISTRAR_SEGUIMIENTO_EMPLEABILIDAD,
        payload: resultado.data.data,
      });

      obtenerSeguimientosEmpleabilidad("borrador");
    } catch (error) {
      // console.log(error.response);
      const alerta = {
        msg: error.response.data,
        categoria: "danger",
      };
      dispatch({
        type: ERROR_FORMULARIO,
        payload: alerta,
      });
    }
  };

  //Agregar seguimiento psicosocial
  const agregarSeguimientoPsicosocial = async (data) => {
    try {
      const resultado = await instance.post(
        "/api/caracterizaciones/create/asesoriapsicosocial",
        data
      );

      dispatch({
        type: REGISTRAR_SEGUIMIENTO_PSICOSOCIAL,
        payload: resultado.data.data,
      });

      obtenerSeguimientosPsicosocial("borrador");
    } catch (error) {
      // console.log(error.response);
      const alerta = {
        msg: error.response.data,
        categoria: "danger",
      };
      dispatch({
        type: ERROR_FORMULARIO,
        payload: alerta,
      });
    }
  };

  //Edita un seguimiento juridica
  const editarSeguimiento = async (data) => {
    try {
      const resultado = await instance.put(
        `/api/caracterizaciones/update/asesoriajuridica`,
        data
      );
      // console.log(resultado);
      dispatch({
        type: EDITAR_SEGUIMIENTO,
        payload: resultado.data,
      });
      obtenerSeguimientosJuridica("borrador");
    } catch (error) {
      // console.log(error);
    }
  };

  //Edita un seguimiento empleabilidad
  const editarSeguimientoEmpleabilidad = async (data) => {
    try {
      const resultado = await instance.put(
        `/api/caracterizaciones/update/asesoriaempleabilidad`,
        data
      );
      //console.log(resultado);
      dispatch({
        type: EDITAR_SEGUIMIENTO_EMPLEABILIDAD,
        payload: resultado.data,
      });
      obtenerSeguimientosEmpleabilidad("borrador");
    } catch (error) {
      // console.log(error);
    }
  };

  //Editar seguimiento psicosocial
  const editarSeguimientoPsicosocial = async (data) => {
    try {
      const resultado = await instance.put(
        `/api/caracterizaciones/update/asesoriapsicosocial`,
        data
      );
      //console.log(resultado);
      dispatch({
        type: EDITAR_SEGUIMIENTO_PSICOSOCIAL,
        payload: resultado.data,
      });
      obtenerSeguimientosPsicosocial("borrador");
    } catch (error) {
      //console.log(error);
    }
  };

  //Obtener los seguimientos del asesor juridico
  const obtenerSeguimientosJuridica = async (estado, id) => {
    // console.log(estado, id);
    try {
      const resultado = await instance.get(
        `/api/caracterizaciones/getAll/asesoriajuridica`,
        { params: { estado, id } }
      );
      // console.log(resultado);
      dispatch({
        type: OBTENER_SEGUIMIENTOS_JURIDICA,
        payload: resultado.data,
      });
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
        categoria: "danger",
      };
      dispatch({
        type: PRESTAMO_ERROR,
        payload: alerta,
      });
    }
  };

  //Obtener los seguimientos del asesor empleabilidad
  const obtenerSeguimientosEmpleabilidad = async (estado, id) => {
    //console.log(estado, id);
    try {
      const resultado = await instance.get(
        `/api/caracterizaciones/getAll/asesoriaempleabilidad`,
        { params: { estado, id } }
      );
      //console.log(resultado);
      dispatch({
        type: OBTENER_SEGUIMIENTOS_EMPLEABILIDAD,
        payload: resultado.data,
      });
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
        categoria: "danger",
      };
      dispatch({
        type: PRESTAMO_ERROR,
        payload: alerta,
      });
    }
  };

  //Obtener los seguimientos del asesor psicosocial
  const obtenerSeguimientosPsicosocial = async (estado, id) => {
    //console.log(estado);
    try {
      const resultado = await instance.get(
        `/api/caracterizaciones/getAll/asesoriapsicosocial`,
        { params: { estado, id } }
      );
      //console.log(resultado);
      dispatch({
        type: OBTENER_SEGUIMIENTOS_PSICOSOCIAL,
        payload: resultado.data,
      });
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
        categoria: "danger",
      };
      dispatch({
        type: PRESTAMO_ERROR,
        payload: alerta,
      });
    }
  };

  //Eliminar seguimiento Juridico
  const eliminarSeguimientoJuridico = async (id) => {
    try {
      const resultado = await instance.delete(
        "/api/caracterizaciones/delete/asesoriajuridica",
        { params: { id } }
      );
      dispatch({
        type: ELIMINAR_SEGUIMIENTO_JURIDICO,
        payload: resultado.data,
      });
      obtenerSeguimientosJuridica("borrador");
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
        categoria: "danger",
      };
      dispatch({
        type: PRESTAMO_ERROR,
        payload: alerta,
      });
    }
  };

  //Eliminar seguimiento Empleabilidad
  const eliminarSeguimientoEmpleabilidad = async (id) => {
    try {
      const resultado = await instance.delete(
        "/api/caracterizaciones/delete/asesoriaempleabilidad",
        { params: { id } }
      );
      // console.log(resultado);
      dispatch({
        type: ELIMINAR_SEGUIMIENTO_EMPLEABILIDAD,
        payload: resultado.config.params.id,
      });
      // obtenerSeguimientosEmpleabilidad("borrador")
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
        categoria: "danger",
      };
      dispatch({
        type: PRESTAMO_ERROR,
        payload: alerta,
      });
    }
  };

  //Eliminar seguimiento Empleabilidad
  const eliminarSeguimientoPsicosocial = async (id) => {
    try {
      const resultado = await instance.delete(
        "/api/caracterizaciones/delete/asesoriapsicosocial",
        { params: { id } }
      );
      dispatch({
        type: ELIMINAR_SEGUIMIENTO_PSICOSOCIAL,
        payload: resultado.data,
      });
      obtenerSeguimientosPsicosocial("borrador");
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
        categoria: "danger",
      };
      dispatch({
        type: PRESTAMO_ERROR,
        payload: alerta,
      });
    }
  };

  //Selecciona un seguimiento para editarlo
  const seguimientoSeleccionadoEditar = (data) => {
    try {
      dispatch({
        type: SEGUIMIENTO_SELECCIONADO_EDITAR,
        payload: data,
      });
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
        categoria: "danger",
      };
      dispatch({
        type: PRESTAMO_ERROR,
        payload: alerta,
      });
    }
  };

  /**
   * Cambiar contraseña desde el perfil
   */

  const cambiarContraseña = async (data) => {
    try {
      const resultado = await instance.put(`/api/users/update`, data);
      //  console.log(resultado)
    } catch (error) {
      // console.log(error);
    }
  };

  /**
   * Cerrar seguimiento
   */

  //Cerrar Seguimiento
  const cerrarSeguimiento = async (data) => {
    try {
      const resultado = await instance.put(
        `/api/caracterizaciones/update/tipo_estado`,
        data
      );
      // console.log(resultado);
    } catch (error) {
      // console.log(error);
    }
  };

  //Obtener los casos con estados
  const obtenerCaraterizacionesAsesorEstado = async (
    estado,
    estado_seguimiento,
    id
  ) => {
    try {
      const resultado = await instance.get(
        `/api/caracterizacion/AsesorAsignadoEstado`,
        { params: { estado, estado_seguimiento, id } }
      );
      dispatch({
        type: OBTENER_CARACTERIZACIONES,
        payload: resultado.data,
      });
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
        categoria: "danger",
      };
      dispatch({
        type: PRESTAMO_ERROR,
        payload: alerta,
      });
    }
  };

  //Cerrar Seguimiento admin
  const cerrarSeguimientoAdmin = async (data) => {
    //console.log(data);
    try {
      const resultado = await instance.put(
        `/api/caracterizaciones/update/estado`,
        data
      );
      //console.log(resultado);
    } catch (error) {
      // console.log(error);
    }
  };

  //Eliminar usuario
  const eliminarUsuario = async (data) => {
    let id = data.id;
    try {
      const resultado = await instance.delete("/api/users/delete", {
        params: { id },
      });
      dispatch({
        type: ELIMINAR_USUARIO,
        payload: resultado.data,
      });

      obtenerUsuarios();
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
        categoria: "danger",
      };
      dispatch({
        type: PRESTAMO_ERROR,
        payload: alerta,
      });
    }
  };

  const value = {
    formulario_basico: state.formulario_basico,
    listado: state.listado,
    usuario: state.usuario,
    usuarios: state.usuarios,
    mensaje: state.mensaje,
    usuario_seleccionado: state.usuario_seleccionado,
    caraterizaciones: state.caraterizaciones,
    asesores: state.asesores,
    usuario_seleccionado1: state.usuario_seleccionado1,
    usuario_seleccionado_datos_personales:
      state.usuario_seleccionado_datos_personales,
    usuario_seleccionado_entorno_familiar:
      state.usuario_seleccionado_entorno_familiar,
    usuario_seleccionado_salud_familiar:
      state.usuario_seleccionado_salud_familiar,
    usuario_seleccionado_morbilidad: state.usuario_seleccionado_morbilidad,
    usuario_seleccionado_salud_mental: state.usuario_seleccionado_salud_mental,
    usuario_seleccionado_juridica: state.usuario_seleccionado_juridica,
    usuario_seleccionado_asignacion: state.usuario_seleccionado_asignacion,
    asesores_asignados: state.asesores_asignados,
    seguimiento_seleccionado: state.seguimiento_seleccionado,
    seguimiento_juridico: state.seguimiento_juridico,
    seguimientos_juridica: state.seguimientos_juridica,
    seguimiento_editar: state.seguimiento_editar,
    loading: state.loading,
    usuario_seleccionado_back: state.loading,
    obtenerUsuarios,
    agregarDatosBasicos,
    obtenerCaraterizaciones,
    agregarUsuario,
    usuarioSeleccionado,
    obtenerCaraterizacionesAdmin,
    obtenerCaraterizacionesAsesor,
    actualizarDatosBasicos,
    actualizarTipoAsesoria,
    eliminarRegistro,
    borrarUsuarioSeleccionado,
    usuarioSeleccionadoEditar,
    asignarCaso,
    obtenerAsesores,
    asignarAsesor,
    actualizarDatosPersonales,
    actualizarEntornoFamiliar,
    actualizarSaludFamiliar,
    actualizarMorbilidadMenor,
    actualizarMorbilidadMayor,
    actualizarMorbilidadCronica,
    actualizarMortalidad,
    actualizarSaludMental,
    actualizarJuridica,
    actualizarPsicologica,
    actualizarEmpleabilidad,
    usuarioSeleccionadoDatosPersonales,
    usuarioSeleccionadoEntornoFamiliar,
    usuarioSeleccionadoSaludFamiliar,
    usuarioSeleccionadoMorbilidad,
    usuarioSeleccionadoSaludMental,
    usuarioSeleccionadoJuridica,
    usuarioSeleccionadoAsignacion,
    AsesoresAsignados,
    seguimientoSeleccionado,
    agregarSeguimientoJuridico,
    obtenerSeguimientosJuridica,
    seguimientoSeleccionadoEditar,
    editarSeguimiento,
    eliminarSeguimientoJuridico,
    obtenerSeguimientosEmpleabilidad,
    agregarSeguimientoEmpleabilidad,
    editarSeguimientoEmpleabilidad,
    eliminarSeguimientoEmpleabilidad,
    agregarSeguimientoEmpleabilidad,
    obtenerSeguimientosPsicosocial,
    agregarSeguimientoPsicosocial,
    editarSeguimientoPsicosocial,
    eliminarSeguimientoPsicosocial,
    cambiarContraseña,
    cerrarSeguimiento,
    obtenerCaraterizacionesAsesorEstado,
    cerrarSeguimientoAdmin,
    eliminarUsuario,
    usuarioSeleccionadoback,
    actualizarUsuarioAdmin,
  };

  return (
    <ContextFormulario.Provider value={value}>
      {children}
    </ContextFormulario.Provider>
  );
};

export default ProviderFormulario;
