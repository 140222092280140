import React, { useState, useContext, useEffect } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container } from "reactstrap";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

import {
  Form,
  Input,
  Button,
  Radio,
  Row,
  Col,
  Card,
  Select,
  InputNumber,
  Checkbox,
  Space,
  Divider,
  Alert,
} from "antd";
import { useHistory } from "react-router-dom";

import { ContextFormulario } from "../../contexts/formulario/ContextFormulario";

const NuevoUsuario = () => {
  const history = useHistory();
  const [alerta, setAlerta] = useState(false);
  const [alerta1, setAlerta1] = useState(false);

  const contextformulario = useContext(ContextFormulario);
  const {
    agregarUsuario,
    actualizarUsuarioAdmin,
    obtenerUsuarios,
    usuarios,
    usuario_seleccionado,
  } = contextformulario;
  const [formData, setForm] = useState({
    username: "",
    email: "",
    password: "",
    roles: [],
    tipo_asesoria: "",
  });

  const { username, email, password, roles, tipo_asesoria } = formData;

  useEffect(() => {
    obtenerUsuarios();
  }, []);

  // console.log(usuarios)

  //console.log(usuario_seleccionado)

  useEffect(() => {
    if (usuario_seleccionado !== null) {
      setForm({
        username: usuario_seleccionado.username,
        email: usuario_seleccionado.email,
        password: "",
        roles: usuario_seleccionado.roles.name,
        tipo_asesoria:
          usuario_seleccionado.tipo_asesoria === "Juridica"
            ? "1"
            : usuario_seleccionado.tipo_asesoria === "Empleabilidad"
            ? "2"
            : usuario_seleccionado.tipo_asesoria === "Psicologica"
            ? "3"
            : undefined,
      });
    }
  }, [usuario_seleccionado]);

  //console.log(fields)

  //console.log(formData)

  const onFinish = (values) => {
    if (usuario_seleccionado === null) {
      values["roles"] = [roles];
      //console.log("Success:", values);
      agregarUsuario(values);
      history.push("/usuarios");
    } else {
      values["id"] = usuario_seleccionado.id;
      values["roles"] = [roles];
      // console.log("Success:", values);
      actualizarUsuarioAdmin(values);
      history.push("/usuarios");
    }
  };

  const onFinishFailed = (errorInfo) => {
    //console.log("Failed:", errorInfo);
  };

  const onChange = (e) => {
    setForm({
      ...formData,
      [e[0].name]: e[0].value,
    });
  };

  // console.log(username)
  return (
    <div className="page-content">
      <Container fluid>
        {/* Render Breadcrumb */}
        <Breadcrumbs
          titulo="Crear Usuario"
          breadcrumb={{
            actual: "Crear Usuario",
            linkActual: "#",
            anterior: "Crear Usuario",
            linkAnterior: "#",
          }}
        />
        {alerta === true ? (
          <Alert
            message="Error"
            description="Este nombre de usuario ya se encuentra en uso, favor digitar otro."
            type="error"
            showIcon
          />
        ) : null}

        {alerta1 === true ? (
          <Alert
            message="Error"
            description="Este correo electronico ya se encuentra en uso, favor digitar otro."
            type="error"
            showIcon
          />
        ) : null}

        <Form
          layout="vertical"
          name="basic"
          initialValues={{
            username: usuario_seleccionado ? usuario_seleccionado.username : "",
            email: usuario_seleccionado ? usuario_seleccionado.email : "",
            roles: usuario_seleccionado
              ? usuario_seleccionado.roles.name
              : undefined,
            tipo_asesoria: usuario_seleccionado
              ? usuario_seleccionado.tipo_asesoria === "Juridica"
                ? "1"
                : usuario_seleccionado.tipo_asesoria === "Empleabilidad"
                ? "2"
                : usuario_seleccionado.tipo_asesoria === "Psicologica"
                ? "3"
                : undefined
              : undefined,
          }}
          onFieldsChange={onChange}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Card className="borde">
            <div className="titulo">
              <p>Crear usuarios</p>
            </div>

            <Row gutter={[40, 16]} justify="center">
              <Col lg={12} md={12} xs={24}>
                <Form.Item
                  label="Nombre de Usuario:"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                  value={username}
                >
                  <Input placeholder="Digite" />
                </Form.Item>
              </Col>
              <Col lg={12} md={12} xs={24}>
                <Form.Item
                  label="Email:"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      required: true,
                      message: "Digite un correo valido",
                    },
                  ]}
                  value={email}
                >
                  <Input placeholder="Digite" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[40, 16]} justify="center">
              <Col lg={12} md={12} xs={24}>
                <Form.Item
                  label="Contraseña:"
                  name="password"
                  rules={[
                    {
                      required: usuario_seleccionado !== null ? false : true,
                      message: "Este campo es requerido",
                    },
                  ]}
                  value={password}
                >
                  <Input.Password
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                    placeholder="Digite"
                  />
                </Form.Item>
              </Col>

              <Col lg={12} md={12} xs={24}>
                <Form.Item
                  label="Tipo de usuario:"
                  name="roles"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                  value={roles}
                >
                  <Select placeholder="Seleccione">
                    <Select.Option value="user">Digitador</Select.Option>
                    <Select.Option value="moderator">Asesor</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[40, 16]} justify="center">
              <Col lg={12} md={12} xs={24}>
                <Form.Item
                  label="Tipo de Asesor"
                  name="tipo_asesoria"
                  rules={[
                    {
                      required: roles === "moderator" ? true : false,
                      message: "Este campo es requerido",
                    },
                  ]}
                  value={tipo_asesoria}
                >
                  <Select
                    disabled={roles === "moderator" ? false : true}
                    placeholder="Seleccione"
                  >
                    <Select.Option value="1">Juridica</Select.Option>
                    <Select.Option value="2">Empleabilidad</Select.Option>
                    <Select.Option value="3">Psicologica</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col lg={12} md={12} xs={24}></Col>
            </Row>

            <Divider />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Guardar
                </Button>
              </Form.Item>
            </div>
          </Card>
        </Form>
      </Container>
    </div>
  );
};

export default NuevoUsuario;
