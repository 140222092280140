import React, { useState, useContext, useEffect } from "react";
import { ContextFormulario } from "../../contexts/formulario/ContextFormulario";
import {
  Form,
  Input,
  Button,
  Radio,
  Row,
  Col,
  Card,
  Select,
  InputNumber,
  Checkbox,
  Space,
  Divider,
} from "antd";
import "./styles.css";
import { ContextAutenticacion } from "../../contexts/autenticacion/ContextAutenticacion";
import { useHistory } from "react-router-dom";

export const Empleabilidad = ({ navigation }) => {
  const history = useHistory();
  const [flag, setFlag] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [datosBasicos, setDatosBasicos] = useState({
    formacion_empleo: "",
    orientacion_empresas: "",
  });

  const contextformulario = useContext(ContextFormulario);
  const { actualizarEmpleabilidad, usuario_seleccionado, formulario_basico } =
    contextformulario;
  const contextAutenticacion = useContext(ContextAutenticacion);
  const { usuario } = contextAutenticacion;
  const [data_formulario, setDataFormulario] = useState("");

  const { formacion_empleo, orientacion_empresas } = datosBasicos;

  useEffect(() => {
    if (usuario_seleccionado !== null) {
      setDatosBasicos({
        formacion_empleo: usuario_seleccionado.formacion_empleo,
        orientacion_empresas: usuario_seleccionado.orientacion_empresas,
      });
    }
  }, [usuario_seleccionado]);

  useEffect(() => {
    if (formulario_basico.length > 0) {
      setDataFormulario(formulario_basico[0]);
    }
  }, [formulario_basico]);

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };

  const checkStyle = {
    display: "block",
    marginRight: "0",
  };

  const checkStyle1 = {
    display: "block",
    marginRight: "0",
    marginBottom: "1rem",
    paddingTop: "0.5rem",
  };

  const onFinish = (values) => {
    navigation.next();
  };

  const onFinishFailed = (errorInfo) => {
    //console.log("Failed:", errorInfo);
  };

  const onChange = (e) => {
    setDatosBasicos({
      ...datosBasicos,
      [e[0].name]: e[0].value,
    });
  };

  return (
    <>
      <Form
        layout="vertical"
        name="basic"
        initialValues={{
          formacion_empleo: usuario_seleccionado
            ? usuario_seleccionado.formacion_empleo
            : "",
          orientacion_empresas: usuario_seleccionado
            ? usuario_seleccionado.orientacion_empresas
            : "",
        }}
        onFieldsChange={onChange}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Card className="borde">
          <div className="titulo">
            <p>4. Asesoría Empleabilidad</p>
          </div>
          <Row gutter={[40, 16]} justify="left">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="101. Formación para el empleo: ¿En qué?"
                name="formacion_empleo"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={formacion_empleo}
              >
                <Input.TextArea disabled={disabled} placeholder="Digite" />
              </Form.Item>
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="102. Orientación empresarial/ideas de negocios ¿En qué?"
                name="orientacion_empresas"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={orientacion_empresas}
              >
                <Input.TextArea disabled={disabled} placeholder="Digite" />
              </Form.Item>
            </Col>
          </Row>

          <Divider />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Form.Item>
              <Button
                style={{ marginRight: "2rem" }}
                type="primary"
                onClick={() => navigation.previous()}
              >
                Atras
              </Button>
              <Button type="primary" htmlType="submit">
                Siguiente
              </Button>
            </Form.Item>
          </div>
        </Card>
      </Form>
    </>
  );
};
