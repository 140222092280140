import React, { useState, useContext, useEffect } from "react";
import { ContextFormulario } from "../../contexts/formulario/ContextFormulario";
import {
  Form,
  Input,
  Button,
  Radio,
  Row,
  Col,
  Card,
  Select,
  InputNumber,
  Checkbox,
  Space,
  Divider,
  DatePicker,
} from "antd";
import "./styles.css";
import { ContextAutenticacion } from "../../contexts/autenticacion/ContextAutenticacion";
import { Container } from "reactstrap";
import moment from "moment";
import { useHistory } from "react-router-dom";

export const VerPsicosocial = () => {
  const history = useHistory();
  const [flag, setFlag] = useState(false);
  const [datosBasicos, setDatosBasicos] = useState({
    fecha_atencion_psicosocial: "",
    tipo_poblacion_psicosocial: "",
    edad_psicosocial: "",
    temas_psicosocial: "",
    avances_psicosocial: "",
    reportes_psicosocial: "",
    tuvo_solucion_psicosocial: "",
    observaciones_psicosocial: "",
    estado_psicosocial: "",
  });

  const contextformulario = useContext(ContextFormulario);
  const {
    seguimiento_juridico,
    caraterizaciones,
    seguimiento_editar,
    agregarSeguimientoPsicosocial,
    editarSeguimientoPsicosocial,
  } = contextformulario;
  const contextAutenticacion = useContext(ContextAutenticacion);
  const { usuario } = contextAutenticacion;

  const {
    fecha_atencion_psicosocial,
    tipo_poblacion_psicosocial,
    edad_psicosocial,
    temas_psicosocial,
    avances_psicosocial,
    reportes_psicosocial,
    tuvo_solucion_psicosocial,
    observaciones_psicosocial,
    estado_psicosocial,
  } = datosBasicos;

  useEffect(() => {
    if (seguimiento_editar !== null) {
      setDatosBasicos({
        fecha_atencion_psicosocial: seguimiento_editar.fecha,
        tipo_poblacion_psicosocial:
          seguimiento_editar.tipo_poblacion_psicosocial === "Niños"
            ? "1"
            : seguimiento_editar.tipo_poblacion_psicosocial === "Tercera edad"
            ? "2"
            : "3",
        edad_psicosocial: seguimiento_editar.edad,
        temas_psicosocial: seguimiento_editar.temas,
        avances_psicosocial: seguimiento_editar.avances,
        reportes_psicosocial: seguimiento_editar.reportes,
        tuvo_solucion_psicosocial:
          seguimiento_editar.solucion === "Si" ? "1" : "2",
        observaciones_psicosocial: seguimiento_editar.observaciones,
      });
    }
  }, [seguimiento_editar]);

  //console.log(seguimiento_editar)

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };

  const checkStyle = {
    display: "block",
    marginRight: "0",
  };

  const checkStyle1 = {
    display: "block",
    marginRight: "0",
    marginBottom: "1rem",
    paddingTop: "0.5rem",
  };

  const onFinish = (values) => {
    // console.log(values)
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const onChange = (e) => {
    setDatosBasicos({
      ...datosBasicos,
      [e[0].name]: e[0].value,
    });
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Form
            layout="vertical"
            name="basic"
            initialValues={{
              fecha_atencion_psicosocial: seguimiento_editar
                ? moment(seguimiento_editar.fecha, "YYYY-MM-DD")
                : "",
              remitido_juridico: seguimiento_editar
                ? seguimiento_editar.remitido_juridico === "Si"
                  ? "1"
                  : "2"
                : undefined,
              tipo_poblacion_psicosocial: seguimiento_editar
                ? seguimiento_editar.tipo_poblacion_psicosocial === "Niños"
                  ? "1"
                  : seguimiento_editar.tipo_poblacion_psicosocial ===
                    "Tercera edad"
                  ? "2"
                  : "3"
                : undefined,
              edad_psicosocial: seguimiento_editar
                ? seguimiento_editar.edad
                : "",
              temas_psicosocial: seguimiento_editar
                ? seguimiento_editar.temas
                : "",
              avances_psicosocial: seguimiento_editar
                ? seguimiento_editar.avances
                : "",
              reportes_psicosocial: seguimiento_editar
                ? seguimiento_editar.reportes
                : "",
              tuvo_solucion_psicosocial: seguimiento_editar
                ? seguimiento_editar.solucion === "Si"
                  ? "1"
                  : "2"
                : undefined,
              observaciones_psicosocial: seguimiento_editar
                ? seguimiento_editar.observaciones
                : "",
            }}
            onFieldsChange={onChange}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Card className="borde">
              <div className="titulo">
                <p>Nuevo Seguimiento Psicosocial</p>
              </div>
              <Row gutter={[40, 16]} justify="left">
                <Col lg={12} md={12} xs={24}>
                  <Form.Item
                    label="1. Fecha de la atención"
                    name="fecha_atencion_psicosocial"
                    rules={[
                      {
                        required: true,
                        message: "Este campo es requerido",
                      },
                    ]}
                    value={fecha_atencion_psicosocial}
                  >
                    <DatePicker disabled={true} style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
                <Col lg={12} md={12} xs={24}>
                  <Form.Item
                    label="2. Tipo de población"
                    name="tipo_poblacion_psicosocial"
                    rules={[
                      {
                        required: true,
                        message: "Este campo es requerido",
                      },
                    ]}
                    value={tipo_poblacion_psicosocial}
                  >
                    <Select disabled={true} placeholder="Seleccione">
                      <Select.Option value="1">Niños</Select.Option>
                      <Select.Option value="2">Tercera edad</Select.Option>
                      <Select.Option value="3">Jovenes</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[40, 16]} justify="left">
                <Col lg={12} md={12} xs={24}>
                  <Form.Item
                    label="3. Edad"
                    name="edad_psicosocial"
                    rules={[
                      {
                        required: true,
                        message: "Este campo es requerido",
                      },
                    ]}
                    value={edad_psicosocial}
                  >
                    <InputNumber
                      disabled={true}
                      style={{ width: "100%" }}
                      placeholder="Digite la edad"
                    />
                  </Form.Item>
                </Col>
                <Col lg={12} md={12} xs={24}>
                  <Form.Item
                    label="4. Temas o problemáticas tratadas"
                    name="temas_psicosocial"
                    rules={[
                      {
                        required: true,
                        message: "Este campo es requerido",
                      },
                    ]}
                    value={temas_psicosocial}
                  >
                    <Input.TextArea
                      disabled={true}
                      placeholder="Digite la información"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[40, 16]} justify="left">
                <Col lg={12} md={12} xs={24}>
                  <Form.Item
                    label="5. Avances de las actuaciones realizadas por la red"
                    name="avances_psicosocial"
                    rules={[
                      {
                        required: true,
                        message: "Este campo es requerido",
                      },
                    ]}
                    value={avances_psicosocial}
                  >
                    <Input.TextArea
                      disabled={true}
                      placeholder="Digite la información"
                    />
                  </Form.Item>
                </Col>
                <Col lg={12} md={12} xs={24}>
                  <Form.Item
                    label="6. Reportes de la remisión a instituciones"
                    name="reportes_psicosocial"
                    rules={[
                      {
                        required: true,
                        message: "Este campo es requerido",
                      },
                    ]}
                    value={reportes_psicosocial}
                  >
                    <Input.TextArea
                      disabled={true}
                      placeholder="Digite la información"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[40, 16]} justify="left">
                <Col lg={12} md={12} xs={24}>
                  <Form.Item
                    label="7. ¿Tuvo solución?"
                    name="tuvo_solucion_psicosocial"
                    rules={[
                      {
                        required: true,
                        message: "Este campo es requerido",
                      },
                    ]}
                    value={tuvo_solucion_psicosocial}
                  >
                    <Select disabled={true} placeholder="Seleccione">
                      <Select.Option value="1">Si</Select.Option>
                      <Select.Option value="2">No</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col lg={12} md={12} xs={24}>
                  <Form.Item
                    label="8. Observaciones"
                    name="observaciones_psicosocial"
                    rules={[
                      {
                        required: true,
                        message: "Este campo es requerido",
                      },
                    ]}
                    value={observaciones_psicosocial}
                  >
                    <Input.TextArea
                      disabled={true}
                      placeholder="Digite la información"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Divider />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Form.Item>
                  <Button type="primary" onClick={() => history.goBack()}>
                    Atras
                  </Button>
                </Form.Item>
              </div>
            </Card>
          </Form>
        </Container>
      </div>
    </>
  );
};
