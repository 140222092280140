import React from "react";
import { useForm, useStep } from "react-hooks-helper";
import { DatosPersonales } from "./stepFormVer/DatosPersonales";
import { DatosBasicos } from "./stepFormVer/DatosBasicos";
import { EntornoFamiliar } from "./stepFormVer/EntornoFamiliar";
import { SaludFamiliar } from "./stepFormVer/SaludFamiliar";
import { MorbilidadMenores } from "./stepFormVer/MorbilidadMenores";
import { MorbilidadMayores } from "./stepFormVer/MorbilidadMayores";
import { MorbilidadCronica } from "./stepFormVer/MorbilidadCronica";
import { Mortalidad } from "./stepFormVer/Mortalidad";
import { SaludMental } from "./stepFormVer/SaludMental";
import { Review } from "./stepForm/Review";
import { Asignacion } from "./stepFormVer/Asignacion";
import { Submit } from "./stepFormVer/Submit";
import { Empleabilidad } from "./stepFormVer/Empleabilidad";
import { Juridica } from "./stepFormVer/Juridica";
import { Psicologica } from "./stepFormVer/Psicologica";


const defaultData = {
  firstName: "",
  lastName: "",
  nickName: "",
  genero:'',
  address: "",
  city: "",
  state: "",
  zip: "",
  phone: "",
  email: "",
};

const steps = [ 
 { id: "datosbasicos" },
 { id: "datos_personales" },
 { id: "entorno_familiar" },
 { id: "salud_familiar" },
 { id: "morbilidad_menores" },
 { id: "morbilidad_mayores" },
 { id: "morbilidad_cronica" },
 { id: "mortalidad" },
 { id: "salud_mental" },
 { id: "asesoria_juridica" },
 { id: "asesoria_psicologica" },
 { id: "asesoria_empleabilidad" },
  // { id: "review" },
 { id: "asignacion" },
 { id: "submit" },
];

export const MultiStepFormVer = () => {
  const [formData, setForm] = useForm(defaultData);
  const { step, navigation } = useStep({
    steps,
    initialStep: 0,
  });

  const props = { formData, setForm, navigation };

  switch (step.id) {
    case "datosbasicos":
      return <DatosBasicos {...props} />;
    case "datos_personales":
      return <DatosPersonales {...props} />;  
    case "entorno_familiar":
       return <EntornoFamiliar {...props} />;
    case "salud_familiar":
       return <SaludFamiliar {...props} />;
    case "morbilidad_menores":
       return <MorbilidadMenores {...props} />;
    case "morbilidad_mayores":
       return <MorbilidadMayores {...props} />;
    case "morbilidad_cronica":
       return <MorbilidadCronica {...props} />;
    case "mortalidad":
       return <Mortalidad {...props} />;
    case "salud_mental":
      return <SaludMental {...props} />;
    case "asesoria_juridica":
      return <Juridica {...props} />;
    case "asesoria_psicologica":
      return <Psicologica {...props} />;
     case "asesoria_empleabilidad":
      return <Empleabilidad {...props} />;

    // case "review":
    //   return <Review {...props} />;
    case "asignacion":
        return <Asignacion {...props} />  
    case "submit":
      return <Submit {...props} />;
  }

  return (
    <div>
      <h1>Pagina no encontrada</h1>
    </div>
  );
};
