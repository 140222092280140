import React, { useState, useContext, useEffect } from "react";
import { Row, Col, CardBody, Card, Container } from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import { ContextAutenticacion } from "../../contexts/autenticacion/ContextAutenticacion";
import profile from "../../assets/images/logotipo-oficial-unicartagena.png";
import logo_new from "../../assets/images/logo-incial.jpg";
import logo from "../../assets/images/logo-inicio.png";
import Form from "../../components/UI/Form";
import { message } from "antd";
import classes from "./Login.module.less";

const Login = (props) => {
  const contextAutenticacion = useContext(ContextAutenticacion);
  const { mensaje, autenticado, iniciarSesion, cargando, usuario } =
    contextAutenticacion;

  const [form, setForm] = useState({
    inputs: {
      email: {
        valor: "",
        config: {
          id: "email",
          elemento: "input",
          tipo: "email",
          label: "Correo Electrónico",
          requerido: true,
        },
      },
      password: {
        valor: "",
        config: {
          id: "password",
          elemento: "input",
          tipo: "password",
          label: "Contraseña",
          requerido: true,
        },
      },
    },
    boton: {
      label: "Ingresar",
    },
  });

  useEffect(() => {
    if (autenticado && cargando) {
      //  console.log(autenticado)
      props.history.push("/dashboard");
    }
    if (mensaje) {
      message.error(mensaje.msg);
    }

    // eslint-disable-next-line
  }, [mensaje, autenticado, props.history]);

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div style={{ backgroundColor: "#f6d9a94d" }}>
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4 pt-5">
                        <h5 className={classes.textUdeC}>Bienvenidos!</h5>
                        <p style={{ color: "black" }}>
                          Inicia sesión para continuar.
                        </p>
                      </div>
                    </Col>
                    <Col className="col-5">
                      <img
                        src={profile}
                        alt=""
                        style={{
                          width: "250px",
                          paddingTop: "3em",
                          paddingRight: "8em",
                        }}
                      />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span
                          className="avatar-title"
                          style={{
                            width: "10rem",
                            borderRadius: "50%",
                            background: "#F7FAFF",
                          }}
                        >
                          <img
                            src={logo}
                            alt=""
                            className=""
                            height="70"
                            width="120"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      form={form}
                      setForm={setForm}
                      loading={cargando}
                      onSubmit={iniciarSesion}
                    />
                  </div>
                </CardBody>
              </Card>
              {/* <div className="mt-5 text-center">
               
                <p>
                  Don't have an account ?{' '}
                  <Link
                    to="register"
                    className="font-weight-medium text-primary"
                  >
                    {' '}
                    Signup now{' '}
                  </Link>{' '}
                </p>
                <p>
                  © {new Date().getFullYear()} Skote. Crafted with{' '}
                  <i className="mdi mdi-heart text-danger"></i> by Themesbrand
                </p>
              
              </div>*/}
            </Col>
          </Row>
          <Row>
            <Col span={12} offset={6}>
              <div className="mt-5 text-center">
                <img
                  // src={logo_new}
                  alt=""
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);
