import React, { useState, useEffect } from "react";
import axios from "axios";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Button } from "antd";
import instance from "../../axios/axios-api";

const ExportData = () => {
  const [loading, setLoading] = useState(false);
  const fileName = "RUASIM"; // here enter filename for your excel file
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const estado = "sin_asignar";

  //.get(`/api/caracterizaciones/admin`, { params: "borrador" })

  const exportToCSV = () => {
    setLoading(true);
    instance.get(`/api/caracterizaciones/excel`).then((res) => {
      console.log(res);
      if (res.status === 200) {
        console.log(res);
        let ws = XLSX.utils.json_to_sheet(res.data);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, {
          bookType: "xlsx",
          type: "array",
        });
        const data1 = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data1, fileName + fileExtension);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <Button type="primary" loading={loading} onClick={(e) => exportToCSV()}>
      Exportar a Excel
    </Button>
  );
};

export default ExportData;
