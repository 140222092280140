import React, { useRef, useState } from "react";
import {
  Input,
  InputNumber,
  Radio,
  Checkbox,
  Slider,
  Select,
  Form,
  Col,
  Row,
  Upload,
} from "antd";

import _ from "lodash";

const ComponenteInput =
  /*React.memo(*/
  (props) => {
    const [imagenes, setImagenes] = useState([]);
    let componente = null;

    const onChangeParams = (params) => {
      if (props.subMenu !== null) {
        params.push(props.subMenu);
      }
      return params;
    };

    const refPrefix = useRef(null);
    let prefix = null;
    if (props.config.prefix) {
      prefix = (
        <Form.Item name="prefix" noStyle>
          <Select ref={refPrefix} defaultValue="+57" style={{ width: 100 }}>
            {props.config.prefix.map((elem) => (
              <Select.Option key={elem.value} value={elem.value}>
                <img
                  src={`https://www.countryflags.io/${elem.code}/flat/16.png`}
                  alt="pais"
                />
                ({elem.value})
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      );
    }

    switch (props.config.elemento) {
      case "input":
        let TipoInput = Input;
        const propsInput = {};

        switch (props.config.tipo) {
          case "search":
            TipoInput = Input.Search;
            break;
          case "password":
            TipoInput = Input.Password;
            break;
          case "textarea":
            TipoInput = Input.TextArea;
            break;
          default:
            TipoInput = Input;
        }

        if (prefix) propsInput.addonBefore = prefix;

        componente = (
          <TipoInput
            type={props.config.tipo}
            id={props.config.id}
            placeholder={props.config.placeholder}
            value={props.valor}
            onChange={(evt) => {
              if (prefix) {
                evt.target.value =
                  refPrefix.current.props.defaultValue + evt.target.value;
              }
              props.onChange(...onChangeParams([evt, props.config.id]));
            }}
            {...propsInput}
          />
        );
        break;
      case "inputNumber":
        const propsInputNumber = {};

        if (props.config.min) propsInputNumber.min = props.config.min;
        if (props.config.max) propsInputNumber.max = props.config.max;

        switch (props.config.tipo) {
          case "price":
            propsInputNumber.formatter = (value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            propsInputNumber.parser = (value) =>
              value.replace(/(\$|\s|\.)/g, "");
            break;
          default:
        }

        componente = (
          <InputNumber
            id={props.config.id}
            placeholder={props.config.placeholder}
            value={props.valor}
            onChange={(evt) =>
              props.onChange(...onChangeParams([evt, props.config.id]))
            }
            {...propsInputNumber}
          />
        );
        break;
      case "checkbox":
        if (!props.config.multiple) {
          componente = (
            <Checkbox
              checked={props.valor}
              onChange={(evt) =>
                props.onChange(...onChangeParams([evt, props.config.id]))
              }
              style={props.config.estilo ? props.config.estilo : null}
            >
              {props.config.texto}
            </Checkbox>
          );
        } else {
          if (props.config.opciones.length > 0) {
            let propsCol = { xs: 24 };
            if (props.config.colItems) propsCol = props.config.colItems;
            const opciones = props.config.opciones.map((elem) => (
              <Col key={elem} {...propsCol}>
                <Checkbox value={elem.toLowerCase()}>{elem}</Checkbox>
              </Col>
            ));

            componente = (
              <Checkbox.Group
                value={props.valor}
                onChange={(evt) =>
                  props.onChange(...onChangeParams([evt, props.config.id]))
                }
              >
                <Row>{opciones}</Row>
              </Checkbox.Group>
            );
          } else {
            componente = <p>{props.config.mensaje}</p>;
          }
        }
        break;
      case "radio":
        let estilosRadio = {};
        if (props.config.orientacion === "vertical") {
          estilosRadio = {
            display: "block",
            height: "30px",
            lineHeight: "30px",
          };
        }

        const radios = props.config.opciones.map((opcion, opcionIdx) => {
          const label = typeof opcion === "object" ? opcion.label : opcion;
          const value =
            typeof opcion === "object" ? opcion.value : opcion.toLowerCase();

          return (
            <Radio key={opcionIdx} style={estilosRadio} value={value}>
              {label}
            </Radio>
          );
        });

        componente = (
          <Radio.Group
            onChange={(evt) =>
              props.onChange(...onChangeParams([evt, props.config.id]))
            }
            value={props.valor}
          >
            {radios}
          </Radio.Group>
        );
        break;
      case "select":
        const opciones = props.config.opciones.map((opcion, opcionIdx) => {
          const label = typeof opcion === "object" ? opcion.label : opcion;
          const value =
            typeof opcion === "object" ? opcion.value : opcion.toLowerCase();

          return (
            <Select.Option key={opcionIdx} value={value}>
              {label}
            </Select.Option>
          );
        });

        componente = (
          <Select
            showSearch
            style={{ width: 200, marginTop: 5 }}
            optionFilterProp="children"
            onChange={(evt) =>
              props.onChange(...onChangeParams([evt, props.config.id]))
            }
            value={props.valor}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {opciones}
          </Select>
        );
        break;
      default:
      // console.log(`${props.config.elemento} no encontrado`);
    }

    const rules = [];
    const propsInput = {};

    if (props.config.requerido) {
      if (props.config.elemento === "checkbox" && !props.config.multiple) {
        propsInput.valuePropName = "checked";
        rules.push({
          validator: (_, value) =>
            value ? Promise.resolve() : Promise.reject(props.config.mensaje),
        });
      } else {
        rules.push({
          required: true,
          message: "Campo requerido",
        });
      }

      // if (props.config.elemento === 'select') {
      //   rules.push({
      //     validator: (_, value) =>
      //       value === undefined ||
      //       (value &&
      //         props.config.opciones.find(
      //           (elem) => elem.toLowerCase() === `${value}`
      //         ))
      //         ? Promise.resolve()
      //         : Promise.reject('Seleccione una opción válida'),
      //   });
      // }
    }

    if (props.config.tipo === "email") {
      rules.push({
        type: "email",
        message: "Correo inválido",
      });
    }

    if (props.config.regex) {
      rules.push({
        pattern: props.config.regex,
        message: props.config.mensaje,
      });
    }

    let label = null;
    if (props.label) {
      label = <p className="label">{props.config.label}</p>;
    }

    if (props.config.dependencia) {
      propsInput.dependencies = ["password"];
      rules.push(({ getFieldValue }) => ({
        validator(rule, value) {
          if (!value || getFieldValue("password") === value) {
            return Promise.resolve();
          }
          return Promise.reject("Las constraseñas ingresadas no coinciden!");
        },
      }));
    }

    if (props.form) {
      let propsCol = { xs: 24 };
      if (props.config.col) propsCol = props.config.col;
      componente = (
        <Col {...propsCol}>
          <Form.Item
            name={props.config.id}
            label={props.config.label ? props.config.label : null}
            labelCol={{ span: 24 }}
            colon={false}
            rules={rules}
            {...propsInput}
          >
            {componente}
          </Form.Item>
        </Col>
      );
    } else {
      componente = (
        <div>
          {label}
          {componente}
        </div>
      );
    }

    return componente;
  }; /*,
  (prevProps, nextProps) => {
    return !(
      (prevProps.config.opciones &&
        (prevProps.config.opciones.length !==
          nextProps.config.opciones.length ||
          !prevProps.config.opciones.every(
            (value, index) => value === nextProps.config.opciones[index]
          ))) ||
      !_.isEqual(prevProps.valor, nextProps.valor)
    );
  }
)*/

export default ComponenteInput;
