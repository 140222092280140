import React, { useState, useEffect, useContext } from "react";

import { Container, Row, Col, Card, CardBody, Media } from "reactstrap";

import { Form, Button, Input, Divider } from "antd";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/avatar-1.jpg";

import { ContextAutenticacion } from "../../contexts/autenticacion/ContextAutenticacion";
import { ContextFormulario } from "../../contexts/formulario/ContextFormulario";

const Perfil = () => {
  const contextAutenticacion = useContext(ContextAutenticacion);
  const { usuario, cerrarSesion } = contextAutenticacion;

  const contextFormulario = useContext(ContextFormulario);
  const { cambiarContraseña } = contextFormulario;

  const [datosBasicos, setDatosBasicos] = useState({
    password: "",
    repassword: "",
  });

  const { password, repassword } = datosBasicos;

  const onFinish = (values) => {
    values["userId"] = usuario.id;
    //console.log(values)
    cambiarContraseña(values);
    cerrarSesion();
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const onChange = (e) => {
    setDatosBasicos({
      ...datosBasicos,
      [e[0].name]: e[0].value,
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Media>
                    <div className="mr-3">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <Media body className="align-self-center">
                      <div className="text-muted">
                        <h5>{usuario.username}</h5>
                        <p className="mb-1">{usuario.email}</p>
                      </div>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Card>
            <CardBody>
              <Form
                layout="vertical"
                name="basic"
                onFieldsChange={onChange}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Card className="borde">
                  <div>
                    <p style={{ textAlign: "center", fontSize: "20px" }}>
                      Cambiar contraseña
                    </p>
                  </div>

                  <Row justify="center">
                    <Col sm="12" md={{ size: 6, offset: 3 }}>
                      <Form.Item
                        name="password"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Este campo es requerido",
                          },
                        ]}
                        value={password}
                      >
                        <Input.Password placeholder="Digite un nueva contraseña" />
                      </Form.Item>
                    </Col>
                    <Col sm="12" md={{ size: 6, offset: 3 }}>
                      <Form.Item
                        name="repassword"
                        dependencies={["password"]}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Este campo es requerido",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue("password") === value
                              ) {
                                return Promise.resolve();
                              }

                              return Promise.reject(
                                new Error(
                                  "Las contraseñas ingresadas no coinciden!"
                                )
                              );
                            },
                          }),
                        ]}
                        value={repassword}
                      >
                        <Input.Password placeholder="Digite nuevamente la contraseña" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Form.Item>
                      <Button type="primary" danger htmlType="submit">
                        Cambiar
                      </Button>
                    </Form.Item>
                  </div>
                </Card>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Perfil;
