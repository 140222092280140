import React, { useState, useContext, useEffect } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { ContextAutenticacion } from '../../../contexts/autenticacion/ContextAutenticacion';
import { ContextFormulario } from '../../../contexts/formulario/ContextFormulario';
import { Spin } from 'antd';
import { Link } from "react-router-dom";

// users
import user1 from '../../../assets/images/users/avatar-1.jpg';

const ProfileMenu = (props) => {
  const contextAutenticacion = useContext(ContextAutenticacion);
  const { usuario, cerrarSesion} = contextAutenticacion;
  const contextformulario = useContext(ContextFormulario);
  const { borrarUsuarioSeleccionado } = contextformulario;
  const [menu, setMenu] = useState(false);

const cerrar_sesion = () =>{
    borrarUsuarioSeleccionado();
    cerrarSesion()
  }

  if(!usuario) return <Spin/>
 

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
           <span className="d-none d-xl-inline-block ml-2 mr-1">{usuario.username}</span> 
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </DropdownToggle>
        <DropdownMenu right>
           <Link to="/perfil" >
          <DropdownItem >
            {' '}
            <i className="bx bx-user font-size-16 align-middle mr-1"></i>
            {'Perfil'}
          </DropdownItem>
          </Link>
        {/*   <DropdownItem tag="a" href="/crypto-wallet">
            <i className="bx bx-wallet font-size-16 align-middle mr-1"></i>
            {'My Wallet'}
  </DropdownItem> */}
          {/* <DropdownItem tag="a" href="/perfil">
            {' '}
            <i className="bx bx-cog  font-size-16 align-middle mr-1"></i>
            {'Configuración'}
          </DropdownItem> */}
         {/*  <DropdownItem tag="a" href="auth-lock-screen">
            <i className="bx bx-lock-open font-size-16 align-middle mr-1"></i>
            {'Lock screen'}
          </DropdownItem>*/}
          <div className="dropdown-divider"></div>
          <a onClick={()=>{cerrar_sesion()}} className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
            <span>{'Cerrar Sesión'}</span>
          </a>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileMenu;
