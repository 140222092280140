import React, { useContext, useEffect, useState } from "react";
import { Badge, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { ContextFormulario } from "../../contexts/formulario/ContextFormulario";
import { ContextAutenticacion } from "../../contexts/autenticacion/ContextAutenticacion";
import { Alert } from "antd";
import { useHistory } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap";
import "../../assets/scss/datatables.scss";

import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

import "./styles.css";
import Swal from "sweetalert2";

function rowStyleFormat(row, rowIdx) {
  return { backgroundColor: rowIdx % 2 === 0 ? "white" : "#E1E7F1" };
}

function renderShowsTotal(start, to, total) {
  return (
    <span style={{ color: "gray" }}>
      Mostrando filas {start} de {to}, de {total} &nbsp;&nbsp;
    </span>
  );
}

const options = {
  firstPageText: "Primera",
  prePageText: "Atras",
  nextPageText: "Siguiente",
  lastPageText: "Última",
  nextPageTitle: "Primera Pagina",
  prePageTitle: "Pagina Previa",
  firstPageTitle: "Siguiente Pagina",
  lastPageTitle: "Ultima Pagina",
  showTotal: true,
  paginationShowsTotal: renderShowsTotal,
};

const LatestTranaction = (props) => {
  const history = useHistory();

  const contextformulario = useContext(ContextFormulario);
  const {
    obtenerCaraterizaciones,
    listado,
    usuarioSeleccionado,
    usuario_seleccionado,
    eliminarRegistro,
    borrarUsuarioSeleccionado,
    usuarioSeleccionadoDatosPersonales,
    usuarioSeleccionadoEntornoFamiliar,
    usuarioSeleccionadoSaludFamiliar,
    usuarioSeleccionadoMorbilidad,
    usuarioSeleccionadoSaludMental,
    usuarioSeleccionadoJuridica,
    usuarioSeleccionadoAsignacion,
    usuario_seleccionado_datos_personales,
  } = contextformulario;

  const contextAutenticacion = useContext(ContextAutenticacion);
  const { usuario } = contextAutenticacion;

  useEffect(() => {
    obtenerCaraterizaciones("borrador", usuario.id);
  }, []);
  console.log(listado);
  const array = [];
  for (var i = 0; i < listado.length; i++) {
    array.push({
      id: listado[i].id,
      num: i + 1,
      nombre: listado[i].persona.nombre + " " + listado[i].persona.apellido,
      creado: listado[i].createdAt,
      estado: "Borrador",
      barrio_domicilio: listado[i].barrio_domicilio,
    });
  }

  function downloadFile(json) {
    usuarioSeleccionado(json.id);
    usuarioSeleccionadoDatosPersonales(json.id);
    usuarioSeleccionadoEntornoFamiliar(json.id);
    usuarioSeleccionadoSaludFamiliar(json.id);
    usuarioSeleccionadoMorbilidad(json.id);
    usuarioSeleccionadoSaludMental(json.id);
    usuarioSeleccionadoJuridica(json.id);
    usuarioSeleccionadoAsignacion(json.id);
  }

  useEffect(() => {
    if (
      usuario_seleccionado !== null &&
      usuario_seleccionado_datos_personales !== null
    ) {
      history.push("/formulario");
    }
    // // function downloadFile(json) {
    //  usuarioSeleccionado(json.id);
    //  setTimeout(() => {
    //   history.push("/formulario");
    // }, 1000);
  }, [usuario_seleccionado, usuario_seleccionado_datos_personales]);

  function buttons(row, Index) {
    return (
      <>
        <i
          onClick={() => downloadFile(Index)}
          className="fas fa-edit edit"
          title="Editar"
        ></i>
        <i
          onClick={() => onEliminar(Index)}
          className="fas fa-trash-alt delete"
          title="Eliminar"
        ></i>
      </>
    );
  }

  const redirigir = () => {
    borrarUsuarioSeleccionado();
    history.push("/formulario");
  };

  const onEliminar = (index) => {
    Swal.fire({
      title: "¿Deseas eliminar este registro?",
      text: "Esta acción no se puede deshacer",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar",
      cancelButtonText: "No, Cancelar",
    }).then(async (result) => {
      if (result.value) {
        try {
          eliminarRegistro(index);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
          Swal.fire("Eliminado", "", "success");
        } catch (error) {
          Swal.fire("Error al eliminar", "", "error");
        }
      }
    });
  };

  return (
    <React.Fragment>
      {listado.length > 0 ? (
        <div style={{ paddingTop: "2rem", paddingBottom: "6rem" }}>
          <div className="py-3">
            <Button
              type="button"
              color="success"
              size="sm"
              className="btn-rounded waves-effect waves-light"
              onClick={() => redirigir()}
              style={{ background: "#0f8000a3", color: "white" }}
            >
              Agregar Nuevo
            </Button>
          </div>
          <BootstrapTable
            searchPlaceholder="Búsqueda..."
            wrapperClasses="table-responsive"
            striped
            pagination
            options={options}
            data={array}
            search={true}
            multiColumnSearch={true}
            trStyle={rowStyleFormat}
          >
            <TableHeaderColumn width="60" dataField="num" isKey>
              ID
            </TableHeaderColumn>
            <TableHeaderColumn width="120" dataField="nombre">
              Nombre
            </TableHeaderColumn>
            <TableHeaderColumn width="120" dataField="creado">
              Fecha
            </TableHeaderColumn>
            <TableHeaderColumn width="120" dataField="estado">
              Estado
            </TableHeaderColumn>
            <TableHeaderColumn
              width="100"
              dataField="buttons"
              dataFormat={buttons}
            >
              Acciones
            </TableHeaderColumn>
          </BootstrapTable>
        </div>
      ) : (
        <Alert
          message="Información"
          description="No existen casos en borrador."
          type="info"
          showIcon
        />
      )}
    </React.Fragment>
  );
};

export default LatestTranaction;
