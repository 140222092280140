import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Badge, Container } from "reactstrap";
import { Link } from "react-router-dom";
import { ContextFormulario } from "../../contexts/formulario/ContextFormulario";
import AlertaContext from "../../contexts/alerta/alertaContext";
import { Alert } from "antd";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useHistory } from "react-router-dom";
import { Spin, Button, Tooltip } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

import "../Dashboard/styles.css";
import Swal from "sweetalert2";

function rowStyleFormat(row, rowIdx) {
  return { backgroundColor: rowIdx % 2 === 0 ? "white" : "#E1E7F1" };
}

function renderShowsTotal(start, to, total) {
  return (
    <span style={{ color: "gray" }}>
      Mostrando filas {start} de {to}, de {total} &nbsp;&nbsp;
    </span>
  );
}

const options = {
  firstPageText: "Primera",
  prePageText: "Atras",
  nextPageText: "Siguiente",
  lastPageText: "Última",
  nextPageTitle: "Primera Pagina",
  prePageTitle: "Pagina Previa",
  firstPageTitle: "Siguiente Pagina",
  lastPageTitle: "Ultima Pagina",
  showTotal: true,
  paginationShowsTotal: renderShowsTotal,
};

const Usuarios = () => {
  const history = useHistory();
  const contextformulario = useContext(ContextFormulario);
  const {
    obtenerUsuarios,
    eliminarUsuario,
    usuarios,
    usuarioSeleccionado,
    usuarioSeleccionadoEditar,
    borrarUsuarioSeleccionado,
  } = contextformulario;

  const alertaContext = useContext(AlertaContext);
  const { alerta, mostrarAlerta } = alertaContext;

  useEffect(() => {
    obtenerUsuarios();
  }, []);

  //console.log(usuarios);
  const array = [];
  if (usuarios.length >= 1) {
    for (var i = 0; i < usuarios.length; i++) {
      //console.log(usuarios[i].roles[0]);
      array.push({
        id: usuarios[i].id,
        num: i + 1,
        username: usuarios[i].username,
        email: usuarios[i].email,
        password: usuarios[i].password,
        rol:
          usuarios[i] !== undefined &&
          usuarios.length >= 1 &&
          usuarios[i].roles[0].name === "user"
            ? "Digitador"
            : usuarios[i].roles[0].name === "moderator"
            ? "Asesor"
            : "Administrador",
        roles: usuarios[i].roles[0],
        tipo_asesoria:
          usuarios[i].tipo_asesoria === "1"
            ? "Juridica"
            : usuarios[i].tipo_asesoria === "2"
            ? "Empleabilidad"
            : usuarios[i].tipo_asesoria === "3"
            ? "Psicologica"
            : "N/A",
      });
    }
  }

  const usuarioSeleccion = (data) => {
    usuarioSeleccionadoEditar(data);
    history.push("/nuevo_usuario");
  };

  const redirigir = () => {
    borrarUsuarioSeleccionado();
    history.push("/nuevo_usuario");
  };

  function buttons(row, Index) {
    return (
      <>
        <i
          onClick={() => usuarioSeleccion(Index)}
          className="fas fa-edit edit"
          title="Editar"
        ></i>
        <i
          onClick={() => onEliminar(Index)}
          className="fas fa-trash-alt delete"
          title="Eliminar"
        ></i>
      </>
    );
  }

  const onEliminar = (index) => {
    // console.log(index);
    Swal.fire({
      title: "¿Deseas eliminar este registro?",
      text: "Esta acción no se puede deshacer",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar",
      cancelButtonText: "No, Cancelar",
    }).then(async (result) => {
      if (result.value) {
        try {
          eliminarUsuario(index);
          Swal.fire("Eliminado", "", "success");
        } catch (error) {
          Swal.fire("Error al eliminar", "", "error");
        }
      }
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            titulo="Gestión de usuarios"
            breadcrumb={{
              actual: "Gestión de usuarios",
              linkActual: "#",
              anterior: "Gestión de usuarios",
              linkAnterior: "#",
            }}
          />
          <div className="py-3">
            <Button
              type="button"
              color="success"
              size="sm"
              className="btn-rounded waves-effect waves-light"
              onClick={() => redirigir()}
              style={{ background: "#0f8000a3", color: "white" }}
            >
              Agregar usuario
            </Button>
          </div>
          {usuarios && usuarios.length > 0 ? (
            <>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Listado de Usuarios</CardTitle>

                  <BootstrapTable
                    searchPlaceholder="Búsqueda..."
                    wrapperClasses="table-responsive"
                    striped
                    pagination
                    options={options}
                    data={array}
                    search={true}
                    multiColumnSearch={true}
                    trStyle={rowStyleFormat}
                  >
                    <TableHeaderColumn width="60" dataField="num" isKey>
                      ID
                    </TableHeaderColumn>
                    <TableHeaderColumn width="120" dataField="username">
                      Username
                    </TableHeaderColumn>
                    <TableHeaderColumn width="120" dataField="email">
                      Correo
                    </TableHeaderColumn>
                    <TableHeaderColumn width="120" dataField="rol">
                      Tipo
                    </TableHeaderColumn>
                    <TableHeaderColumn width="120" dataField="tipo_asesoria">
                      Tipo de Asesoria
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      width="100"
                      dataField="buttons"
                      dataFormat={buttons}
                    >
                      Acciones
                    </TableHeaderColumn>
                  </BootstrapTable>
                </CardBody>
              </Card>
            </>
          ) : (
            <Alert
              message="Información"
              description="No existen casos asignados "
              type="info"
              showIcon
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Usuarios;
