import React, { useState, useContext, useEffect } from "react";
import { ContextFormulario } from "../../contexts/formulario/ContextFormulario";
import {
  Form,
  Input,
  Button,
  Radio,
  Row,
  Col,
  Card,
  Select,
  InputNumber,
  Checkbox,
  Space,
  Divider,
} from "antd";
import "./styles.css";
import { ContextAutenticacion } from "../../contexts/autenticacion/ContextAutenticacion";
import { Alert } from "antd";
import instance from "../../axios/axios-api";

export const MorbilidadMenores = ({ navigation }) => {
  const [bandera, setBandera] = useState(false);
  const [cambio, setCambio] = useState(false);
  const [error, setError] = useState(false);
  const [loadingGuardar, setLoadingGuardar] = useState(false);
  const [loadingAtras, setLoadingAtras] = useState(false);
  const [errorConexion, setErrorConexion] = useState(false);

  const [datosBasicos, setDatosBasicos] = useState({
    enfermedades: "",
    enfermedad_otra: "",
    tratamiento: "",
    tratamiento_otro: "",
    sin_ayuda: "",
    sin_ayuda_otro: "",
  });

  const contextformulario = useContext(ContextFormulario);
  const {
    actualizarMorbilidadMenor,
    usuario_seleccionado,
    formulario_basico,
    usuarioSeleccionado,
    usuarioSeleccionadoSaludFamiliar,
  } = contextformulario;
  const contextAutenticacion = useContext(ContextAutenticacion);
  const { usuario } = contextAutenticacion;
  const [data_formulario, setDataFormulario] = useState("");

  const {
    enfermedades,
    enfermedad_otra,
    tratamiento,
    tratamiento_otro,
    sin_ayuda,
    sin_ayuda_otro,
  } = datosBasicos;

  useEffect(() => {
    if (usuario_seleccionado !== null) {
      setDatosBasicos({
        enfermedades: usuario_seleccionado.enfermedades,
        enfermedad_otra: usuario_seleccionado.enfermedad_otra,
        tratamiento: usuario_seleccionado.tratamiento,
        tratamiento_otro: usuario_seleccionado.tratamiento_otro,
        sin_ayuda: usuario_seleccionado.sin_ayuda,
        sin_ayuda_otro: usuario_seleccionado.sin_ayuda_otro,
      });
      setCambio(false);
    }
  }, [usuario_seleccionado]);

  useEffect(() => {
    if (formulario_basico.length > 0) {
      setDataFormulario(formulario_basico[0]);
    }
  }, [formulario_basico]);

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };

  const checkStyle = {
    display: "block",
    marginRight: "0",
  };

  const checkStyle1 = {
    display: "block",
    marginRight: "0",
    marginBottom: "1rem",
    paddingTop: "0.5rem",
  };

  const onFinish = (values) => {
    if (usuario_seleccionado !== null) {
      values["id"] = usuario_seleccionado.id;
      // console.log("Success:", values);
      if (cambio === true) {
        //actualizarMorbilidadMenor(values);
        //navigation.next();
        setLoadingGuardar(true);
        instance
          .put("/api/caracterizaciones/update_morbilidad_menores", values)
          .then((res) => {
            if (res.status === 200) {
              setLoadingGuardar(false);
              navigation.next();
              //console.log(res);
            } else {
              setLoadingGuardar(false);
              setErrorConexion(true);
            }
          })
          .catch(function (error) {
            setLoadingGuardar(false);
            setErrorConexion(true);
          });
      } else {
        navigation.next();
      }
    } else {
      values["id"] = data_formulario;
      // actualizarMorbilidadMenor(values);
      // navigation.next();
      setLoadingGuardar(true);
      instance
        .put("/api/caracterizaciones/update_morbilidad_menores", values)
        .then((res) => {
          if (res.status === 200) {
            setLoadingGuardar(false);
            navigation.next();
            //console.log(res);
          } else {
            setLoadingGuardar(false);
            setErrorConexion(true);
          }
        })
        .catch(function (error) {
          setLoadingGuardar(false);
          setErrorConexion(true);
        });
    }
  };

  const onFinishFailed = (errorInfo) => {
    //console.log("Failed:", errorInfo);
    setError(true);
  };

  const goBack = async () => {
    if (usuario_seleccionado !== null) {
      let id = usuario_seleccionado.id;
      setLoadingAtras(true);
      usuarioSeleccionado(id);
      usuarioSeleccionadoSaludFamiliar(id);
      setTimeout(() => {
        navigation.previous();
        setLoadingAtras(false);
      }, 3000);
    } else {
      //console.log("Va de corrido");
      let id = data_formulario;
      setLoadingAtras(true);
      usuarioSeleccionado(id);
      usuarioSeleccionadoSaludFamiliar(id);
      setTimeout(() => {
        navigation.previous();
        setLoadingAtras(false);
      }, 3000);
    }
  };

  const onChange = (e) => {
    setDatosBasicos({
      ...datosBasicos,
      [e[0].name]: e[0].value,
    });
    setBandera(true);
    if (bandera) {
      setCambio(true);
    } else {
      setCambio(false);
    }
  };

  return (
    <>
      <Form
        layout="vertical"
        name="basic"
        initialValues={{
          enfermedades: usuario_seleccionado
            ? usuario_seleccionado.enfermedades
            : "",
          enfermedad_otra: usuario_seleccionado
            ? usuario_seleccionado.enfermedad_otra
            : "",
          tratamiento: usuario_seleccionado
            ? usuario_seleccionado.tratamiento
            : "",
          tratamiento_otro: usuario_seleccionado
            ? usuario_seleccionado.tratamiento_otro
            : "",
          sin_ayuda: usuario_seleccionado ? usuario_seleccionado.sin_ayuda : "",
          sin_ayuda_otro: usuario_seleccionado
            ? usuario_seleccionado.sin_ayuda_otro
            : "",
        }}
        onFieldsChange={onChange}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Card className="borde">
          <div className="titulo">
            <p>1.5.1 Morbilidad sentida en menores de 5 años</p>
          </div>
          <Row gutter={[40, 16]} justify="left">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="63. Conteste las siguientes preguntas si él o (los) menor(es) ha(n) presentado en el último mes estas enfermedades"
                name="enfermedades"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={enfermedades}
              >
                <Select placeholder="Seleccione">
                  <Select.Option value="1">
                    Accidente casero, familiar o escolar
                  </Select.Option>
                  <Select.Option value="2">
                    Diarrea o malestar en el estómago
                  </Select.Option>
                  <Select.Option value="3">Alergias</Select.Option>
                  <Select.Option value="4">
                    Gripe, tos, resfriado, pulmonía o bronquitis
                  </Select.Option>
                  <Select.Option value="5">Covid-19</Select.Option>
                  <Select.Option value="6">
                    Alguna otra enfermedad
                  </Select.Option>
                  <Select.Option value="7">No aplica</Select.Option>
                </Select>
              </Form.Item>
              {enfermedades === "6" ? (
                <Form.Item
                  style={{ paddingTop: "1rem" }}
                  label="Especifique"
                  name="enfermedad_otra"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                  value={enfermedad_otra}
                >
                  <Input placeholder="Digite" />
                </Form.Item>
              ) : null}
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="64. ¿Dónde buscó ayuda o tratamiento?"
                name="tratamiento"
                rules={[
                  {
                    required: enfermedades === "7" ? false : true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={tratamiento}
              >
                <Select
                  disabled={enfermedades === "7" ? true : false}
                  placeholder="Seleccione"
                >
                  <Select.Option value="1">No buscó ayuda</Select.Option>
                  <Select.Option value="2">Hospital público</Select.Option>
                  <Select.Option value="3">Farmacia</Select.Option>
                  <Select.Option value="4">Otro</Select.Option>
                </Select>
              </Form.Item>

              {tratamiento === "4" ? (
                <Form.Item
                  style={{ paddingTop: "1rem" }}
                  label="¿Donde?"
                  name="tratamiento_otro"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                  value={tratamiento_otro}
                >
                  <Input placeholder="Digite" />
                </Form.Item>
              ) : null}
            </Col>
          </Row>
          <Divider />
          <Row gutter={[40, 16]} justify="left">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="65. En caso de no buscar ayuda ¿Cuál fue la razón de no haberlo hecho?"
                name="sin_ayuda"
                rules={[
                  {
                    required: enfermedades === "7" ? false : false,
                    message: "Este campo es requerido",
                  },
                ]}
                value={sin_ayuda}
              >
                <Select
                  disabled={enfermedades === "7" ? true : false}
                  placeholder="Seleccione"
                >
                  <Select.Option value="1">No sabía dónde ir</Select.Option>
                  <Select.Option value="2">Queda lejos</Select.Option>
                  <Select.Option value="3">
                    No tiene seguro medico
                  </Select.Option>
                  <Select.Option value="4">
                    No lo considero necesario
                  </Select.Option>
                  <Select.Option value="5">
                    Pensó que no estaba enfermo
                  </Select.Option>
                  <Select.Option value="6">
                    Prestan un mal servicio
                  </Select.Option>
                  <Select.Option value="7">Otro</Select.Option>
                </Select>
              </Form.Item>

              {sin_ayuda === "7" ? (
                <Form.Item
                  style={{ paddingTop: "1rem" }}
                  label="Razón"
                  name="sin_ayuda_otro"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                  value={sin_ayuda_otro}
                >
                  <Input placeholder="Digite" />
                </Form.Item>
              ) : null}
            </Col>
            <Col lg={12} md={12} xs={24}></Col>
          </Row>

          <Divider />
          {errorConexion === true ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Alert
                style={{ width: "50%" }}
                message="Los datos no fueron guardados, por favor revisa tu conexión a internet."
                type="error"
                showIcon
              />
            </div>
          ) : null}

          {error === true ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Alert
                style={{ width: "50%" }}
                message="Por favor verificar los datos ingresados."
                type="error"
                showIcon
              />
            </div>
          ) : null}

          {cambio === true && error === false && errorConexion === false ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Alert
                style={{ width: "50%" }}
                message="Se guardaran los cambios efectuados."
                type="info"
                showIcon
              />
            </div>
          ) : null}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "2rem",
            }}
          >
            <Form.Item>
              <Button
                style={{ marginRight: "2rem" }}
                type="primary"
                loading={loadingAtras}
                onClick={() => goBack()}
              >
                Atras
              </Button>
              <Button type="primary" htmlType="submit" loading={loadingGuardar}>
                Siguiente
              </Button>
            </Form.Item>
          </div>
        </Card>
      </Form>
    </>
  );
};
