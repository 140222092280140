import React from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container } from "reactstrap";
import ExportData from "../ExportData/ExportData";

const InicioAdmin = () => {
  return (
    <>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            titulo={"Inicio Admin"}
            breadcrumb={{
              actual: "Inicio",
              linkActual: "#",
              anterior: "Inicio",
              linkAnterior: "#",
            }}
          />
          Bienvenidos a Inicio
        </Container>
        <div style={{ paddingLeft: "0.7rem", paddingTop: "1rem" }}>
          A continuación podras descargar un reporte completo de toda la
          actividad realizada.
        </div>
        <div style={{ paddingTop: "2rem", paddingLeft: "0.7rem" }}>
          <ExportData />
        </div>
      </div>
    </>
  );
};

export default InicioAdmin;
