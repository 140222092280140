import React, { useContext, useEffect, useState } from "react";
import { Container } from "reactstrap";

import { ContextFormulario } from "../../contexts/formulario/ContextFormulario";
import { ContextAutenticacion } from "../../contexts/autenticacion/ContextAutenticacion";
import { Alert } from "antd";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useHistory } from "react-router-dom";
import { DeleteOutlined, UserAddOutlined } from "@ant-design/icons";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Form, Button, Row, Col, Select, Divider, Tooltip, Spin } from "antd";
import "./styles.css";
import Swal from "sweetalert2";
import { SettingsRemoteOutlined } from "@material-ui/icons";

function rowStyleFormat(row, rowIdx) {
  return { backgroundColor: rowIdx % 2 === 0 ? "white" : "#E1E7F1" };
}

function renderShowsTotal(start, to, total) {
  return (
    <span style={{ color: "gray" }}>
      Mostrando filas {start} de {to}, de {total} &nbsp;&nbsp;
    </span>
  );
}

const options = {
  firstPageText: "Primera",
  prePageText: "Atras",
  nextPageText: "Siguiente",
  lastPageText: "Última",
  nextPageTitle: "Primera Pagina",
  prePageTitle: "Pagina Previa",
  firstPageTitle: "Siguiente Pagina",
  lastPageTitle: "Ultima Pagina",
  showTotal: true,
  paginationShowsTotal: renderShowsTotal,
};

const SeguimientoEmpleabilidadVer = () => {
  const history = useHistory();

  const contextformulario = useContext(ContextFormulario);
  const {
    caraterizaciones,
    borrarUsuarioSeleccionado,
    obtenerSeguimientosEmpleabilidad,
    seguimientos_juridica,
    seguimientoSeleccionadoEditar,
    eliminarSeguimientoEmpleabilidad,
    obtenerCaraterizacionesAsesor,
    seguimiento_seleccionado,
  } = contextformulario;

  const contextAutenticacion = useContext(ContextAutenticacion);
  const { usuario } = contextAutenticacion;

  useEffect(() => {
    if (caraterizaciones === undefined || caraterizaciones === null) {
      obtenerCaraterizacionesAsesor("asignada", 2);
    }
  }, [caraterizaciones]);

  useEffect(() => {
    if (seguimiento_seleccionado !== null) {
      obtenerSeguimientosEmpleabilidad("borrador", seguimiento_seleccionado.id);
    }
  }, [seguimiento_seleccionado]);

  //console.log(seguimientos_juridica)

  function downloadFile(json) {
    seguimientoSeleccionadoEditar(json);
    history.push("/nuevo_seguimiento_empleabilidad");
  }

  function downloadFile1(json) {
    Swal.fire({
      title: "¿Deseas eliminar este registro?",
      text: "Esta acción no se puede deshacer",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar",
      cancelButtonText: "No, Cancelar",
    }).then(async (result) => {
      //console.log(result.value)
      if (result.value) {
        try {
          eliminarSeguimientoEmpleabilidad(json);

          Swal.fire("Eliminado", "", "success");
        } catch (error) {
          Swal.fire("Error al eliminar", "", "error");
        }
      }
    });
  }

  function downloadFile2(json) {
    seguimientoSeleccionadoEditar(json);
    history.push("/ver_seguimiento_empleabilidad");
  }

  function buttons(row, Index) {
    return (
      <>
        <i
          onClick={() => downloadFile2(Index)}
          className="fas fa-eye see"
          title="Ver seguimiento"
        ></i>
      </>
    );
  }

  const array = [];
  //console.log(caraterizaciones)
  for (var i = 0; i < seguimientos_juridica.length; i++) {
    let genero;
    let solucion;
    if (seguimientos_juridica[i].genero_empleabilidad === 1) {
      genero = "Hombre";
    } else if (seguimientos_juridica[i].genero_empleabilidad === 2) {
      genero = "Mujer";
    } else if (seguimientos_juridica[i].genero_empleabilidad === 3) {
      genero = "Hombre Transgenero";
    } else if (seguimientos_juridica[i].genero_empleabilidad === 4) {
      genero = "Mujer Transgenero";
    } else {
      genero = "Intersexual";
    }

    if (seguimientos_juridica[i].tuvo_solucion_empleabilidad === 1) {
      solucion = "Si";
    } else {
      solucion = "No";
    }

    array.push({
      id: seguimientos_juridica[i].id,
      fecha: seguimientos_juridica[i].fecha_atencion_empleabilidad,
      descripcion: seguimientos_juridica[i].descripcion_empleabilidad,
      edad: seguimientos_juridica[i].edad_empleabilidad,
      genero: genero,
      genero_fix: seguimientos_juridica[i].genero_empleabilidad,
      nombre_empleabilidad: seguimientos_juridica[i].nombre_empleabilidad,
      observaciones_empleabilidad:
        seguimientos_juridica[i].observaciones_empleabilidad,
      responsable_empleabilidad:
        seguimientos_juridica[i].responsable_empleabilidad,
      tipo_empleabilidad: seguimientos_juridica[i].tipo_empleabilidad,
      tuvo_solucion_empleabilidad: solucion,
    });
  }

  const redirigir = () => {
    borrarUsuarioSeleccionado();
    history.push("/nuevo_seguimiento_empleabilidad");
  };

  const redirigir1 = () => {
    borrarUsuarioSeleccionado();
    history.push("/asesor");
  };

  //console.log(array)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            titulo="Gestión de seguimiento Empleabilidad"
            breadcrumb={{
              actual: "Gestión de seguimiento",
              linkActual: "#",
              anterior: "Gestión de seguimiento",
              linkAnterior: "#",
            }}
          />
          <div className="py-3">
            <Button
              type="button"
              color="success"
              size="sm"
              className="btn-rounded waves-effect waves-light"
              onClick={() => redirigir1()}
              style={{ background: "#0f8000a3", color: "white" }}
            >
              Atras
            </Button>
          </div>
          {seguimientos_juridica && seguimientos_juridica.length > 0 ? (
            <>
              <div style={{ paddingTop: "2rem", paddingBottom: "2rem" }}>
                <BootstrapTable
                  searchPlaceholder="Búsqueda..."
                  wrapperClasses="table-responsive"
                  striped
                  pagination
                  options={options}
                  data={array}
                  search={true}
                  multiColumnSearch={true}
                  trStyle={rowStyleFormat}
                >
                  <TableHeaderColumn width="60" dataField="id" isKey>
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn width="200" dataField="fecha">
                    Fecha de la atención
                  </TableHeaderColumn>
                  <TableHeaderColumn width="200" dataField="genero">
                    Género
                  </TableHeaderColumn>
                  <TableHeaderColumn width="200" dataField="edad">
                    Edad
                  </TableHeaderColumn>
                  <TableHeaderColumn width="200" dataField="tipo_empleabilidad">
                    Tipo de formación (curso-carreras)
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    width="250"
                    dataField="nombre_empleabilidad"
                  >
                    Nombre del curso o carrera
                  </TableHeaderColumn>
                  <TableHeaderColumn width="200" dataField="descripcion">
                    Descripción del proceso adelantado
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    width="250"
                    dataField="responsable_empleabilidad"
                  >
                    Responsable del caso (código de usuario)
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    width="250"
                    dataField="tuvo_solucion_empleabilidad"
                  >
                    ¿Tuvo solucion?
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    width="250"
                    dataField="observaciones_empleabilidad"
                  >
                    Observaciones
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    width="150"
                    dataField="buttons"
                    dataFormat={buttons}
                  >
                    Acciones
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            </>
          ) : (
            <Alert
              message="Información"
              description="No existen seguimientos para este caso"
              type="info"
              showIcon
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SeguimientoEmpleabilidadVer;
