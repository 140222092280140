import React from "react";
import { Redirect } from "react-router-dom";
import Dashboard from "../pages/Dashboard/index";
import Login from "../pages/Autenticacion/Login";
import Formulario from "../pages/Formulario/Formulario";
import FormularioVer from "../pages/Formulario/FormularioVer";
import TableAdmin from "../pages/TableAdmin/TableAdmin";
import NuevoUsuario from "../pages/NuevoUsuario/NuevoUsuario";
import Usuarios from "../pages/Usuarios/Usuarios";
import TableAdminBorrador from "../pages/TableAdmin/TableAdminBorrador";
import TableAdminAsignacion from "../pages/TableAdmin/TableAdminAsignacion";
import TableAdminAsignadas from "../pages/TableAdmin/TableAdminAsignadas";
import TableAdminCerrado from "../pages/TableAdmin/TableAdminCerrado";
import TableAsesor from "../pages/TableAsesor/TableAsesor";
import InicioAdmin from "../pages/InicioAdmin/InicioAdmin";
import InicioAsesor from "../pages/InicioAsesor/InicioAsesor";
import SinAsignar from "../pages/SinAsignar/SinAsignar";
import Asignados from "../pages/Asignados/Asignados";
import Seguimiento from "../pages/Seguimiento/Seguimiento";
import SeguimientoVer from "../pages/Seguimiento/SeguimientoVer";
import SeguimientoVerAdmin from "../pages/Seguimiento/SeguimientoVerAdmin";
import SeguimientoJuridicoVerAdmin from "../pages/Seguimiento/SeguimientoJuridicoVerAdmin";
import SeguimientoPsicosocialVerAdmin from "../pages/Seguimiento/SeguimientoPsicosocialVerAdmin";
import SeguimientoEmpleabilidadVerAdmin from "../pages/Seguimiento/SeguimientoEmpleabilidadVerAdmin";

import { NuevoJuridico } from "../pages/Seguimiento/NuevoJuridico";
import { VerJuridico } from "../pages/Seguimiento/VerJuridico";
import { NuevoEmpleabilidad } from "../pages/Seguimiento/NuevoEmpleabilidad";
import { VerEmpleabilidad } from "../pages/Seguimiento/VerEmpleabilidad";
import { NuevoPsicosocial } from "../pages/Seguimiento/NuevoPsicosocial";
import { VerPsicosocial } from "../pages/Seguimiento/VerPsicosocial";
import TableAsesorClose from "../pages/TableAsesor/TableAsesorClose";

import Perfil from "../pages/Perfil/Perfil";

const userRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/formulario", component: Formulario },
  { path: "/formulario-ver", component: FormularioVer },
  { path: "/sin-asignar", component: SinAsignar },
  { path: "/asignados", component: Asignados },
  { path: "/perfil", component: Perfil },
  { path: "/", component: () => <Redirect to="/dashboard" /> },
];

const authRoutes = [
  { path: "/login", component: Login },
  { path: "/", component: () => <Redirect to="/login" /> },
];

const adminRoutes = [
  { path: "/formulario-ver", component: FormularioVer },
  {
    path: "/seguimiento_admin_juridico",
    component: SeguimientoJuridicoVerAdmin,
  },
  {
    path: "/seguimiento_admin_empleabilidad",
    component: SeguimientoEmpleabilidadVerAdmin,
  },
  {
    path: "/seguimiento_admin_psicosocial",
    component: SeguimientoPsicosocialVerAdmin,
  },
  { path: "/seguimiento_admin_cerado", component: TableAdminCerrado },
  { path: "/ver_seguimiento_juridico", component: VerJuridico },
  { path: "/ver_seguimiento_empleabilidad", component: VerEmpleabilidad },
  { path: "/ver_seguimiento_psicosocial", component: VerPsicosocial },
  { path: "/formulario", component: Formulario },
  { path: "/borrador_admin", component: TableAdminBorrador },
  { path: "/asignacion_admin", component: TableAdminAsignacion },
  { path: "/asignadas_admin", component: TableAdminAsignadas },
  { path: "/nuevo_usuario", component: NuevoUsuario },
  { path: "/usuarios", component: Usuarios },
  { path: "/inicio", component: InicioAdmin },
  { path: "/perfil", component: Perfil },
  { path: "/", component: () => <Redirect to="/inicio" /> },
];

const asesorRoutes = [
  { path: "/asesor_cerrado", component: TableAsesorClose },
  { path: "/asesor", component: TableAsesor },
  { path: "/formulario-ver", component: FormularioVer },
  { path: "/seguimiento", component: Seguimiento },
  { path: "/seguimiento_ver", component: SeguimientoVer },
  { path: "/nuevo_seguimiento_juridico", component: NuevoJuridico },
  { path: "/ver_seguimiento_juridico", component: VerJuridico },
  { path: "/nuevo_seguimiento_empleabilidad", component: NuevoEmpleabilidad },
  { path: "/ver_seguimiento_empleabilidad", component: VerEmpleabilidad },
  { path: "/nuevo_seguimiento_psicosocial", component: NuevoPsicosocial },
  { path: "/ver_seguimiento_psicosocial", component: VerPsicosocial },
  { path: "/inicio", component: InicioAsesor },
  { path: "/perfil", component: Perfil },
  { path: "/", component: () => <Redirect to="/inicio" /> },
];

export { userRoutes, authRoutes, adminRoutes, asesorRoutes };
