import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Badge, Container } from "reactstrap";
import { Link } from "react-router-dom";
import { ContextFormulario } from "../../contexts/formulario/ContextFormulario";
import AlertaContext from "../../contexts/alerta/alertaContext";
import { Alert } from "antd";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useHistory } from "react-router-dom";

import { DeleteOutlined, UserAddOutlined } from "@ant-design/icons";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

import { Form, Button, Row, Col, Select, Divider, Tooltip, Spin } from "antd";
import "./styles.css";

function rowStyleFormat(row, rowIdx) {
  return { backgroundColor: rowIdx % 2 === 0 ? "white" : "#E1E7F1" };
}

function renderShowsTotal(start, to, total) {
  return (
    <span style={{ color: "gray" }}>
      Mostrando filas {start} de {to}, de {total} &nbsp;&nbsp;
    </span>
  );
}

const options = {
  firstPageText: "Primera",
  prePageText: "Atras",
  nextPageText: "Siguiente",
  lastPageText: "Última",
  nextPageTitle: "Primera Pagina",
  prePageTitle: "Pagina Previa",
  firstPageTitle: "Siguiente Pagina",
  lastPageTitle: "Ultima Pagina",
  showTotal: true,
  paginationShowsTotal: renderShowsTotal,
};

const TableAdminAsignacion = () => {
  const [flag, setflag] = useState(null);
  const history = useHistory();
  const [datosBasicos, setDatosBasicos] = useState({
    asignacion_empleabilidad: undefined,
    asignacion_juridica: undefined,
    asignacion_psicologica: undefined,
  });

  const {
    asignacion_empleabilidad,
    asignacion_juridica,
    asignacion_psicologica,
  } = datosBasicos;
  const contextformulario = useContext(ContextFormulario);
  const {
    obtenerCaraterizacionesAdmin,
    caraterizaciones,
    usuario_seleccionado,
    asignarCaso,
    obtenerAsesores,
    asesores,
    asignarAsesor,
    borrarUsuarioSeleccionado,
    usuarioSeleccionado,
    usuario_seleccionado1,
    usuarioSeleccionadoDatosPersonales,
    usuarioSeleccionadoEntornoFamiliar,
    usuarioSeleccionadoSaludFamiliar,
    usuarioSeleccionadoMorbilidad,
    usuarioSeleccionadoSaludMental,
    usuarioSeleccionadoJuridica,
    usuarioSeleccionadoAsignacion,
    AsesoresAsignados,
    usuario_seleccionado_salud_mental,
  } = contextformulario;

  const alertaContext = useContext(AlertaContext);
  const { alerta, mostrarAlerta } = alertaContext;

  useEffect(() => {
    obtenerCaraterizacionesAdmin("borrador");
    obtenerAsesores();
  }, []);

  //console.log(caraterizaciones);

  function downloadFile1(json) {
    //console.log(json)
    usuarioSeleccionado(json.id);
    usuarioSeleccionadoDatosPersonales(json.id);
    usuarioSeleccionadoEntornoFamiliar(json.id);
    usuarioSeleccionadoSaludFamiliar(json.id);
    usuarioSeleccionadoMorbilidad(json.id);
    usuarioSeleccionadoSaludMental(json.id);
    usuarioSeleccionadoJuridica(json.id);
    usuarioSeleccionadoAsignacion(json.id);
    AsesoresAsignados(json.id);
  }

  function downloadFile2(json) {
    usuarioSeleccionado(json.id);
    usuarioSeleccionadoDatosPersonales(json.id);
    usuarioSeleccionadoEntornoFamiliar(json.id);
    usuarioSeleccionadoSaludFamiliar(json.id);
    usuarioSeleccionadoMorbilidad(json.id);
    usuarioSeleccionadoSaludMental(json.id);
    usuarioSeleccionadoJuridica(json.id);
    usuarioSeleccionadoAsignacion(json.id);
    AsesoresAsignados(json.id);
    setflag(true);
  }

  useEffect(() => {
    if (usuario_seleccionado !== null) {
      history.push("/formulario-ver");
    }
  }, [usuario_seleccionado]);

  useEffect(() => {
    if (usuario_seleccionado !== null && flag === true) {
      history.push("/formulario");
    }
  }, [usuario_seleccionado]);

  function buttons(row, Index) {
    return (
      <>
        <i
          onClick={() => downloadFile1(Index)}
          className="far fa-eye see"
          title="Visualizar Caracterizacion"
        ></i>
        <i
          onClick={() => downloadFile2(Index)}
          className="fas fa-edit edit"
          title="Editar"
        ></i>
      </>
    );
  }

  const array = [];
  //console.log(caraterizaciones);
  if (caraterizaciones.length > 0) {
    for (var i = 0; i < caraterizaciones.length; i++) {
      var tipo = "";
      if (caraterizaciones[i].tipo_asesoria == 1) {
        tipo = "Asesoria Juridica";
      } else if (caraterizaciones[i].tipo_asesoria == 3) {
        tipo = "Atención Psicosocial";
      } else {
        tipo = "Empleabilidad";
      }
      array.push({
        id: caraterizaciones[i].id,
        num: i + 1,
        nombre:
          caraterizaciones[i].persona.nombre +
          " " +
          caraterizaciones[i].persona.apellido,
        creado: caraterizaciones[i].createdAt,
        estado: "Borrador",
        tipo_asesoria: tipo,
        digitador: caraterizaciones[i].digitador.username,
      });
    }
  }

  //console.log(array);

  const onFinish = (values) => {
    // console.log(values);
    var obj = values;
    var array = [];
    var arrayFinal = [];

    var objFinala = {};
    var objFinalb = {};
    var objFinalc = {};

    if (obj["asignacion_juridica"]) {
      objFinala["asesor"] = obj.asignacion_juridica.toString();
      objFinala["id"] = usuario_seleccionado1.id.toString();
      objFinala["tipo"] = "1";
      array.push(objFinala);
    }

    if (obj["asignacion_psicologica"]) {
      objFinalb["asesor"] = obj.asignacion_psicologica.toString();
      objFinalb["id"] = usuario_seleccionado1.id.toString();
      objFinalb["tipo"] = "2";
      array.push(objFinalb);
    }

    if (obj["asignacion_empleabilidad"]) {
      objFinalc["asesor"] = obj.asignacion_empleabilidad.toString();
      objFinalc["id"] = usuario_seleccionado1.id.toString();
      objFinalc["tipo"] = "3";
      array.push(objFinalc);
    }

    arrayFinal.push({
      asignaciones: array,
      id: usuario_seleccionado1.id,
    });

    asignarAsesor(arrayFinal);
    borrarUsuarioSeleccionado();
    //navigation.next();
  };

  const onFinishFailed = (errorInfo) => {
    //console.log("Failed:", errorInfo);
  };

  const onChange = (e) => {
    setDatosBasicos({
      ...datosBasicos,
      [e[0].name]: e[0].value,
    });
  };

  //console.log(usuario_seleccionado1);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            titulo="Gestión de casos Sin Asignar"
            breadcrumb={{
              actual: "Gestión de casos",
              linkActual: "#",
              anterior: "Gestión de casos",
              linkAnterior: "#",
            }}
          />
          {caraterizaciones && caraterizaciones.length > 0 ? (
            <>
              <div style={{ paddingTop: "2rem", paddingBottom: "2rem" }}>
                <BootstrapTable
                  searchPlaceholder="Búsqueda..."
                  wrapperClasses="table-responsive"
                  striped
                  pagination
                  options={options}
                  data={array}
                  search={true}
                  multiColumnSearch={true}
                  trStyle={rowStyleFormat}
                >
                  <TableHeaderColumn width="60" dataField="num" isKey>
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn width="120" dataField="nombre">
                    Nombre
                  </TableHeaderColumn>
                  <TableHeaderColumn width="120" dataField="creado">
                    Fecha
                  </TableHeaderColumn>

                  <TableHeaderColumn width="120" dataField="estado">
                    Estado
                  </TableHeaderColumn>
                  <TableHeaderColumn width="120" dataField="digitador">
                    Digitador
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    width="100"
                    dataField="buttons"
                    dataFormat={buttons}
                  >
                    Acciones
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            </>
          ) : (
            <Alert
              message="Información"
              description="No existen casos sin asignar "
              type="info"
              showIcon
            />
          )}
          {/* {usuario_seleccionado1 === null ? (
            <Alert
              message="Información"
              description="Selecciona un caso para asignar un asesor "
              type="info"
              showIcon
            />
          ) : (
            <Form
              layout="vertical"
              name="basic"
              initialValues={datosBasicos}
              onFieldsChange={onChange}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Card className="borde">
                <div className="titulo">
                  <p>
                    Asignar asesor para el caso de{" "}
                    {usuario_seleccionado1.persona.nombre}{" "}
                    {usuario_seleccionado1.persona.apellido}
                  </p>
                </div>
                <Divider />
                {usuario_seleccionado1 !== null &&
                usuario_seleccionado1.tipo_asignacion.includes(1) ? (
                  <Row gutter={[40, 16]} justify="center">
                    <Col lg={12} md={12} xs={24}>
                      <Form.Item
                        label="Escoger asesor para juridica:"
                        name="asignacion_juridica"
                        rules={[
                          {
                            required: true,
                            message: "Este campo es requerido",
                          },
                        ]}
                        value={asignacion_juridica}
                      >
                        <Select placeholder="Seleccione">
                          {asesores.map((asesor) => (
                            <Select.Option value={asesor.id} key={asesor.id}>
                              {asesor.username}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                ) : null}

                {usuario_seleccionado1 !== null &&
                usuario_seleccionado1.tipo_asignacion.includes(2) ? (
                  <Row gutter={[40, 16]} justify="center">
                    <Col lg={12} md={12} xs={24}>
                      <Form.Item
                        label="Escoger asesor para psicologica:"
                        name="asignacion_psicologica"
                        rules={[
                          {
                            required: true,
                            message: "Este campo es requerido",
                          },
                        ]}
                        value={asignacion_psicologica}
                      >
                        <Select placeholder="Seleccione">
                          {asesores.map((asesor) => (
                            <Select.Option value={asesor.id} key={asesor.id}>
                              {asesor.username}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                ) : null}
                {usuario_seleccionado1 !== null &&
                usuario_seleccionado1.tipo_asignacion.includes(3) ? (
                  <Row gutter={[40, 16]} justify="center">
                    <Col lg={12} md={12} xs={24}>
                      <Form.Item
                        label="Escoger asesor para empleabilidad:"
                        name="asignacion_empleabilidad"
                        rules={[
                          {
                            required: true,
                            message: "Este campo es requerido",
                          },
                        ]}
                        value={asignacion_empleabilidad}
                      >
                        <Select placeholder="Seleccione">
                          {asesores.map((asesor) => (
                            <Select.Option value={asesor.id} key={asesor.id}>
                              {asesor.username}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                ) : null} 

                <Divider />
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Asignar
                    </Button>
                  </Form.Item>
                </div>
              </Card>
            </Form>
          )}
          */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TableAdminAsignacion;
