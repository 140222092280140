import React, { createContext, useReducer } from "react";
import ReducerAutenticacion from "./ReducerAutenticacion";

import instance from "../../axios/axios-api";
import tokenAuth from "../../axios/tokenAuth";

import {
  REGISTRO_EXITOSO,
  REGISTRO_ERROR,
  OBTENER_USUARIO,
  LOGIN_EXITOSO,
  LOGIN_ERROR,
  CERRAR_SESION,
} from "../../types/index";

export const ContextAutenticacion = createContext();

const ProviderAutenticacion = ({ children }) => {
  const initialState = {
    token: localStorage.getItem("token"),
    autenticado: null,
    usuario: null,
    mensaje: null,
    //  cargando: true,
    correo: "",
    info: "",
    error_alert: null,
  };

  const [state, dispatch] = useReducer(ReducerAutenticacion, initialState);

  //Retorna el usuario autenticado
  const usuarioAutenticado = async () => {
    const token = localStorage.getItem("token");

    if (token) {
      //TODO: Funcion para enviar el token por headers
      tokenAuth(token);
    }

    try {
      const respuesta = await instance.get("/api/auth/authenticated");
      //console.log(respuesta.data.username);
      dispatch({
        type: OBTENER_USUARIO,
        payload: respuesta.data,
      });
    } catch (error) {
      // console.log(error);
      dispatch({
        type: LOGIN_ERROR,
      });
    }
  };

  //Cuando el usuario inicia sesion
  const iniciarSesion = async (datos) => {
    try {
      const respuesta = await instance.post("/api/auth/signin/", datos);
      //console.log(respuesta)
      dispatch({
        type: LOGIN_EXITOSO,
        payload: respuesta.data,
      });

      //Obtener el usuario
      usuarioAutenticado();
    } catch (error) {
      // console.log(error.response);
      const alerta = {
        msg: error.response.data.message,
        categoria: "danger",
      };
      dispatch({
        type: LOGIN_ERROR,
        payload: alerta,
      });
    }
  };

  //Cuando se cierra la sesion del usuario
  const cerrarSesion = async () => {
    dispatch({
      type: CERRAR_SESION,
    });
  };

  const value = {
    token: state.token,
    autenticado: state.autenticado,
    usuario: state.usuario,
    mensaje: state.mensaje,
    cargando: state.cargando,
    error_alert: state.error_alert,
    iniciarSesion,
    usuarioAutenticado,
    cerrarSesion,
  };

  return (
    <ContextAutenticacion.Provider value={value}>
      {children}
    </ContextAutenticacion.Provider>
  );
};

export default ProviderAutenticacion;
