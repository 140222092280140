import React, { useState, useContext, useEffect } from "react";
import { ContextFormulario } from "../../contexts/formulario/ContextFormulario";
import {
  Form,
  Input,
  Button,
  Radio,
  Row,
  Col,
  Card,
  Select,
  InputNumber,
  Checkbox,
  Space,
  Divider,
} from "antd";
import "./styles.css";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { ContextAutenticacion } from "../../contexts/autenticacion/ContextAutenticacion";
import { Alert } from "antd";
import instance from "../../axios/axios-api";
import { useHistory } from "react-router-dom";

export const DatosBasicos = ({ navigation }) => {
  const [datosBasicos, setDatosBasicos] = useState({
    nombre: "",
    apellido: "",
    sexo: undefined,
    genero: "",
    edad: "",
    telefono: "",
    correo_electronico: "",
    ciudad_domicilio: "",
    barrio_domicilio: "",
    tipo_documento: undefined,
    documento: "",
    descripcion_otro: "",
    origen_pais: "",
    origen_departamento: "",
    origen_ciudad: "",
  });

  const [cambio, setCambio] = useState(false);
  const [bandera, setBandera] = useState(false);
  const [error, setError] = useState(false);
  const [loadingGuardar, setLoadingGuardar] = useState(false);
  const [errorConexion, setErrorConexion] = useState(false);
  const [cedulaDuplicada, setCedulaDuplicada] = useState(false);
  const history = useHistory();

  const [on, setOn] = useState("");

  const contextformulario = useContext(ContextFormulario);
  const {
    agregarDatosBasicos,
    usuario_seleccionado,
    actualizarDatosBasicos,
    listado,
    borrarUsuarioSeleccionado,
  } = contextformulario;

  const contextAutenticacion = useContext(ContextAutenticacion);
  const { usuario } = contextAutenticacion;

  //console.log(usuario_seleccionado);

  const {
    tipo_documento,
    documento,
    nombre,
    apellido,
    sexo,
    genero,
    ciudad_domicilio,
    origen_pais,
    origen_departamento,
    origen_ciudad,
    descripcion_otro,
    edad,
    telefono,
    correo_electronico,
    barrio_domicilio,
  } = datosBasicos;

  useEffect(() => {
    if (usuario_seleccionado !== null) {
      setDatosBasicos({
        nombres: usuario_seleccionado.persona.nombre,
        apellidos: usuario_seleccionado.persona.apellido,
        sexo: usuario_seleccionado.persona.sexo,
        genero: usuario_seleccionado.persona.genero,
        edad: usuario_seleccionado.persona.edad,
        telefono: usuario_seleccionado.telefono,
        correo_electronico: usuario_seleccionado.correo_electronico,
        ciudad_domicilio: usuario_seleccionado.ciudad_domicilio,
        barrio_domicilio: usuario_seleccionado.barrio_domicilio,
        documento: usuario_seleccionado.persona.tipo_documento,
        numero_documento: usuario_seleccionado.persona.documento,
        descripcion_otro: usuario_seleccionado.persona.descripcion_otro,
        origen_pais: usuario_seleccionado.persona.origen_pais,
        origen_departamento: usuario_seleccionado.persona.origen_departamento,
        origen_ciudad: usuario_seleccionado.persona.origen_ciudad,
      });

      setCambio(false);
    }
  }, [usuario_seleccionado]);

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };

  const checkStyle = {
    display: "block",
    marginRight: "0",
  };

  const checkStyle1 = {
    display: "block",
    marginRight: "0",
    marginBottom: "1rem",
    paddingTop: "0.5rem",
  };

  const onFinish = (values) => {
    if (usuario_seleccionado === null) {
      setLoadingGuardar(true);
      values["userId"] = usuario.id;
      instance
        .post("api/personas/register", values)
        .then((res) => {
          if (res.status === 200) {
            localStorage.setItem("id", res.data.id);
            setTimeout(() => {
              agregarDatosBasicos();
              setLoadingGuardar(false);
            }, 2000);
            navigation.next();
          } else {
            setLoadingGuardar(false);
            setErrorConexion(true);
          }
        })
        .catch(function (error) {
          // console.log(error.response);
          if (error.response.data.message === "Validation error") {
            setCedulaDuplicada(true);
            setLoadingGuardar(false);
          } else {
            setLoadingGuardar(false);
            setErrorConexion(true);
          }
        });
    } else {
      values["personaId"] = usuario_seleccionado.personaId;
      values["userId"] = usuario.id;
      values["id"] = usuario_seleccionado.id;

      if (cambio === true) {
        // actualizarDatosBasicos(values);
        // navigation.next();
        setLoadingGuardar(true);
        instance
          .put("/api/caracterizaciones/update", values)
          .then((res) => {
            if (res.status === 200) {
              setLoadingGuardar(false);
              navigation.next();
              // console.log(res);
            } else {
              setLoadingGuardar(false);
              setErrorConexion(true);
            }
          })
          .catch(function (error) {
            setLoadingGuardar(false);
            setErrorConexion(true);
          });
      } else {
        navigation.next();
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    //console.log("Failed:", errorInfo);
    setError(true);
  };

  const goBack = () => {
    borrarUsuarioSeleccionado();
    history.push("/asignacion_admin");
  };

  const onChange = (e) => {
    setDatosBasicos({
      ...datosBasicos,
      [e[0].name]: e[0].value,
    });
    setBandera(true);

    if (bandera === true) {
      // console.log(datosBasicos)
      setCambio(true);
    } else {
      setCambio(false);
    }
  };

  //console.log(usuario_seleccionado);

  return (
    <>
      <Form
        layout="vertical"
        name="basic"
        initialValues={{
          nombre: usuario_seleccionado
            ? usuario_seleccionado.persona.nombre
            : "",
          apellido: usuario_seleccionado
            ? usuario_seleccionado.persona.apellido
            : "",
          sexo: usuario_seleccionado
            ? usuario_seleccionado.persona.sexo
            : undefined,
          genero: usuario_seleccionado
            ? usuario_seleccionado.persona.genero
            : undefined,
          edad: usuario_seleccionado ? usuario_seleccionado.persona.edad : "",
          telefono: usuario_seleccionado ? usuario_seleccionado.telefono : "",
          correo_electronico: usuario_seleccionado
            ? usuario_seleccionado.correo_electronico
            : "",
          ciudad_domicilio: usuario_seleccionado
            ? usuario_seleccionado.ciudad_domicilio
            : "",
          barrio_domicilio: usuario_seleccionado
            ? usuario_seleccionado.barrio_domicilio
            : "",
          tipo_documento: usuario_seleccionado
            ? usuario_seleccionado.persona.tipo_documento
            : undefined,
          documento: usuario_seleccionado
            ? usuario_seleccionado.persona.documento
            : "",
          descripcion_otro: usuario_seleccionado
            ? usuario_seleccionado.persona.descripcion_otro
            : "",
          origen_pais: usuario_seleccionado
            ? usuario_seleccionado.persona.origen_pais
            : "",
          origen_departamento: usuario_seleccionado
            ? usuario_seleccionado.persona.origen_departamento
            : "",
          origen_ciudad: usuario_seleccionado
            ? usuario_seleccionado.persona.origen_ciudad
            : "",
        }}
        onFieldsChange={onChange}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Card className="borde">
          <div className="titulo">
            <p>1. Datos personales del encuestado</p>
          </div>
          <Row gutter={[40, 16]} justify="left">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="1. Nombres:"
                name="nombre"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={nombre}
              >
                <Input placeholder="Digite sus nombres" />
              </Form.Item>
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="2. Apellidos:"
                name="apellido"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={apellido}
              >
                <Input placeholder="Digite sus apellidos" />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={[40, 16]} justify="center">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="3. Sexo:"
                name="sexo"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={sexo}
              >
                <Select placeholder="Seleccione">
                  <Select.Option value={1}>Masculino</Select.Option>
                  <Select.Option value={2}>Femenino</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="4. Género:"
                name="genero"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={genero}
              >
                <Select placeholder="Seleccione">
                  <Select.Option value="1">Hombre</Select.Option>
                  <Select.Option value="2">Mujer</Select.Option>
                  <Select.Option value="3">Hombre Transgenero</Select.Option>
                  <Select.Option value="4">Mujer Transgenero</Select.Option>
                  <Select.Option value="5">Intersexual</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={[40, 16]} justify="center">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="5. Edad:"
                name="edad"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={edad}
              >
                <Input placeholder="Digite su edad" />
              </Form.Item>
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="6. Telefono:"
                name="telefono"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={telefono}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  placeholder="Digite su telefono"
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={[40, 16]} justify="center">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="7. Correo electronico:"
                name="correo_electronico"
                rules={[
                  {
                    type: "email",
                    required: false,
                    message: "Digite un correo valido",
                  },
                ]}
                value={correo_electronico}
              >
                <Input placeholder="Digite su correo electronico" />
              </Form.Item>
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="8. Ciudad/Corregimiento de domicilio:"
                name="ciudad_domicilio"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={ciudad_domicilio}
              >
                <Input placeholder="Digite su Ciudad/Corregimiento" />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={[40, 16]} justify="center">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="9. Barrio de domicilio:"
                name="barrio_domicilio"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={barrio_domicilio}
              >
                <Input placeholder="Digite su barrio de domicilio" />
              </Form.Item>
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="10. Tipo de documento de identidad:"
                name="tipo_documento"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={tipo_documento}
              >
                <Select placeholder="Seleccione su tipo de documento de identidad">
                  <Select.Option value={1}>
                    Cédula de identidad venezolana
                  </Select.Option>
                  <Select.Option value={2}>Cedula de ciudadanía</Select.Option>
                  <Select.Option value={3}>Cédula de Extranjería</Select.Option>
                  <Select.Option value={4}>Pasaporte</Select.Option>
                  <Select.Option value={5}>Registro Civil</Select.Option>
                  <Select.Option value={6}>Tarjeta de identidad</Select.Option>
                  <Select.Option value={7}>PEP</Select.Option>
                  <Select.Option value={8}>Visa</Select.Option>
                  <Select.Option value={9}>Otro</Select.Option>
                </Select>
              </Form.Item>

              {tipo_documento === 9 ? (
                <Form.Item
                  style={{ paddingTop: "1rem" }}
                  label="Digite la descripción"
                  name="descripcion_otro"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                  value={descripcion_otro}
                >
                  <Input placeholder="Descripción" />
                </Form.Item>
              ) : null}
            </Col>
          </Row>
          <Divider />
          <Row gutter={[40, 16]} justify="left">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="11. Número de documento:"
                name="documento"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={documento}
              >
                <Input placeholder="Digite número de documento" />
              </Form.Item>
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="12. Lugar de origen:"
                name="origen_pais"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={origen_pais}
              >
                <Input placeholder="País" />
              </Form.Item>

              <Form.Item
                style={{ paddingTop: "0.5rem" }}
                name="origen_departamento"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={origen_departamento}
              >
                <Input placeholder="Estado/Departamento" />
              </Form.Item>

              <Form.Item
                style={{ paddingTop: "0.5rem" }}
                name="origen_ciudad"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={origen_ciudad}
              >
                <Input placeholder="Municipio/Ciudad" />
              </Form.Item>
            </Col>
          </Row>
          <Divider />

          {cedulaDuplicada === true ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Alert
                style={{ width: "50%" }}
                message="El número de identificación digitado, ya existe en la base de datos."
                type="error"
                showIcon
              />
            </div>
          ) : null}
          {errorConexion === true ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Alert
                style={{ width: "50%" }}
                message="Los datos no fueron guardados, por favor revisa tu conexión a internet"
                type="error"
                showIcon
              />
            </div>
          ) : null}
          {error === true ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Alert
                style={{ width: "50%" }}
                message="Por favor verificar los datos ingresados"
                type="error"
                showIcon
              />
            </div>
          ) : null}
          {cambio === true &&
          error === false &&
          errorConexion === false &&
          cedulaDuplicada === false ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Alert
                style={{ width: "50%" }}
                message="Se guardaran los cambios efectuados"
                type="info"
                showIcon
              />
            </div>
          ) : null}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "2rem",
            }}
          >
            <Form.Item>
              <Button
                style={{ marginRight: "2rem" }}
                type="secondary"
                onClick={() => goBack()}
              >
                Atras
              </Button>
              <Button type="primary" htmlType="submit" loading={loadingGuardar}>
                Siguiente
              </Button>
            </Form.Item>
          </div>
        </Card>
      </Form>
    </>
  );
};
