import {
  REGISTRAR_DATOS_BASICOS,
  OBTENER_PRESTAMOS,
  REGISTRAR_USUARIO,
  OBTENER_USUARIOS,
  OBTENER_CARACTERIZACIONES,
  PRESTAMO_ERROR,
  OBTENER_USUARIO_SELECCIONADO,
  ACTUALIZAR_DATOS_BASICOS,
  ACTUALIZAR_TIPO_ASESORIA,
  ELIMINAR_CARACTERIZACION,
  BORRAR,
  USUARIO_SELECCIONADO_EDITAR,
  USUARIO_SELECCIONADO_ASESOR,
  OBTENER_ASESORES,
  ASIGNAR_ASESOR,
  ACTUALIZAR_DATOS_PERSONALES,
  ACTUALIZAR_ENTORNO_FAMILIAR,
  ACTUALIZAR_SALUD_FAMILIAR,
  ACTUALIZAR_MORBILIDAD_MENOR,
  ACTUALIZAR_MORBILIDAD_MAYOR,
  ACTUALIZAR_MORTALIDAD,
  ACTUALIZAR_SALUD_MENTAL,
  ACTUALIZAR_JURIDICA,
  ACTUALIZAR_PSICOLOGICA,
  ACTUALIZAR_EMPLEABILIDAD,
  OBTENER_USUARIO_SELECCIONADO_DATOS_PERSONALES,
  OBTENER_USUARIO_SELECCIONADO_ENTORNO_FAMILIAR,
  OBTENER_USUARIO_SELECCIONADO_SALUD_FAMILIAR,
  OBTENER_USUARIO_SELECCIONADO_MORBILIDAD,
  OBTENER_USUARIO_SELECCIONADO_SALUD_MENTAL,
  OBTENER_USUARIO_SELECCIONADO_JURIDICA,
  OBTENER_USUARIO_ASIGNACION,
  ASESORES_ASIGNADOS,
  OBTENER_SEGUIMIENTO_SELECCIONADO,
  REGISTRAR_SEGUIMIENTO_JURIDICO,
  OBTENER_SEGUIMIENTOS_JURIDICA,
  SEGUIMIENTO_SELECCIONADO_EDITAR,
  EDITAR_SEGUIMIENTO,
  ELIMINAR_SEGUIMIENTO_JURIDICO,
  OBTENER_SEGUIMIENTOS_EMPLEABILIDAD,
  REGISTRAR_SEGUIMIENTO_EMPLEABILIDAD,
  EDITAR_SEGUIMIENTO_EMPLEABILIDAD,
  ELIMINAR_SEGUIMIENTO_EMPLEABILIDAD,
  OBTENER_SEGUIMIENTOS_PSICOSOCIAL,
  REGISTRAR_SEGUIMIENTO_PSICOSOCIAL,
  EDITAR_SEGUIMIENTO_PSICOSOCIAL,
  ELIMINAR_SEGUIMIENTO_PSICOSOCIAL,
  ELIMINAR_USUARIO,
  OBTENER_USUARIO_SELECCIONADO_BACK,
  ACTUALIZAR_USUARIO_ADMIN,
} from "../../types";

export default (state, action) => {
  switch (action.type) {
    case PRESTAMO_ERROR:
      return {
        ...state,
        mensaje: action.payload,
      };

    case REGISTRAR_DATOS_BASICOS:
      return {
        ...state,
        formulario_basico: [...state.formulario_basico, action.payload],
        errorformulario: false,
      };
    case OBTENER_PRESTAMOS:
      // console.log(action.payload)
      return {
        ...state,
        listado: action.payload,
      };
    case OBTENER_SEGUIMIENTOS_PSICOSOCIAL:
    case OBTENER_SEGUIMIENTOS_EMPLEABILIDAD:
    case OBTENER_SEGUIMIENTOS_JURIDICA:
      return {
        ...state,
        seguimientos_juridica: action.payload,
      };

    case REGISTRAR_USUARIO:
      return {
        ...state,
        usuario: [...state.usuario, action.payload],
        errorformulario: false,
      };

    case REGISTRAR_SEGUIMIENTO_PSICOSOCIAL:
    case REGISTRAR_SEGUIMIENTO_EMPLEABILIDAD:
    case REGISTRAR_SEGUIMIENTO_JURIDICO:
      // console.log(action.payload);

      return {
        ...state,
        seguimientos_juridica: [...state.seguimientos_juridica, action.payload],
        errorformulario: false,
      };

    case OBTENER_USUARIOS:
      // console.log(action.payload)
      return {
        ...state,
        usuarios: action.payload,
      };

    case OBTENER_CARACTERIZACIONES:
      // console.log(action.payload)
      return {
        ...state,
        caraterizaciones: action.payload,
      };

    case OBTENER_USUARIO_SELECCIONADO:
      return {
        ...state,
        usuario_seleccionado: action.payload,
        loading: false,
      };

    case OBTENER_USUARIO_SELECCIONADO_BACK:
      return {
        ...state,
        usuario_seleccionado_back: action.payload,
        loading: false,
      };

    case OBTENER_SEGUIMIENTO_SELECCIONADO:
      return {
        ...state,
        seguimiento_seleccionado: action.payload,
      };

    case OBTENER_USUARIO_SELECCIONADO_DATOS_PERSONALES:
      return {
        ...state,
        usuario_seleccionado_datos_personales: action.payload,
      };

    case OBTENER_USUARIO_SELECCIONADO_ENTORNO_FAMILIAR:
      return {
        ...state,
        usuario_seleccionado_entorno_familiar: action.payload,
      };

    case OBTENER_USUARIO_SELECCIONADO_SALUD_FAMILIAR:
      return {
        ...state,
        usuario_seleccionado_salud_familiar: action.payload,
      };

    case OBTENER_USUARIO_SELECCIONADO_MORBILIDAD:
      return {
        ...state,
        usuario_seleccionado_morbilidad: action.payload,
      };

    case OBTENER_USUARIO_SELECCIONADO_SALUD_MENTAL:
      return {
        ...state,
        usuario_seleccionado_salud_mental: action.payload,
      };

    case OBTENER_USUARIO_SELECCIONADO_JURIDICA:
      return {
        ...state,
        usuario_seleccionado_juridica: action.payload,
      };

    case OBTENER_USUARIO_ASIGNACION:
      return {
        ...state,
        usuario_seleccionado_asignacion: action.payload,
      };

    case ASESORES_ASIGNADOS:
      return {
        ...state,
        asesores_asignados: action.payload,
      };

    case SEGUIMIENTO_SELECCIONADO_EDITAR:
      return {
        ...state,
        seguimiento_editar: action.payload,
      };

    case ACTUALIZAR_EMPLEABILIDAD:
    case ACTUALIZAR_PSICOLOGICA:
    case ACTUALIZAR_JURIDICA:
    case ACTUALIZAR_SALUD_MENTAL:
    case ACTUALIZAR_MORTALIDAD:
    case ACTUALIZAR_MORBILIDAD_MAYOR:
    case ACTUALIZAR_MORBILIDAD_MENOR:
    case ACTUALIZAR_SALUD_FAMILIAR:
    case ACTUALIZAR_ENTORNO_FAMILIAR:
    case ACTUALIZAR_DATOS_PERSONALES:
      return {
        ...state,
        formulario_basico: state.formulario_basico.map((basico) =>
          basico.id === action.payload._id ? action.payload : basico
        ),
      };

    case ACTUALIZAR_DATOS_BASICOS:
      return {
        ...state,
        formulario_basico: state.formulario_basico.map((basico) =>
          basico._id === action.payload._id ? action.payload : basico
        ),
      };

    case ACTUALIZAR_TIPO_ASESORIA:
      return {
        ...state,
        formulario_basico: state.formulario_basico.map((basico) =>
          basico._id === action.payload.id ? action.payload : basico
        ),
        usuario_seleccionado: null,
      };

    case ELIMINAR_CARACTERIZACION:
      return {
        ...state,
        formulario_basico: state.formulario_basico.filter(
          (cliente) => cliente._id !== action.payload
        ),
        cliente: null,
      };

    case ELIMINAR_USUARIO:
      return {
        ...state,
        usuarios: state.usuarios.filter(
          (usuario) => usuario._id !== action.payload
        ),
      };

    case USUARIO_SELECCIONADO_EDITAR:
    case BORRAR:
      return {
        ...state,
        usuario_seleccionado: action.payload,
        usuario_seleccionado1: action.payload,
        usuario_seleccionado_datos_personales: action.payload,
        usuario_seleccionado_entorno_familiar: action.payload,
        usuario_seleccionado_salud_familiar: action.payload,
        usuario_seleccionado_morbilidad: action.payload,
        usuario_seleccionado_salud_mental: action.payload,
        usuario_seleccionado_juridica: action.payload,
        seguimiento_seleccionado: action.payload,
        seguimiento_editar: action.payload,
        usuario_seleccionado_asignacion: action.payload,
        asesores_asignados: action.payload,
      };

    case USUARIO_SELECCIONADO_ASESOR:
      return {
        ...state,
        usuario_seleccionado1: action.payload,
      };

    case OBTENER_ASESORES:
      return {
        ...state,
        asesores: action.payload,
      };

    case ASIGNAR_ASESOR:
      return {
        ...state,
        formulario_basico: state.formulario_basico.map((basico) =>
          basico._id === action.payload._id ? action.payload : basico
        ),
        usuario_seleccionado: null,
      };

    case EDITAR_SEGUIMIENTO_PSICOSOCIAL:
    case EDITAR_SEGUIMIENTO_EMPLEABILIDAD:
    case EDITAR_SEGUIMIENTO:
      return {
        ...state,
        seguimientos_juridica: state.seguimientos_juridica.map((seguimiento) =>
          seguimiento._id === action.payload.id ? action.payload : seguimiento
        ),
      };

    case ELIMINAR_SEGUIMIENTO_PSICOSOCIAL:
    case ELIMINAR_SEGUIMIENTO_EMPLEABILIDAD:
    case ELIMINAR_SEGUIMIENTO_JURIDICO:
      return {
        ...state,
        seguimientos_juridica: state.seguimientos_juridica.filter(
          (seguimiento) => seguimiento.id !== action.payload
        ),
      };

    case ACTUALIZAR_USUARIO_ADMIN:
      return {
        ...state,
        usuarios: state.usuarios.map((basico) =>
          basico._id === action.payload.id ? action.payload : basico
        ),
      };

    default:
      return state;
  }
};
