import React, {useEffect, useContext} from "react";

import { Route,Redirect,withRouter } from "react-router-dom";
import { ContextAutenticacion } from '../../contexts/autenticacion/ContextAutenticacion';



const Authmiddleware = ({
	path,
	component: Component,
	layout: Layout
}) => {

	const contextAutenticacion = useContext(ContextAutenticacion);
   const {autenticado, cargando, usuarioAutenticado, usuario} = contextAutenticacion;



    useEffect(() => {
      
      usuarioAutenticado();
        // eslint-disable-next-line
	}, [])



		return(
			<Route
			path={path}
			render={props => {

			// here you can apply condition
			if (!autenticado && !cargando ) {
					return (
						<Redirect to={{ pathname: "/login", state: { from: props.location } }} />
					);
				}
				
				return (
		     	<Layout>
						<Component {...props} />
					</Layout>
				);
			}}
		/>

		)
		
	
}


export default withRouter(Authmiddleware);

