import React, { useState, useContext, useEffect } from "react";
import { ContextFormulario } from "../../contexts/formulario/ContextFormulario";
import { Result, Button } from "antd";
import { divIcon } from "leaflet";

export const SeleccionarAsignacion = ({ navigation }) => {
  const [loadingAtras, setLoadingAtras] = useState(false);
  const contextformulario = useContext(ContextFormulario);
  const {
    actualizarEmpleabilidad,
    usuario_seleccionado,
    formulario_basico,
    usuarioSeleccionado,
  } = contextformulario;
  const [data_formulario, setDataFormulario] = useState("");

  useEffect(() => {
    if (formulario_basico.length > 0) {
      setDataFormulario(formulario_basico[0]);
    }
  }, [formulario_basico]);

  const goBack = async () => {
    if (usuario_seleccionado !== null) {
      let id = usuario_seleccionado.id;
      setLoadingAtras(true);
      usuarioSeleccionado(id);

      setTimeout(() => {
        navigation.previous();
        setLoadingAtras(false);
      }, 3000);
    } else {
      // console.log("Va de corrido");
      let id = data_formulario;
      setLoadingAtras(true);
      usuarioSeleccionado(id);

      setTimeout(() => {
        navigation.previous();
        setLoadingAtras(false);
      }, 3000);
    }
  };

  const redirigir = () => {
    navigation.go(13);
  };

  const redirigir1 = () => {
    navigation.go(14);
  };
  return (
    <>
      <Result
        title="Seleccione una opción para continuar"
        extra={[
          <Button
            style={{ paddingLeft: "3rem", paddingRight: "3rem" }}
            type="primary"
            key="console"
            onClick={() => redirigir()}
          >
            Asignar asesoria
          </Button>,
          <Button type="primary" key="buy" onClick={() => redirigir1()}>
            Asignar asesoria y asesor
          </Button>,
        ]}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button loading={loadingAtras} onClick={() => goBack()}>
          Atras
        </Button>
      </div>
    </>
  );
};
