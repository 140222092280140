import React, { useContext, useEffect } from "react";
import { ContextFormulario } from "../../contexts/formulario/ContextFormulario";
import { ContextAutenticacion } from "../../contexts/autenticacion/ContextAutenticacion";
import SeguimientoEmpleabilidad from "./SeguimientoEmpleabilidad";
import SeguimientoJuridico from "./SeguimientoJuridico";
import SeguimientoPsicosocial from "./SeguimientoPsicosocial";

const Seguimiento = () => {
  const contextformulario = useContext(ContextFormulario);
  const { caraterizaciones, obtenerCaraterizacionesAsesor } = contextformulario;

  const contextAutenticacion = useContext(ContextAutenticacion);
  const { usuario } = contextAutenticacion;

  useEffect(() => {
    if (caraterizaciones === undefined || caraterizaciones === null) {
      obtenerCaraterizacionesAsesor("asignada", usuario.id);
    }
  }, []);
  // console.log(usuario)

  //console.log(caraterizaciones)
  return usuario.tipo_asesoria == "1" ? (
    <SeguimientoJuridico />
  ) : usuario.tipo_asesoria == "3" ? (
    <SeguimientoPsicosocial />
  ) : (
    <SeguimientoEmpleabilidad />
  );
};

export default Seguimiento;
