import React from 'react';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {
  Container,
} from 'reactstrap';

const InicioAsesor = () => {
    return ( 
            <>
            <div className="page-content">
            <Container fluid>
                {/* Render Breadcrumb */}
                <Breadcrumbs
                titulo={('Inicio Asesor')}
                breadcrumb={{
                    actual: 'Inicio',
                    linkActual: '#',
                    anterior: 'Inicio',
                    linkAnterior: '#',
                }}
                />
                Bienvenidos a Inicio
               </Container>
               </div>
              </>

     );
}
 
export default InicioAsesor;