import React, { useState, useContext, useEffect } from "react";
import { ContextFormulario } from "../../contexts/formulario/ContextFormulario";
import {
  Form,
  Input,
  Button,
  Radio,
  Row,
  Col,
  Card,
  Select,
  InputNumber,
  Checkbox,
  Space,
  Divider,
} from "antd";
import "./styles.css";
import { ContextAutenticacion } from "../../contexts/autenticacion/ContextAutenticacion";
import { Alert } from "antd";
import instance from "../../axios/axios-api";

export const Empleabilidad = ({ navigation }) => {
  const [bandera, setBandera] = useState(false);
  const [cambio, setCambio] = useState(false);
  const [error, setError] = useState(false);
  const [loadingGuardar, setLoadingGuardar] = useState(false);
  const [loadingAtras, setLoadingAtras] = useState(false);
  const [errorConexion, setErrorConexion] = useState(false);
  const [datosBasicos, setDatosBasicos] = useState({
    formacion_empleo: "",
    orientacion_empresas: "",
  });

  const contextformulario = useContext(ContextFormulario);
  const {
    actualizarEmpleabilidad,
    usuario_seleccionado,
    formulario_basico,
    usuarioSeleccionado,
  } = contextformulario;
  const contextAutenticacion = useContext(ContextAutenticacion);
  const { usuario } = contextAutenticacion;
  const [data_formulario, setDataFormulario] = useState("");

  const { formacion_empleo, orientacion_empresas } = datosBasicos;

  useEffect(() => {
    if (usuario_seleccionado !== null) {
      setDatosBasicos({
        formacion_empleo: usuario_seleccionado.formacion_empleo,
        orientacion_empresas: usuario_seleccionado.orientacion_empresas,
      });

      setCambio(false);
    }
  }, [usuario_seleccionado]);

  useEffect(() => {
    if (formulario_basico.length > 0) {
      setDataFormulario(formulario_basico[0]);
    }
  }, [formulario_basico]);

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };

  const checkStyle = {
    display: "block",
    marginRight: "0",
  };

  const checkStyle1 = {
    display: "block",
    marginRight: "0",
    marginBottom: "1rem",
    paddingTop: "0.5rem",
  };

  const onFinish = (values) => {
    if (usuario_seleccionado !== null) {
      values["id"] = usuario_seleccionado.id;
      //console.log("Success:", values);
      if (cambio === true) {
        // actualizarEmpleabilidad(values);
        // navigation.next();
        setLoadingGuardar(true);
        instance
          .put("/api/caracterizaciones/update_empleabilidad", values)
          .then((res) => {
            if (res.status === 200) {
              setLoadingGuardar(false);
              navigation.next();
              //console.log(res);
            } else {
              setLoadingGuardar(false);
              setErrorConexion(true);
            }
          })
          .catch(function (error) {
            setLoadingGuardar(false);
            setErrorConexion(true);
          });
      } else {
        navigation.next();
      }
    } else {
      values["id"] = data_formulario;
      //  actualizarEmpleabilidad(values);
      // navigation.next();
      setLoadingGuardar(true);
      instance
        .put("/api/caracterizaciones/update_empleabilidad", values)
        .then((res) => {
          if (res.status === 200) {
            setLoadingGuardar(false);
            navigation.next();
            //console.log(res);
          } else {
            setLoadingGuardar(false);
            setErrorConexion(true);
          }
        })
        .catch(function (error) {
          setLoadingGuardar(false);
          setErrorConexion(true);
        });
    }
  };

  const onFinishFailed = (errorInfo) => {
    //console.log("Failed:", errorInfo);
    setError(true);
  };

  const goBack = async () => {
    if (usuario_seleccionado !== null) {
      let id = usuario_seleccionado.id;
      setLoadingAtras(true);
      usuarioSeleccionado(id);

      setTimeout(() => {
        navigation.previous();
        setLoadingAtras(false);
      }, 3000);
    } else {
      //console.log("Va de corrido");
      let id = data_formulario;
      setLoadingAtras(true);
      usuarioSeleccionado(id);

      setTimeout(() => {
        navigation.previous();
        setLoadingAtras(false);
      }, 3000);
    }
  };

  const onChange = (e) => {
    setDatosBasicos({
      ...datosBasicos,
      [e[0].name]: e[0].value,
    });

    setBandera(true);
    if (bandera) {
      setCambio(true);
    } else {
      setCambio(false);
    }
  };

  return (
    <>
      <Form
        layout="vertical"
        name="basic"
        initialValues={{
          formacion_empleo: usuario_seleccionado
            ? usuario_seleccionado.formacion_empleo
            : "",
          orientacion_empresas: usuario_seleccionado
            ? usuario_seleccionado.orientacion_empresas
            : "",
        }}
        onFieldsChange={onChange}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Card className="borde">
          <div className="titulo">
            <p>4. Asesoría Empleabilidad</p>
          </div>
          <Row gutter={[40, 16]} justify="left">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="101. Formación para el empleo: ¿En qué?"
                name="formacion_empleo"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={formacion_empleo}
              >
                <Input.TextArea placeholder="Digite" />
              </Form.Item>
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="102. Orientación empresarial/ideas de negocios ¿En qué?"
                name="orientacion_empresas"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={orientacion_empresas}
              >
                <Input.TextArea placeholder="Digite" />
              </Form.Item>
            </Col>
          </Row>

          <Divider />
          {errorConexion === true ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Alert
                style={{ width: "50%" }}
                message="Los datos no fueron guardados, por favor revisa tu conexión a internet."
                type="error"
                showIcon
              />
            </div>
          ) : null}

          {error === true ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Alert
                style={{ width: "50%" }}
                message="Por favor verificar los datos ingresados."
                type="error"
                showIcon
              />
            </div>
          ) : null}

          {cambio === true && error === false && errorConexion === false ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Alert
                style={{ width: "50%" }}
                message="Se guardaran los cambios efectuados."
                type="info"
                showIcon
              />
            </div>
          ) : null}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "2rem",
            }}
          >
            <Form.Item>
              <Button
                style={{ marginRight: "2rem" }}
                type="primary"
                loading={loadingAtras}
                onClick={() => goBack()}
              >
                Atras
              </Button>
              <Button type="primary" htmlType="submit" loading={loadingGuardar}>
                Siguiente
              </Button>
            </Form.Item>
          </div>
        </Card>
      </Form>
    </>
  );
};
