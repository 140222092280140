import React, { useState, useContext, useEffect } from "react";
import { ContextFormulario } from "../../contexts/formulario/ContextFormulario";
import {
  Form,
  Input,
  Button,
  Radio,
  Row,
  Col,
  Card,
  Select,
  InputNumber,
  Checkbox,
  Space,
  Divider,
} from "antd";
import "./styles.css";
import { ContextAutenticacion } from "../../contexts/autenticacion/ContextAutenticacion";
import { useHistory } from "react-router-dom";

export const Psicologica = ({ navigation }) => {
  const history = useHistory();
  const [flag, setFlag] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [datosBasicos, setDatosBasicos] = useState({
    atencion_red: "",
    atencion_red_cuales: "",
    ubicacion_red: "",
  });

  const contextformulario = useContext(ContextFormulario);
  const { actualizarPsicologica, usuario_seleccionado, formulario_basico } =
    contextformulario;
  const contextAutenticacion = useContext(ContextAutenticacion);
  const { usuario } = contextAutenticacion;
  const [data_formulario, setDataFormulario] = useState("");

  const { atencion_red, atencion_red_cuales, ubicacion_red } = datosBasicos;

  useEffect(() => {
    if (usuario_seleccionado !== null) {
      setDatosBasicos({
        atencion_red: usuario_seleccionado.atencion_red,
        atencion_red_cuales: usuario_seleccionado.atencion_red_cuales,
        ubicacion_red: usuario_seleccionado.ubicacion_red,
      });
    }
  }, [usuario_seleccionado]);

  useEffect(() => {
    if (formulario_basico.length > 0) {
      setDataFormulario(formulario_basico[0]);
    }
  }, [formulario_basico]);

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };

  const checkStyle = {
    display: "block",
    marginRight: "0",
  };

  const checkStyle1 = {
    display: "block",
    marginRight: "0",
    marginBottom: "1rem",
    paddingTop: "0.5rem",
  };

  const onFinish = (values) => {
    navigation.next();
  };

  const onFinishFailed = (errorInfo) => {
    //console.log("Failed:", errorInfo);
  };

  const onChange = (e) => {
    setDatosBasicos({
      ...datosBasicos,
      [e[0].name]: e[0].value,
    });
  };

  return (
    <>
      <Form
        layout="vertical"
        name="basic"
        initialValues={{
          atencion_red: usuario_seleccionado
            ? usuario_seleccionado.medio_atencion
            : "",
          atencion_red_cuales: usuario_seleccionado
            ? usuario_seleccionado.atencion_red_cuales
            : "",
          ubicacion_red: usuario_seleccionado
            ? usuario_seleccionado.ubicacion_red
            : "",
        }}
        onFieldsChange={onChange}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Card className="borde">
          <div className="titulo">
            <p>3. Asesoría Psicologica</p>
          </div>
          <Row gutter={[40, 16]} justify="left">
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="99. Atención"
                name="atencion_red"
                rules={[
                  {
                    required: true,
                    message: "Este campo es requerido",
                  },
                ]}
                value={atencion_red}
              >
                <Select disabled={disabled} placeholder="Seleccione">
                  <Select.Option value="1">Atención por la red</Select.Option>
                  <Select.Option value="2">
                    Remisión a otras entidades
                  </Select.Option>
                  <Select.Option value="3">No aplica</Select.Option>
                </Select>
              </Form.Item>
              {atencion_red === "2" ? (
                <Form.Item
                  style={{ paddingTop: "1rem" }}
                  label="Cúal"
                  name="atencion_red_cuales"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es requerido",
                    },
                  ]}
                  value={atencion_red_cuales}
                >
                  <Input disabled={disabled} placeholder="Digite" />
                </Form.Item>
              ) : null}
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                label="100. Para la atención por la red, que lugar le queda más cercano:"
                name="ubicacion_red"
                rules={[
                  {
                    required: false,
                    message: "Este campo es requerido",
                  },
                ]}
                value={ubicacion_red}
              >
                <Select disabled={disabled} placeholder="Seleccione">
                  <Select.Option value="1">
                    Universidad San Buenaventura-Ternera
                  </Select.Option>
                  <Select.Option value="2">
                    Universidad del Sinú - Almirante Colón
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Divider />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Form.Item>
              <Button
                style={{ marginRight: "2rem" }}
                type="primary"
                onClick={() => navigation.previous()}
              >
                Atras
              </Button>
              <Button type="primary" htmlType="submit">
                Siguiente
              </Button>
            </Form.Item>
          </div>
        </Card>
      </Form>
    </>
  );
};
