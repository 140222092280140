import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Badge, Container } from "reactstrap";
import { Link } from "react-router-dom";
import { ContextFormulario } from "../../contexts/formulario/ContextFormulario";
import AlertaContext from "../../contexts/alerta/alertaContext";
import { Alert } from "antd";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useHistory } from "react-router-dom";

import { DeleteOutlined, UserAddOutlined } from "@ant-design/icons";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

import { Form, Button, Row, Col, Select, Divider, Tooltip, Spin } from "antd";
import "./styles.css";
import Swal from "sweetalert2";
import { ContextAutenticacion } from "../../contexts/autenticacion/ContextAutenticacion";

function rowStyleFormat(row, rowIdx) {
  return { backgroundColor: rowIdx % 2 === 0 ? "white" : "#E1E7F1" };
}

function renderShowsTotal(start, to, total) {
  return (
    <span style={{ color: "gray" }}>
      Mostrando filas {start} de {to}, de {total} &nbsp;&nbsp;
    </span>
  );
}

const options = {
  firstPageText: "Primera",
  prePageText: "Atras",
  nextPageText: "Siguiente",
  lastPageText: "Última",
  nextPageTitle: "Primera Pagina",
  prePageTitle: "Pagina Previa",
  firstPageTitle: "Siguiente Pagina",
  lastPageTitle: "Ultima Pagina",
  showTotal: true,
  paginationShowsTotal: renderShowsTotal,
};

const TableAdminAsignadas = () => {
  const history = useHistory();
  const [datosBasicos, setDatosBasicos] = useState({
    asignacion_empleabilidad: undefined,
    asignacion_juridica: undefined,
    asignacion_psicologica: undefined,
  });

  const [empleabilidad, setEmpleabilidad] = useState("");
  const [juridica, setJuridica] = useState("");
  const [psicologica, setPsicologica] = useState("");

  const {
    asignacion_empleabilidad,
    asignacion_juridica,
    asignacion_psicologica,
  } = datosBasicos;
  const contextformulario = useContext(ContextFormulario);
  const {
    obtenerCaraterizacionesAdmin,
    caraterizaciones,
    usuario_seleccionado,
    asignarCaso,
    obtenerAsesores,
    asesores,
    asignarAsesor,
    borrarUsuarioSeleccionado,
    asesores_asignados,
    usuarioSeleccionado,
    usuario_seleccionado1,
    usuarioSeleccionadoDatosPersonales,
    usuarioSeleccionadoEntornoFamiliar,
    usuarioSeleccionadoSaludFamiliar,
    usuarioSeleccionadoMorbilidad,
    usuarioSeleccionadoSaludMental,
    usuarioSeleccionadoJuridica,
    usuarioSeleccionadoAsignacion,
    AsesoresAsignados,
    cerrarSeguimientoAdmin,
    seguimientoSeleccionado,
    seguimiento_seleccionado,
    usuario_seleccionado_asignacion,
  } = contextformulario;

  const contextAutenticacion = useContext(ContextAutenticacion);
  const { usuario } = contextAutenticacion;

  const alertaContext = useContext(AlertaContext);
  const { alerta, mostrarAlerta } = alertaContext;
  const [flag, setflag] = useState(null);

  useEffect(() => {
    obtenerCaraterizacionesAdmin("asignada");
    obtenerAsesores();
  }, []);

  //console.log(caraterizaciones);

  function downloadFile4(json) {
    usuarioSeleccionado(json);
    usuarioSeleccionadoDatosPersonales(json);
    usuarioSeleccionadoEntornoFamiliar(json);
    usuarioSeleccionadoSaludFamiliar(json);
    usuarioSeleccionadoMorbilidad(json);
    usuarioSeleccionadoSaludMental(json);
    usuarioSeleccionadoJuridica(json);
    usuarioSeleccionadoAsignacion(json);
    AsesoresAsignados(json);
    setflag(true);
  }

  function downloadFile3(json) {
    //console.log(json)
    usuarioSeleccionado(json);
    usuarioSeleccionadoDatosPersonales(json);
    usuarioSeleccionadoEntornoFamiliar(json);
    usuarioSeleccionadoSaludFamiliar(json);
    usuarioSeleccionadoMorbilidad(json);
    usuarioSeleccionadoSaludMental(json);
    usuarioSeleccionadoJuridica(json);
    usuarioSeleccionadoAsignacion(json);
    AsesoresAsignados(json);
  }

  function downloadFile5(json) {
    Swal.fire({
      title: "¿Deseas cerrar este caso?",
      text: "Esta acción no se puede deshacer",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Cerrar",
      cancelButtonText: "No, Cancelar",
    }).then(async (result) => {
      //console.log(result.value);
      if (result.value) {
        try {
          cerrarSeguimientoAdmin(json);
          setTimeout(() => {
            obtenerCaraterizacionesAdmin("asignada");
          }, 500);

          Swal.fire("Caso Cerrado", "", "success");
        } catch (error) {
          Swal.fire("Error al cerrar caso", "", "error");
        }
      }
    });
  }

  useEffect(() => {
    if (usuario_seleccionado !== null) {
      history.push("/formulario-ver");
    }
    // // function downloadFile(json) {
    //  usuarioSeleccionado(json.id);
    //  setTimeout(() => {
    //   history.push("/formulario");
    // }, 1000);
  }, [usuario_seleccionado]);

  useEffect(() => {
    if (usuario_seleccionado !== null && flag === true) {
      history.push("/formulario");
    }
  }, [usuario_seleccionado]);

  useEffect(() => {
    if (seguimiento_seleccionado !== null) {
      history.push("/seguimiento_ver_admin");
    }
  }, [seguimiento_seleccionado]);

  function buttons(row, Index) {
    return (
      <>
        <i
          onClick={() => downloadFile3(Index.id)}
          className="far fa-eye see"
          title="Visualizar Caracterizacion"
        ></i>
        <i
          onClick={() => downloadFile4(Index.id)}
          className="fas fa-edit edit"
          title="Editar"
        ></i>
        <i
          onClick={() => downloadFile5(Index)}
          className="far fa-times-circle delete"
          title="Cerrar Seguimiento"
        ></i>
      </>
    );
  }

  const array = [];
  // console.log(caraterizaciones);
  for (var i = 0; i < caraterizaciones.length; i++) {
    //console.log(caraterizaciones.length);
    var tipo = "";
    if (caraterizaciones[i].tipo_asesoria == 1) {
      tipo = "Asesoria Juridica";
    } else if (caraterizaciones[i].tipo_asesoria == 2) {
      tipo = "Atención Psicosocial";
    } else {
      tipo = "Empleabilidad";
    }
    // console.log(caraterizaciones[i].asesor_caracterizaciones[0].tipo);
    //console.log(caraterizaciones[i].asesor_caracterizaciones[1].tipo);
    // console.log(caraterizaciones[i].asesor_caracterizaciones[2].tipo);
    array.push({
      id: caraterizaciones[i].id,
      num: i + 1,
      nombre:
        caraterizaciones[i].persona.nombre +
        " " +
        caraterizaciones[i].persona.apellido,
      creado: caraterizaciones[i].createdAt,
      estado: "Asignados",
      estado_asesoria_empleabilidad:
        caraterizaciones[i].estado_asesoria_empleabilidad,
      estado_asesoria_juridica: caraterizaciones[i].estado_asesoria_juridica,
      estado_asesoria_psicosocial:
        caraterizaciones[i].estado_asesoria_psicosocial,
      tipo_asesoria: tipo,
      digitador: caraterizaciones[i].digitador.username,
      ase_juridido:
        caraterizaciones[i].asesor_caracterizaciones.length > 0 &&
        caraterizaciones[i].asesor_caracterizaciones[0].tipo === "1"
          ? asesores
              .filter(
                (asesor) =>
                  asesor.id ===
                  caraterizaciones[i].asesor_caracterizaciones[0].userId
              )
              .map((asesor) => asesor.username)
          : caraterizaciones[i].asesor_caracterizaciones.length >= 2 &&
            caraterizaciones[i].asesor_caracterizaciones[1].tipo === "1"
          ? asesores
              .filter(
                (asesor) =>
                  asesor.id ===
                  caraterizaciones[i].asesor_caracterizaciones[1].userId
              )
              .map((asesor) => asesor.username)
          : caraterizaciones[i].asesor_caracterizaciones.length >= 3 &&
            caraterizaciones[i].asesor_caracterizaciones[2].tipo === "1"
          ? asesores
              .filter(
                (asesor) =>
                  asesor.id ===
                  caraterizaciones[i].asesor_caracterizaciones[2].userId
              )
              .map((asesor) => asesor.username)
          : "No hay",

      ase_empleo:
        caraterizaciones[i].asesor_caracterizaciones.length > 0 &&
        caraterizaciones[i].asesor_caracterizaciones[0].tipo === "2"
          ? asesores
              .filter(
                (asesor) =>
                  asesor.id ===
                  caraterizaciones[i].asesor_caracterizaciones[0].userId
              )
              .map((asesor) => asesor.username)
          : caraterizaciones[i].asesor_caracterizaciones.length >= 2 &&
            caraterizaciones[i].asesor_caracterizaciones[1].tipo === "2"
          ? asesores
              .filter(
                (asesor) =>
                  asesor.id ===
                  caraterizaciones[i].asesor_caracterizaciones[1].userId
              )
              .map((asesor) => asesor.username)
          : caraterizaciones[i].asesor_caracterizaciones.length >= 3 &&
            caraterizaciones[i].asesor_caracterizaciones[2].tipo === "2"
          ? asesores
              .filter(
                (asesor) =>
                  asesor.id ===
                  caraterizaciones[i].asesor_caracterizaciones[2].userId
              )
              .map((asesor) => asesor.username)
          : "",

      ase_psico:
        caraterizaciones[i].asesor_caracterizaciones.length > 0 &&
        caraterizaciones[i].asesor_caracterizaciones[0].tipo === "3"
          ? asesores
              .filter(
                (asesor) =>
                  asesor.id ===
                  caraterizaciones[i].asesor_caracterizaciones[0].userId
              )
              .map((asesor) => asesor.username)
          : caraterizaciones[i].asesor_caracterizaciones.length >= 2 &&
            caraterizaciones[i].asesor_caracterizaciones[1].tipo === "3"
          ? asesores
              .filter(
                (asesor) =>
                  asesor.id ===
                  caraterizaciones[i].asesor_caracterizaciones[1].userId
              )
              .map((asesor) => asesor.username)
          : caraterizaciones[i].asesor_caracterizaciones.length >= 3 &&
            caraterizaciones[i].asesor_caracterizaciones[2].tipo === "3"
          ? asesores
              .filter(
                (asesor) =>
                  asesor.id ===
                  caraterizaciones[i].asesor_caracterizaciones[2].userId
              )
              .map((asesor) => asesor.username)
          : "",
    });
  }
  //console.log(array);

  //   const array_asesores = [];
  //  // console.log(asesores_asignados)
  //   for (var i = 0; i < asesores_asignados.length; i++) {
  //     array_asesores.push(
  //       parseInt(asesores_asignados[i].tipo)
  //     );
  //   }

  //   console.log(array_asesores)

  const array_asignados = [];
  let array_nuevo = [];

  if (asesores_asignados !== null) {
    array_nuevo = asesores_asignados.asesor_caracterizaciones;
    //console.log(asesores_asignados);
  }

  if (array_nuevo && array_nuevo.length >= 1) {
    //console.log(array_nuevo);
    for (
      var i = 0;
      i < asesores_asignados.asesor_caracterizaciones.length;
      i++
    ) {
      array_asignados.push({
        id: parseInt(asesores_asignados.asesor_caracterizaciones[i].userId),
        tipo: parseInt(asesores_asignados.asesor_caracterizaciones[i].tipo),
      });
    }
  }

  //console.log(array_asignados);
  let empleo;
  let psico;
  let juridi;
  if (array_asignados.length > 0) {
    // console.log("aqui");
    for (var i = 0; i < array_asignados.length; i++) {
      if (array_asignados[i].tipo === 1) {
        juridi = array_asignados[i].id;
      } else if (array_asignados[i].tipo === 3) {
        psico = array_asignados[i].id;
      } else {
        empleo = array_asignados[i].id;
      }
    }
  }

  const onFinish = (values) => {
    // console.log(values);

    borrarUsuarioSeleccionado();
    //navigation.next();
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const onChange = (e) => {
    setDatosBasicos({
      ...datosBasicos,
      [e[0].name]: e[0].value,
    });
  };

  function estado(cell, row, Index) {
    // console.log(cell);
    return cell === "N/A" ? (
      <p>{cell}</p>
    ) : cell === "finalizada" ? (
      <>
        <p>{row.ase_juridido}</p>

        <a style={{ color: "blue" }} onClick={() => downloadFile(row)}>
          Finalizada
        </a>
      </>
    ) : cell === "en_seguimiento" ? (
      <>
        <p>{row.ase_juridido}</p>

        <a style={{ color: "blue" }} onClick={() => downloadFile(row)}>
          En seguimiento
        </a>
      </>
    ) : (
      <>
        <p>{row.ase_juridido}</p>
        <a style={{ color: "blue" }} onClick={() => downloadFile(row)}>
          Sin Completar
        </a>
      </>
    );
  }

  function estado1(cell, row) {
    //console.log(cell);
    return cell === "N/A" ? (
      <p>{cell}</p>
    ) : cell === "finalizada" ? (
      <>
        <p>{row.ase_empleo}</p>
        <a style={{ color: "blue" }} onClick={() => downloadFile1(row)}>
          Finalizada
        </a>
      </>
    ) : cell === "en_seguimiento" ? (
      <>
        <p>{row.ase_empleo}</p>

        <a style={{ color: "blue" }} onClick={() => downloadFile1(row)}>
          En seguimiento
        </a>
      </>
    ) : (
      <>
        <p>{row.ase_empleo}</p>
        <a style={{ color: "blue" }} onClick={() => downloadFile1(row)}>
          Sin Completar
        </a>
      </>
    );
  }

  function estado2(cell, row) {
    //console.log(row);
    return cell === "N/A" ? (
      <p>{cell}</p>
    ) : cell === "finalizada" ? (
      <>
        <p>{row.ase_psico}</p>
        <a style={{ color: "blue" }} onClick={() => downloadFile2(row)}>
          Finalizada
        </a>
      </>
    ) : cell === "en_seguimiento" ? (
      <>
        <p>{row.ase_psico}</p>

        <a style={{ color: "blue" }} onClick={() => downloadFile2(row)}>
          En seguimiento
        </a>
      </>
    ) : (
      <>
        <p>{row.ase_psico}</p>
        <a style={{ color: "blue" }} onClick={() => downloadFile2(row)}>
          Sin Completar
        </a>
      </>
    );
  }

  function downloadFile(json) {
    // console.log(json);
    seguimientoSeleccionado(json.id);
    history.push("/seguimiento_admin_juridico");
  }

  function downloadFile1(json) {
    // console.log(json);
    seguimientoSeleccionado(json.id);
    history.push("/seguimiento_admin_empleabilidad");
  }

  function downloadFile2(json) {
    //console.log(json);
    seguimientoSeleccionado(json.id);
    history.push("/seguimiento_admin_psicosocial");
  }

  //console.log(usuario_seleccionado_asignacion);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            titulo="Gestión de casos asignados"
            breadcrumb={{
              actual: "Gestión de casos",
              linkActual: "#",
              anterior: "Gestión de casos",
              linkAnterior: "#",
            }}
          />
          {caraterizaciones && caraterizaciones.length > 0 ? (
            <>
              <div style={{ paddingTop: "2rem", paddingBottom: "2rem" }}>
                <BootstrapTable
                  searchPlaceholder="Búsqueda..."
                  wrapperClasses="table-responsive"
                  striped
                  pagination
                  options={options}
                  data={array}
                  search={true}
                  multiColumnSearch={true}
                  trStyle={rowStyleFormat}
                >
                  <TableHeaderColumn width="60" dataField="num" isKey>
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn width="120" dataField="nombre">
                    Nombre
                  </TableHeaderColumn>
                  <TableHeaderColumn width="120" dataField="creado">
                    Fecha
                  </TableHeaderColumn>

                  <TableHeaderColumn width="120" dataField="estado">
                    Estado
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    width="100"
                    dataField="estado_asesoria_juridica"
                    dataFormat={estado}
                  >
                    Juridica
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    width="100"
                    dataField="estado_asesoria_empleabilidad"
                    dataFormat={estado1}
                  >
                    Empleabilidad
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    width="100"
                    dataField="estado_asesoria_psicosocial"
                    dataFormat={estado2}
                  >
                    Psicosocial
                  </TableHeaderColumn>
                  <TableHeaderColumn width="120" dataField="digitador">
                    Digitador
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    width="120"
                    hidden
                    dataField="ase_juridido"
                  >
                    Asesor Juridico
                  </TableHeaderColumn>
                  <TableHeaderColumn width="120" hidden dataField="ase_empleo">
                    Asesor Empleo
                  </TableHeaderColumn>
                  <TableHeaderColumn width="120" hidden dataField="ase_psico">
                    Asesor Psico
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    width="100"
                    dataField="buttons"
                    dataFormat={buttons}
                  >
                    Acciones
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            </>
          ) : (
            <Alert
              message="Información"
              description="No existen casos asignados "
              type="info"
              showIcon
            />
          )}
          {/* {array_asignados.length === 0 ? (
            <Alert
              message="Información"
              description="Seleccione un caso para ver asesores asignados "
              type="info"
              showIcon
            />
          ) : (
            <Form
              layout="vertical"
              name="basic"
              initialValues={{
                asignacion_juridica: juridi,
                asignacion_psicologica: psico,
                asignacion_empleabilidad: empleo,
              }}
              onFieldsChange={onChange}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Card className="borde">
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                    paddingTop: "2rem",
                  }}
                >
                  Asesores Asignados
                </p>

                <Divider />

                {array_asignados &&
                asesores_asignados &&
                asesores_asignados.tipo_asignacion.includes(1) ? (
                  <Row gutter={[40, 16]} justify="center">
                    <Col lg={12} md={12} xs={24}>
                      <Form.Item
                        label="Asesor para juridica:"
                        name="asignacion_juridica"
                        rules={[
                          {
                            required: true,
                            message: "Este campo es requerido",
                          },
                        ]}
                        value={asignacion_juridica}
                      >
                        <Select disabled={true} placeholder="Seleccione">
                          {asesores.map((asesor) => (
                            <Select.Option value={asesor.id} key={asesor.id}>
                              {asesor.username}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                ) : null}

                {array_asignados &&
                asesores_asignados &&
                asesores_asignados.tipo_asignacion.includes(3) ? (
                  <Row gutter={[40, 16]} justify="center">
                    <Col lg={12} md={12} xs={24}>
                      <Form.Item
                        label="Asesor para psicologica:"
                        name="asignacion_psicologica"
                        rules={[
                          {
                            required: true,
                            message: "Este campo es requerido",
                          },
                        ]}
                        value={asignacion_psicologica}
                      >
                        <Select disabled={true} placeholder="Seleccione">
                          {asesores.map((asesor) => (
                            <Select.Option value={asesor.id} key={asesor.id}>
                              {asesor.username}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                ) : null}
                {array_asignados &&
                asesores_asignados &&
                asesores_asignados.tipo_asignacion.includes(2) ? (
                  <Row gutter={[40, 16]} justify="center">
                    <Col lg={12} md={12} xs={24}>
                      <Form.Item
                        label="Asesor para empleabilidad:"
                        name="asignacion_empleabilidad"
                        rules={[
                          {
                            required: true,
                            message: "Este campo es requerido",
                          },
                        ]}
                        value={asignacion_empleabilidad}
                      >
                        <Select disabled={true} placeholder="Seleccione">
                          {asesores.map((asesor) => (
                            <Select.Option value={asesor.id} key={asesor.id}>
                              {asesor.username}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                ) : null}

                <Divider />
                <div
                  style={{ display: "flex", justifyContent: "center" }}
                ></div>
              </Card>
            </Form>
          )} */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TableAdminAsignadas;
